import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';
import moment from 'moment';
import { IAction, IState } from 'shared/interface/state';
import { IAllReportCategory } from 'features/reports/interface/reports';
import { IPresignedData } from 'shared/interface';

/**
 * create action creator
 * @param ACTION - type of action
 * @param data - data
 */
export const createAction = (ACTION: string, data: any = null): IAction => ({
	type: ACTION,
	payload: data
});

/**
 * create loading selector
 * @param actions - actions to dispatch
 */
export const createLoadingSelector = (actions: string[]) => (state: IState) =>
	// returns true only when all actions is not loading
	_(actions).some((action: string) => _.get(state, `loading.api.${action}`));

/**
 * dispatch action after given time (to handle some events like close modal after success api call)
 * @param dispatch - dispatch object
 * @param action - action type
 * @param time - time after which action is to be dispatched (default - 100ms)
 */
export const dispatchActionAfterTime = (
	dispatch: ThunkDispatch<unknown, unknown, IAction>,
	action: string,
	time = 100
) => {
	setTimeout(() => {
		dispatch(createAction(action));
	}, time);
};

export const getRandomColor = () => {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

export const calculateReportTotal = (data: IAllReportCategory[]) => {
	return data.reduce((acc: number, report: IAllReportCategory) => acc + report.reportCount, 0);
};

export const debounce = (func: any, wait = 500) => {
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};

export const formatFileName = (filename: string) => {
	return filename
		.replaceAll('-', '_')
		.replaceAll(' ', '')
		.replaceAll('  ', '')
		.replaceAll('(', '')
		.replaceAll(')', '')
		.trim();
};

export const convertToUniqueArray = (data: Array<string>) => {
	return data.filter((value, index, array) => {
		return array.indexOf(value) === index;
	});
};

export const getMinSecDuration = (time, type = 'MM:SS') => {
	// Create a moment duration
	const duration = moment.duration(time, 'seconds');

	// Get minutes, seconds and hours
	const minutes = duration.minutes();
	const seconds = duration.seconds();
	const hours = duration.hours();

	const finalDuration =
		type === 'MM:SS'
			? `${minutes > 9 ? '' : 0}${minutes} : ${seconds}${seconds > 0 ? '' : 0}`
			: `${hours > 9 ? '' : 0}${hours} : ${minutes}${minutes > 0 ? '' : 0}`;
	return finalDuration;
};

export const uploadFileToS3 = async (presignedData: IPresignedData, file: File) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		Object.keys(presignedData.fields).forEach((key) => {
			formData.append(key, presignedData.fields[key]);
		});

		formData.append('file', file);
		const xhr = new XMLHttpRequest();
		xhr.onload = function () {
			this.status === 204 ? resolve(true) : reject(this.responseText);
		};
		xhr.open('POST', presignedData.uploadUrl, true);
		xhr.send(formData);
	});
};

export const ALLOW_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export const validateFileSize = (
	file: File,
	fileUploaderType: string,
	setFieldError: (field: string, message: string) => void,
	name: string
) => {
	const MAX_IMAGE_SIZE_MB = 5;
	const MAX_VIDEO_SIZE_MB = 50;

	// Convert max sizes to bytes
	const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MB * 1024 * 1024;
	const MAX_VIDEO_SIZE_BYTES = MAX_VIDEO_SIZE_MB * 1024 * 1024;

	if ((fileUploaderType === 'imageOnly' || fileUploaderType === 'thumbnail') && file.size > MAX_IMAGE_SIZE_BYTES) {
		setFieldError(name, `Image size should be less than ${MAX_IMAGE_SIZE_MB}MB`);
		return false;
	}

	if (fileUploaderType === 'videoOnly' && file.size > MAX_VIDEO_SIZE_BYTES) {
		setFieldError(name, `Video size should be less than ${MAX_VIDEO_SIZE_MB}MB`);
		return false;
	}

	return true;
};

export const handleKeyDown = (e: any, charLength: number) => {
	const {
		which,
		target: { value }
	} = e;

	// Prevent default action if Enter key is pressed
	if (which === 13) {
		e.preventDefault();
		return;
	}

	// Allow backspace and delete keys to function normally
	const allowedKeys = new Set([8, 46]); // 8: Backspace, 46: Delete
	if (allowedKeys.has(which)) {
		return;
	}

	// Prevent default action if the text length is 100 characters
	if (value.length >= charLength) {
		e.preventDefault();
	}
};
