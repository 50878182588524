import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../form/button';

const NotFound: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className='middle-box text-center animated fadeInDown'>
			<h1>404</h1>
			<h3 className='font-bold'>Page Not Found</h3>

			<div className='error-desc'>
				<form className='form-inline m-t'>
					<Button className='btn btn-primary' onClick={() => navigate('/')}>
						Home
					</Button>
				</form>
			</div>
		</div>
	);
};

export default NotFound;
