import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { ICategoryOption } from '../interface/challenges';

export const keys = {
	groupIdKey: 'id',
	groupTitleKey: 'title',
	groupRightTitleKey: 'rightTitle',
	itemIdKey: 'id',
	itemTitleKey: 'title', // key for item div content
	itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
	itemGroupKey: 'group',
	itemTimeStartKey: 'start_time',
	itemTimeEndKey: 'end_time'
};

export const defaultTimeStart = moment().startOf('day').toDate();
export const defaultTimeEnd = moment().startOf('day').add(6, 'day').toDate();
export const defaultTimeRange = Number(defaultTimeEnd) - Number(defaultTimeStart);

export const minTime = moment().add(-6, 'months').valueOf();
export const maxTime = moment().add(6, 'months').valueOf();

export const visibleTimeStart = moment().startOf('day').valueOf();
export const visibleTimeEnd = moment().startOf('day').add(1, 'day').valueOf();

export const format = 'DD.MM.YYYY';

export const holidays = [moment('07.02.2023', format)];

export const categoryStatusDropdownArray: ICategoryOption[] = [
	{ label: 'All Status', value: 'all', name: 'all', dotColor: undefined },
	{ label: 'Upcoming', value: 'upcoming', name: 'Upcoming', dotColor: '#ffff00' },
	{ label: 'Submission Phase', value: 'submission_phase', name: 'Submission Phase', dotColor: '#ffa500' },
	// { label: 'Waiting Phase', value: 'waiting_phase', name: 'Waiting Phase', dotColor: '#800080' },
	{ label: 'Voting Phase', value: 'voting_phase', name: 'Voting Phase', dotColor: '#008000' },
	{ label: 'Completed', value: 'completed', name: 'Completed', dotColor: '#808080' }
];

export const challengeError = (errors, name, error) => {
	return (
		!isEmpty(errors) &&
		Object.prototype.hasOwnProperty.call(errors, name) && (
			<p className='error'>
				<span>{error}</span>
			</p>
		)
	);
};

export const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];
export const videoFileTypes = ['JPG', 'PNG', 'GIF', 'JPEG', 'MP4', 'Webm'];
export const types = ['single', 'paired', 'group'];
