import React, { FC, useEffect, useRef, useState } from 'react';

import ButtonTabs from 'shared/components/tabs/tabs';
import SearchComponent from 'shared/components/search/search';
import Button from 'shared/components/form/button';
import httpService from 'shared/services/http.service';
import { AddCampaignIcon, ChallengesDropDownArrowDown, IconFilter } from 'shared/icons/icons';
import { API_CONFIG } from 'shared/constants/api';
import { useOutsideClick } from 'shared/hoc/handleClickOutside/handleClickOutside';

import AddMusicModal from '../modal/addMusicModal';
import MusicList from '../components/musicList';
import { musicTabs } from '../constants/music.constant';

const Music: FC = () => {
	const [activeMusicTab, setActiveMusicTab] = useState('library');
	const [musicSearch, setMusicSearch] = useState('');
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isMusicList, setIsMusicList] = useState(false);
	const [isArtistFilter, setIsArtistFilter] = useState(false);
	const [artistData, setArtistData] = useState([]);
	const [selectedArtists, setSelectedArtists] = useState([]);
	const [isApply, setIsApply] = useState(false);

	const handleMusicTab = (selectedTab: string) => {
		setMusicSearch('');
		setActiveMusicTab(selectedTab);
	};

	const handleModalClose = () => {
		setIsOpenModal(false);
	};

	const handleArtistList = () => {
		httpService
			.get(API_CONFIG.path.musicArtistList)
			.then((response) => {
				setArtistData(response);
			})
			.catch((error) => {
				console.error('error', error);
			});
	};

	const handleSelectedArtist = (artist: string) => {
		// Check if the artist is already in the selectedArtists array
		if (selectedArtists.includes(artist)) {
			// If it's already selected, remove it
			setSelectedArtists(selectedArtists.filter((a) => a !== artist));
		} else {
			// If it's not selected, add it
			setSelectedArtists([...selectedArtists, artist]);
		}
	};

	useEffect(() => {
		handleArtistList();
	}, []);

	const artistListRef = useRef(null);

	useOutsideClick(artistListRef, () => setIsArtistFilter(false));
	return (
		<>
			<ButtonTabs activeTab={activeMusicTab} setActiveTab={handleMusicTab} tabs={musicTabs} />
			<div className='music-filter-wrapper'>
				<div className='music-search-wrapper'>
					<div ref={artistListRef}>
						<Button
							className='filter-music-button'
							onClick={() => {
								setIsArtistFilter(!isArtistFilter);
								setIsApply(false);
							}}
						>
							<IconFilter />
						</Button>
						{isArtistFilter && (
							<div className='artist-filter-wrapper'>
								<div className='d-flex align-items-center justify-content-space-between'>
									<h5>Artist</h5>
									<div className='cursor-pointer' onClick={() => setIsArtistFilter(false)}>
										<ChallengesDropDownArrowDown className='mr-20' />
									</div>
								</div>
								<hr className='mt-2 mb-10 artist-hr' />
								<div className='artist-list'>
									{artistData.map((artist, index) => {
										return (
											<div className='full-width' key={index}>
												<label className='artist-checkbox-wrapper cursor-pointer'>
													<input
														className='artist-checkbox'
														type='checkbox'
														checked={selectedArtists.includes(artist)}
														onChange={() => handleSelectedArtist(artist)}
													/>
													<span className='checkmark' />
													<span className='ml-10 text-black font--16px text--red-hat-display'>
														{artist}
													</span>
												</label>
											</div>
										);
									})}
								</div>
								<Button
									style={{
										background: '#ECF0FF',
										width: '240px',
										marginBottom: '10px'
									}}
									className='apply-btn'
									onClick={() => {
										setIsApply(!isApply);
										setIsArtistFilter(!isArtistFilter);
									}}
								>
									Apply
								</Button>
							</div>
						)}
					</div>
					<div className='music-search'>
						<SearchComponent value={musicSearch} handleSearch={setMusicSearch} />
					</div>
				</div>
				<div className='add-music-btn-wrapper'>
					<Button
						className='add-music-button'
						onClick={() => {
							setIsOpenModal(true);
						}}
					>
						<AddCampaignIcon width='20' height='20' />
						<p className='mb-0 text-white font--14px ml-10'>Add new music</p>
					</Button>
				</div>
			</div>
			<div className='music-component-wrapper'>
				<MusicList
					activeMusicTab={activeMusicTab}
					musicSearch={musicSearch}
					isInPopUp={false}
					isMusicList={isMusicList}
					setIsMusicList={setIsMusicList}
					selectedArtists={selectedArtists}
					isApply={isApply}
				/>
			</div>
			<AddMusicModal show={isOpenModal} handleClose={handleModalClose} setIsMusicList={setIsMusicList} />
		</>
	);
};

export default Music;
