import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import ErrorBoundary from 'shared/components/errorBoundary/errorBoundary';
import WithErrorHandler from 'shared/hoc/withErrorHandler';
import App from './app';
import store from './store';

const Root: React.FC = () => {
    return (
        <Provider store={store}>
            <ErrorBoundary>
                <WithErrorHandler />
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ErrorBoundary>
        </Provider>
    );
};
reportWebVitals();

export default Root;
