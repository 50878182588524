import { FC, useEffect, useState } from 'react';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { IconEye, ReportWinIcon, SliderIcon } from 'shared/icons/icons';
import { IAllVideoReportInfo } from 'features/userManagement/interface/userManagement';

interface ISliderProps {
	settings: Settings;
	data: IAllVideoReportInfo[];
	title?: string;
	className?: string;
	handleDetail?: (index: number) => void;
	categoryId?: string;
	playingVideo?: boolean;
	type?: string | 'about' | 'from' | 'report' | 'all';
}

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
	<div {...props} className='report-slider-arrow left'>
		<SliderIcon />
	</div>
);

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
	<div {...props} className='report-slider-arrow right'>
		<SliderIcon />
	</div>
);

const settings = {
	prevArrow: <PrevArrow />,
	nextArrow: <NextArrow />
};

const SlickSLider: FC<ISliderProps> = (props) => {
	const { data } = props;
	const { playingVideo = false } = props;
	const [currentVideoIndex, setCurrentVideoIndex] = useState<number>();

	useEffect(() => {
		setCurrentVideoIndex(-1);
	}, [props.data]);

	return (
		<div className={`${props.className}`}>
			{!!props.title && <p>{props.title}</p>}
			<Slider {...settings} {...props.settings}>
				{data.map((data: IAllVideoReportInfo, index) => {
					return (
						<div className='report-wrap ' key={data.id}>
							<img
								className={`cursor--pointer ${
									playingVideo && currentVideoIndex == index ? 'hide' : ''
								}`}
								key={index}
								src={
									data.userVideo.thumbnailUrl ||
									data.userVideo.leftThumbnailUrl ||
									data.userVideo.rightThumbnailUrl ||
									''
								}
								alt=''
								onClick={() => {
									props.handleDetail(index);
								}}
							/>
							<div className='user-video-votes-view'>
								<div className='votes'>
									<div className='icon-value-pair'>
										<IconEye /> {data.userVideo.totalViews}
									</div>
									<div className='icon-value-pair'>
										<ReportWinIcon /> {data.userVideo.totalVotes}
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default SlickSLider;
