import * as actionTypes from 'store/action-types';
import { IAuthState } from '../interface/auth';

import { IAction } from 'shared/interface';
import AuthService from 'shared/services/auth.service';

const initialState: IAuthState = {
	isLogin: AuthService.checkLogin(),
	userData: AuthService.getUserData()
};

const reducer = (state: IAuthState = initialState, action: IAction): IAuthState => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			return {
				...state,
				isLogin: true,
				userData: action.payload
			};
		case actionTypes.NO_ACTION:
			return { ...state };
		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return {
				...state,
				isLogin: false
			};
		default:
			return state;
	}
};
export default reducer;
