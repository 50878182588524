import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAction } from 'shared/util/utility';
import NavItem from './navItem';
import * as actionTypes from 'store/action-types';
import { _adminNav } from './_nav';
import { Logo } from '../../icons/icons';

const SideNav = () => {
	const [openStatus, setOpenStatus] = useState<{ [key: string]: boolean }>({});
	const dispatch = useDispatch();

	const toggleIsOpen = (name: string) => {
		if (openStatus[name]) {
			setOpenStatus({ [name]: false });
		} else {
			setOpenStatus({ [name]: true });
		}
	};
	const toggleSidebar = () => {
		if (window.innerWidth < 768) {
			document.body && document.body.classList.remove('mini-navbar');
		}
	};

	const renderSideNav = () => {
		dispatch(createAction(actionTypes.NO_ACTION));
	};

	return (
		<nav className='navbar-default navbar-static-side' role='navigation'>
			<a className='navbar-back-btn' onClick={toggleSidebar}>
				<i className='bx bx-arrow-left' />
			</a>
			<ul className='nav' id='side-menu'>
				<li className='nav-header cursor-pointer'>
					<h3 className='profile-element'>
						<div className='logo-wrapper'>
							<div className='logo'>
								<Logo />
							</div>
						</div>
					</h3>
				</li>
				<div className='line' />
				{_adminNav.map((menu, index) => (
					<NavItem
						isOpen={openStatus[menu.name] || false}
						hideSidebar={toggleSidebar}
						toggleIsOpen={toggleIsOpen}
						key={index}
						renderSideNav={renderSideNav}
						menu={menu}
					/>
				))}
			</ul>
		</nav>
	);
};

export default SideNav;
