import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../form/button';

const ComingSoon: React.FC = () => {
	const navigate = useNavigate();

	return (
		<>
			<div className='middle-box text-center animated fadeInDown coming-soon-wrap'>
				<h1>Coming Soon!</h1>
				<div className='error-desc'>
					<form className='form-inline m-t'>
						<Button className='coming-soon-btn' onClick={() => navigate('/dashboard')}>
							Home
						</Button>
					</form>
				</div>
			</div>
		</>
	);
};

export default ComingSoon;
