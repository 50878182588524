import { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';

import { IBattleCampaignSearchedData, ICampaignPost } from '../interface/campaigns';

import { challengeError } from 'features/challenges/utility/challengeConstants';

import Button from 'shared/components/form/button';
import { Input } from 'shared/components/form/inputTypes';
import CustomModal from 'shared/components/modal/modal';
import { getAddBattleCampaignValidationSchema } from 'shared/constants/validation-schema';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { debounce } from 'shared/util/utility';
import { IPagination } from 'shared/components/pagination/pagination';

interface IProps {
	handleClose: () => void;
	handleSwitchTab: (selectedTab: string) => void;
	handleUpdateList: () => void;
}

const initialValues = {
	title: '',
	advertisementId1: '',
	advertisementId2: ''
};

const BattleCampaignsModal = (props: IProps) => {
	const { handleClose, handleSwitchTab, handleUpdateList } = props;

	const [isGettingBattleList, setIsGettingBattleList] = useState(false);
	const [battleCampaignData, setBattleCampaignData] = useState<IBattleCampaignSearchedData[]>([]);
	const [advertisementOptions, setAdvertisementOptions] = useState([]);
	const [campaignSearch, setCampaignSearch] = useState('');
	const [isCampaignsLoader, setIsCampaignsLoader] = useState<boolean>(false);
	const [showCustomBattleValidation, setShowCustomBattleValidation] = useState(false);
	const [battleCampaignPagination, setBattleCampaignPagination] = useState<IPagination>({} as IPagination);

	// add battle campaign
	const addBattleCampaigns = useCallback((params: ICampaignPost) => {
		setIsCampaignsLoader(true);
		httpService
			.post(`${API_CONFIG.path.battleAdvertisement}`, params)
			.then(() => {
				setIsCampaignsLoader(false);
				handleClose();
				handleSwitchTab('battle');
				handleUpdateList();
			})
			.catch((error) => {
				console.error('error', error);
				setIsCampaignsLoader(false);
				handleClose();
			});
	}, []);

	// single campaign list
	const getBattleCampaign = (page?: number | string, search = '', fromBattleSearch = false) => {
		setIsGettingBattleList(true);
		const detailUrl = `${API_CONFIG.path.campaigns}`;
		httpService
			.get(detailUrl, { page: page || battleCampaignPagination.currentPage, recordPerPage: 10, search })
			.then((response) => {
				const showData = { ...response }.data;
				delete response.data;
				setBattleCampaignPagination(response);
				const appendedData = fromBattleSearch ? showData : [...battleCampaignData, ...showData];
				setBattleCampaignData(appendedData);
				if (appendedData.length > 0) {
					const battleAdvertisementOptions = appendedData?.map((battle) => ({
						value: battle.id,
						label: battle.title,
						id: battle.id
					}));
					setAdvertisementOptions(battleAdvertisementOptions);
				}
				setIsGettingBattleList(false);
			})
			.catch((error) => {
				console.error(error);
				setIsGettingBattleList(false);
			});
	};

	useEffect(() => {
		getBattleCampaign(1, campaignSearch, true);
	}, [campaignSearch]);

	const handleBattleSearch = debounce((battleName: string) => setCampaignSearch(battleName));

	const handleSubmit = (formData) => {
		const modifiedData = {
			...formData,
			advertisementId1: formData.advertisementId1,
			advertisementId2: formData.advertisementId2
		};
		addBattleCampaigns(modifiedData);
	};

	return (
		<CustomModal
			show={true}
			handleClose={() => {
				handleClose();
			}}
			closeOnOutSideClick
			className='add-challenge-modal'
		>
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					handleSubmit(values);
				}}
				validationSchema={getAddBattleCampaignValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
				validateOnMount={false}
				enableReinitialize
			>
				{({ handleSubmit, setFieldValue, errors, values }) => {
					if (!!values.advertisementId1 && !!values.advertisementId2) {
						if (values.advertisementId1 == values.advertisementId2) {
							setShowCustomBattleValidation(true);
						} else {
							setShowCustomBattleValidation(false);
						}
					}
					return (
						<form onSubmit={handleSubmit}>
							<div className='form-group '>
								<Input
									type='text'
									name='title'
									placeholder='Start typing...'
									setFieldValue={setFieldValue}
									showLabels
									className='full-width input-field'
									config={{
										name: 'title',
										type: 'text',
										label: 'Title'
									}}
								/>
								{!values.title && challengeError(errors, 'title', 'Title  is required.')}
							</div>
							<div className='form-group'>
								<Input
									placeholder='Choose Category'
									type='dropdown'
									name='advertisementId1'
									setFieldValue={(name, value) => {
										setFieldValue(name, value.value);
									}}
									showLabels
									value={advertisementOptions.find((opt) => opt.value === values.advertisementId1)}
									isClearable
									className=''
									onInputChange={handleBattleSearch}
									config={{
										name: 'advertisementId1',
										type: 'dropdown',
										label: 'Advertisement 1',
										otherOptions: {
											dropDownOptions: advertisementOptions
										}
									}}
								/>
								{!values.advertisementId1 &&
									challengeError(errors, 'advertisementId1', 'Advertisement 1 is required.')}
							</div>
							<div className='form-group'>
								<Input
									placeholder='Choose Category'
									type='dropdown'
									name='advertisementId2'
									setFieldValue={(name, value) => {
										setFieldValue(name, value.value);
									}}
									showLabels
									value={advertisementOptions.find((opt) => opt.value === values.advertisementId2)}
									isClearable
									className=''
									onInputChange={handleBattleSearch}
									config={{
										name: 'advertisementId2',
										type: 'dropdown',
										label: 'Advertisement 2',
										otherOptions: {
											dropDownOptions: advertisementOptions
										}
									}}
								/>
								{!values.advertisementId2 &&
									challengeError(errors, 'advertisementId2', 'Advertisement 2 is required.')}
								{showCustomBattleValidation && (
									<span className='error'>You can't choose same advertisement</span>
								)}
							</div>
							<div className='d-flex mt-12'>
								<Button
									className='line-height--20px mt-12 mr-13 height--40px width-214px'
									onClick={() => handleClose()}
								>
									Cancel
								</Button>
								<Button
									btnType='primary'
									type='submit'
									disabled={isCampaignsLoader || isGettingBattleList || showCustomBattleValidation}
									className='line-height--20px mt-12 height--40px width-214px'
									onClick={handleSubmit}
								>
									Submit
								</Button>
							</div>
						</form>
					);
				}}
			</Formik>
		</CustomModal>
	);
};

export default BattleCampaignsModal;
