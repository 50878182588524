import { useCallback, useEffect, useState } from 'react';

import { IChallengesCategory, ModalTypes } from '../interface/challenges';

import ChallengeSlider from '../components/challengeSlider';
import ChallengeCalender from '../components/challengeCalender';

import { API_CONFIG } from 'shared/constants/api';
import httpService from 'shared/services/http.service';
import { IDropDownAndCheckboxOptions } from 'shared/components/form/inputTypes';

export const Challenges: React.FC = () => {
	const [categoryDropdownOption, setCategoryDropdownOption] = useState<IDropDownAndCheckboxOptions[]>([]);
	const [currentModalType, setCurrentModalType] = useState<ModalTypes>('');
	const [currentChallengeId, setCurrentChallengeId] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const fetchCategories = useCallback(() => {
		const categoryOptionArray: IDropDownAndCheckboxOptions[] = [
			{ label: 'All Category', value: 'all', name: 'all category' }
		];
		httpService
			.get(`${API_CONFIG.path.categoryList}`)
			.then((response) => {
				response.data.forEach((category: IChallengesCategory) => {
					categoryOptionArray.push({ label: category.name, value: category.id, name: category.name });
				});
				setCategoryDropdownOption([...categoryOptionArray]);
			})
			.catch((error) => {
				console.error('error', error);
			});
	}, []);

	useEffect(() => {
		fetchCategories();
	}, [fetchCategories]);

	const handleChallengeModal = (id: string, type: ModalTypes) => {
		setCurrentChallengeId(id);
		setIsModalOpen(true);
		setCurrentModalType(type);
	};

	return (
		<div className='challenge-page'>
			<ChallengeSlider
				categoryDropdownOption={categoryDropdownOption}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				currentModalType={currentModalType}
				setCurrentModalType={setCurrentModalType}
				handleChallengeModal={handleChallengeModal}
				currentChallengeId={currentChallengeId}
			/>
			<ChallengeCalender
				categoryDropdownOption={categoryDropdownOption}
				handleChallengeModal={handleChallengeModal}
			/>
		</div>
	);
};

export default Challenges;
