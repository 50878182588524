import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import isEmpty from 'lodash/isEmpty';

import { IUserDetailsMapper } from '../interface/userManagement';

import { numberFormat } from 'shared/helpers';
import LineChart, { IChartData } from 'shared/components/chart/linechart';
import { formatDate } from 'shared/util/formatFn';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import ButtonTabs from 'shared/components/tabs/tabs';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export interface IUserStatsGraph {
	userId: string;
}

const usersTabs = [
	{ name: 'Day', value: 'day' },
	{ name: 'Week', value: 'week' },
	{ name: 'Month', value: 'month' },
	{ name: 'Year', value: 'year' },
	{ name: 'All', value: 'all' }
];

export const UserStatsGraph: React.FC<IUserStatsGraph> = ({ userId }) => {
	const [userStats, setUserStats] = useState<IUserDetailsMapper>();
	const [checkChartEmpty, setCheckChartEmpty] = useState<any>({});
	const [activeUsersTab, setActiveUsersTab] = useState('day');

	const userGraph = useMemo(() => {
		if (!isEmpty(userStats)) {
			return Object.keys(userStats);
		} else {
			return [];
		}
	}, [userStats]);

	const getUserStats = useCallback((id: string, filterType: string) => {
		httpService
			.get(`${API_CONFIG.path.user}/${id}/statistics`, { filterType: filterType })
			.then((response) => {
				setCheckChartEmpty((check) => {
					const object = { ...check };
					for (const key in response) {
						if (isEmpty(response[key].performanceGraph)) {
							object[key] = true;
						} else {
							const value = response[key].performanceGraph.some((ele) => ele.count === 0);
							object[key] = value;
						}
					}
					return object;
				});
				setUserStats(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		getUserStats(userId, activeUsersTab);
	}, [getUserStats, userId, activeUsersTab]);

	const createChartData = (key: string) => {
		return {
			labels: userStats[key].performanceGraph.map((ele) => formatDate(new Date(ele.timestamp), 'DD-MM-YYYY')),
			datasets: [
				{
					label: '',
					data: userStats[key].performanceGraph.map((ele) => ele.count),
					borderColor: ['rgba(88, 201, 71, 1)'],
					backgroundColor: 'rgba(88, 201, 71, 1)',
					radius: 2,
					borderWidth: 2,
					tension: 0.5
				}
			]
		};
	};

	const options = {
		plugins: {
			legend: {
				display: false
			}
		},
		scales: {
			y: {
				display: false
			},
			x: {
				display: false
			},
			r: {
				display: false
			}
		},
		maintainAspectRatio: false
	};

	return (
		<>
			<div className='user-statistics-tabs'>
				<ButtonTabs activeTab={activeUsersTab} setActiveTab={setActiveUsersTab} tabs={usersTabs} />
			</div>
			<section className='user-graph-wrapper'>
				<div className='graph-detail-data'>
					<ul className='graph-ul'>
						{userGraph.map((graph, index) => {
							const total = userStats?.[graph.toLocaleLowerCase()]?.total;
							const performancePer = userStats?.[graph.toLocaleLowerCase()]?.performancePer;
							return (
								<li className='graph-li' key={index}>
									<div className='user-progress-data'>
										<div className='user-personal-detail'>
											<h5 className='user-personal-ratio text-capitalize'>{`${graph}s`}</h5>
											<div className='followers-data'>
												<h2 className='user-progress'>{`${numberFormat(total || 0, 1)}`}</h2>
												{activeUsersTab !== 'all' && (
													<h6 className='user-rate'>
														{`${numberFormat(performancePer || 0, 1)}
												% from last ${activeUsersTab}`}
													</h6>
												)}
											</div>
										</div>
										<div className='graph-icon'>
											{checkChartEmpty[graph] ? (
												<p className='empty-chart'>-----</p>
											) : (
												<LineChart
													data={createChartData(graph) as unknown as IChartData}
													option={options}
												/>
											)}
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</section>
		</>
	);
};
