import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export interface ITabs {
	name: string;
	value: string;
}
export interface IButtonTabsProps {
	activeTab: string;
	setActiveTab: (selectedTab: string) => void;
	tabs: ITabs[];
}

const ButtonTabs = (props: IButtonTabsProps) => {
	return (
		<ButtonGroup className='custom-tgl-btn-wrapper'>
			{props.tabs.map((radio, idx) => {
				return (
					<ToggleButton
						className='custom-tgl-btn'
						key={`radio-${idx}-${radio.name}`}
						id={`radio-${idx}-${radio.name}`}
						type='radio'
						variant={'outline-primary'}
						value={radio.value}
						checked={props.activeTab === radio.value}
						onChange={(e) => {
							props.setActiveTab(e.currentTarget.value);
						}}
					>
						{radio.name}
					</ToggleButton>
				);
			})}
		</ButtonGroup>
	);
};
export default ButtonTabs;
