import { useNavigate } from 'react-router';

import { IAlStatisticsReportData, IAllReportCategory, IAllTopVideoReport } from '../interface/reports';

import { calculateReportTotal } from 'shared/util/utility';

export interface IReportsHeader {
	topUserVideoReport: IAllTopVideoReport[];
	reportByCategory: IAllReportCategory[];
	statisticsReport: IAlStatisticsReportData;
}

const ReportsHeader: React.FC<IReportsHeader> = (props) => {
	const { topUserVideoReport, reportByCategory, statisticsReport } = props;

	const navigate = useNavigate();
	const usersCount = statisticsReport?.totalUserVideoReport;

	return (
		<div className='reports-title-header'>
			<span>{calculateReportTotal(reportByCategory)}</span>
			<div className='reports-header-details'>
				<div className='avatars'>
					{topUserVideoReport.length > 0 && (
						<>
							<ul>
								{topUserVideoReport.map((videoReport, index) => {
									return (
										<li
											className='avatar'
											key={index}
											title={videoReport.userName}
											onClick={() => navigate(`/user-management/${videoReport.id}`)}
										>
											<figure>
												<img src={videoReport.image} />
											</figure>
										</li>
									);
								})}
							</ul>
							{usersCount > 5 && (
								<div className='user-counts'>
									<span>+{usersCount - 5}</span>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReportsHeader;
