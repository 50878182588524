import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { API_BASE_URL } from '.';

export const API_CONFIG = {
	baseUrl: `${API_BASE_URL}`,
	path: {
		login: 'auth/login',
		logout: 'logout',
		user: 'user',
		videoReportFromMe: 'video-report/from-me',
		videoReportAboutMe: 'video-report/about-me',
		allVideos: 'videos',
		videoReportDetail: 'video-report/details',
		allVideosDetail: 'details',
		categoryList: 'category',
		challenge: 'challenge',
		challengeCalender: 'challenge/calender',
		challengeUpload: 'upload/challenge/credentials',
		reportStatistics: 'report/statistics',
		report: 'report',
		passwordChange: 'password/change',
		userSearch: 'user/search',
		campaignsUpload: 'upload/advertise/credentials',
		campaigns: 'advertisement',
		battleAdvertisement: 'advertisement/battle',
		music: 'music',
		musicList: 'music/list',
		musicArtistList: 'music/artist',
		uploadMusicCred: 'upload/music/credentials',
		dashboardAllUsers: 'dashboard/all-user',
		dashboardActiveUsers: 'dashboard/active-user',
		dashboardAverageTimeSpend: 'dashboard/average-time-spend',
		dashboardVideoWatched: 'dashboard/video-watched',
		dashboardAllVotes: 'dashboard/all-votes',
		dashboardLinkVisits: 'dashboard/link-visits',
		csvUpload: 'csv/import',
		csvDownload: 'csv/download',
		csvLastUpload: 'csv/csv-details',
		onboarding: 'setting',
		onboardUpload: 'upload/credentials'
	}
};

export const getUrl = (url: string, params: { [key: string]: any } = {}): string => {
	Object.keys(params).forEach((key) => (params[key] == null || params[key] === '') && delete params[key]);
	let urlString = `${API_CONFIG.baseUrl}/${url}`;
	if (params && !isEmpty(params)) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};
