import MusicList from 'features/music/components/musicList';
import { IMusic } from 'features/music/interface/music';
import { useState } from 'react';
import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import SearchComponent from 'shared/components/search/search';
import { IconFilter } from 'shared/icons/icons';

interface IProps {
	show: boolean;
	handleClose: () => void;
	handleMusicSelection?: (row: IMusic) => void;
	selectedRows?: IMusic[];
}

const AddSongModal = (props: IProps) => {
	const [musicSearch, setMusicSearch] = useState('');
	const { show, handleClose, handleMusicSelection, selectedRows } = props;
	return (
		<CustomModal
			show={show}
			handleClose={handleClose}
			className='add-music-modal'
			closeOnOutSideClick
			dialogClassName='modal-70w'
			hideBackDrop
			footer={
				<div>
					<Button
						btnType='primary'
						type='submit'
						className='line-height--20px height--40px width-125px'
						onClick={handleClose}
					>
						Save
					</Button>
				</div>
			}
		>
			<div className='music-component-wrapper mt-0'>
				<div className='music-filter-wrapper justify-content-center'>
					<div className='music-search-wrapper'>
						<div className='music-search'>
							<SearchComponent value={musicSearch} handleSearch={setMusicSearch} />
						</div>
						<Button className='filter-music-button'>
							<IconFilter />
						</Button>
					</div>
				</div>
				<MusicList
					activeMusicTab='library'
					musicSearch={musicSearch}
					isInPopUp={true}
					handleMusicSelection={handleMusicSelection}
					selectedRows={selectedRows}
				/>
			</div>
		</CustomModal>
	);
};

export default AddSongModal;
