import { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useNavigate, useParams } from 'react-router';

import { API_CONFIG } from 'shared/constants/api';
import httpService from 'shared/services/http.service';
import { IPagination } from 'shared/components/pagination/pagination';
import { Loader } from 'shared/components/spinner/spinner';
import { CloseIcon, Search, UserList } from 'shared/icons/icons';
import InfiniteScrollComponent from 'shared/components/infiniteScroll/infiniteScrollComponent';
import { debounce } from 'shared/util/utility';
import { numberFormat } from 'shared/helpers';

import UserImage from 'assets/images/user-list-profile-pic.png';

import { IUpdateStatusProps, IUserDetail } from '../interface/userManagement';

interface IProps {
	updateStatus: IUpdateStatusProps;
	setUpdateStatus: () => void;
}

export const UserListComponent: React.FC<IProps> = ({ updateStatus, setUpdateStatus }) => {
	const { userId } = useParams();
	const navigate = useNavigate();

	const [search, setSearch] = useState('');
	const [isGettingUserList, setIsGettingUserList] = useState(true);
	const [usersData, setUsersData] = useState<IUserDetail[]>([]);
	const [pagination, setPagination] = useState<IPagination>({} as IPagination);
	const perPageUserRecords = Math.ceil((window.innerHeight - 330) / 83);

	// Get Users List Data.
	const getUsersList = useCallback((page: number, search: string) => {
		setIsGettingUserList(true);
		const detailUrl = `${API_CONFIG.path.user}`;
		httpService
			.get(detailUrl, { page, recordPerPage: perPageUserRecords > 10 ? perPageUserRecords : 10, search })
			.then((response) => {
				setUsersData((usersData) => {
					return page === 1 ? response.data : [...usersData, ...response.data];
				});
				setIsGettingUserList(false);
				setPagination(response);
			})
			.catch((error) => {
				console.error(error);
				setIsGettingUserList(false);
			});
	}, []);

	const handleStatus = useCallback(() => {
		const userDetailList = [...usersData];
		userDetailList.forEach((userinfo, index) => {
			if (userinfo.id === userId) {
				const data = {
					...userinfo,
					status: updateStatus.status
				};
				userDetailList.splice(index, 1, data as IUserDetail);
				setUsersData(userDetailList);
				setUpdateStatus();
			}
		});
	}, [setUpdateStatus, updateStatus.status, userId, usersData]);

	const handleSearch = useMemo(
		() =>
			debounce((value: string) => {
				setPagination((page) => ({ ...page, currentPage: 1 }));
				getUsersList(1, value);
			}),
		[getUsersList]
	);

	useEffect(() => {
		if (usersData[0] && (!userId || userId === '')) {
			navigate(`/user-management/${usersData[0].id}`);
		}
	}, [navigate, userId, usersData]);

	useEffect(() => {
		getUsersList(1, '');
	}, [getUsersList]);

	useEffect(() => {
		handleStatus();
	}, [updateStatus]);

	const handleClearSearch = () => {
		setSearch('');
		getUsersList(1, '');
	};

	return (
		<aside className='users-list'>
			<div className='action-wrapper'>
				<Search />
				<input
					type='text'
					autoFocus
					value={search}
					className=' width--726px height--40px br--10px no-border'
					placeholder='Search'
					onChange={({ target: { value } }) => {
						handleSearch(value);
						setSearch(value);
					}}
				/>
				{search !== '' && (
					<div className='user-list-cross-icon' onClick={handleClearSearch}>
						<CloseIcon />
					</div>
				)}
			</div>
			<div className='all-user'>
				<div className='user-total'>
					<h5>{`All user(${pagination.total || 0})`}</h5>
				</div>
			</div>
			<div className='users-list-wrapper'>
				<div className='all-users-data'>
					{isGettingUserList && isEmpty(usersData) && <Loader className='custom-loader' />}
					{!isGettingUserList && isEmpty(usersData) && (
						<div className='user-profile-icon'>
							<UserList />
							<p className='user-profile-text'>No User Found</p>
						</div>
					)}
					{!isEmpty(usersData) && (
						<>
							{Number(pagination.total) > perPageUserRecords ? (
								<InfiniteScrollComponent
									fetchData={() => {
										getUsersList(pagination.currentPage + 1, search);
										setPagination((page) => ({ ...page, currentPage: page.currentPage + 1 }));
									}}
									total={usersData.length}
									hasMore={usersData.length < pagination.total}
									height='calc(100vh - 330px)'
									className='user-list-wrapper'
									endMessage={'Yay! You have seen it all'}
									isSpinner={isGettingUserList && usersData.length > 0}
								>
									{renderUserList(usersData, userId, navigate)}
								</InfiniteScrollComponent>
							) : (
								renderUserList(usersData, userId, navigate)
							)}
						</>
					)}
				</div>
			</div>
		</aside>
	);
};

const renderUserList = (usersData: IUserDetail[], userId: string, navigate: (id: string) => void) => {
	return (
		<ul className='user-list-ul'>
			{usersData.map((user) => {
				return (
					<li
						key={user.id}
						className={`user-list-li align-items-center ${user.id == userId ? 'active-follower' : ''}`}
						onClick={() => navigate(`/user-management/${user.id}`)}
					>
						<div className='user-id-details'>
							<div className='user-img'>
								<img src={user.image ? user.image : UserImage} alt='userImage' />
							</div>
							<div className='user-id'>
								<h5 className='id'>{`@${user.userName}`}</h5>
								<h6 className='followers'>{`${numberFormat(
									user.friendship.followers ? user.friendship.followers : 0,
									1
								)} followers`}</h6>
							</div>
						</div>
						<div className={`${user.status}-btn-wrapper action-btn-wrapper`}>
							<h6 className={`${user.status}-btn action-btn`}>{user.status.replace('_', ' ')}</h6>
						</div>
					</li>
				);
			})}
		</ul>
	);
};

export default UserListComponent;
