import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn, createTheme } from 'react-data-table-component';

import Pagination, { IPagination } from 'shared/components/pagination/pagination';
import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/api';
import httpService from 'shared/services/http.service';
import { debounce, getMinSecDuration } from 'shared/util/utility';
import { AddHashTagIcon, Artist, Clock, IconTikMark, Music, TableFilter } from 'shared/icons/icons';
import Button from 'shared/components/form/button';

import MusicImage from 'assets/images/music.png';
import { IMusic } from '../interface/music';
import { OrderColumn } from '../constants/music.constant';

interface IProps {
	musicSearch: string;
	activeMusicTab: string;
	isInPopUp: boolean;
	selectedRows?: IMusic[];
	isMusicList?: boolean;
	handleMusicSelection?: (row: IMusic) => void;
	setIsMusicList?: (isMusicList: boolean) => void;
	selectedArtists?: string[];
	isApply?: boolean;
}

createTheme('solarized', {
	background: {
		default: 'transparent'
	},
	context: {
		background: '#cb4b16',
		text: '#FFFFFF'
	},
	divider: {
		default: '#cecece'
	},
	action: {
		button: 'rgba(0,0,0,.54)',
		disabled: 'rgba(0,0,0,.12)'
	}
});

const initPagination = {
	currentPage: 1,
	nextPage: null,
	recordPerPage: 10,
	remainingCount: 0,
	total: 0,
	totalPages: 1
};

const MusicList: FC<IProps> = (props) => {
	const {
		activeMusicTab,
		musicSearch,
		isInPopUp,
		isMusicList = false,
		handleMusicSelection,
		selectedRows = [],
		setIsMusicList,
		selectedArtists,
		isApply
	} = props;

	const [musicData, setMusicData] = useState<IMusic[]>([]);
	const [pagination, setPagination] = useState<IPagination>(initPagination);
	const [musicLoader, setMusicLoader] = useState<boolean>(false);
	const [musicOrder, setMusicOrder] = useState<{ [key: string]: string }>({ order: '', orderColumn: '' });

	const { currentPage, recordPerPage } = pagination;

	const fetchMusicList = useCallback(
		(currentPage = 1, recordPerPage = 10, search = '') => {
			const params = {
				search,
				artists: isApply ? selectedArtists : [],
				musicType: activeMusicTab.toLowerCase()
			};

			if (musicOrder.order !== '') {
				params['sort'] = musicOrder;
			}
			setMusicLoader(true);
			httpService
				.post(`${API_CONFIG.path.musicList}?page=${currentPage}&recordPerPage=${recordPerPage}`, params)
				.then((response) => {
					const { data, ...rest } = response;
					setMusicData(data);
					setMusicLoader(false);
					setPagination(rest);
					setIsMusicList(false);
				})
				.catch((error) => {
					console.error('error', error);
					setMusicLoader(false);
				});
		},
		[currentPage, recordPerPage, activeMusicTab, isApply, musicOrder]
	);

	useEffect(fetchMusicList, [activeMusicTab, musicOrder]);

	useEffect(() => {
		(isMusicList || isApply) && fetchMusicList();
	}, [isMusicList, isApply]);

	const handlePagination = (page: number, perPageVal: number) => {
		fetchMusicList(page, perPageVal);
	};

	const handleMusicSearch = useMemo(
		() =>
			debounce((value: string) => {
				fetchMusicList(1, undefined, value.trim());
			}),
		[fetchMusicList]
	);

	useEffect(() => {
		handleMusicSearch(musicSearch);
	}, [musicSearch]);

	const handleMusicOrder = (fieldName: string) => {
		setMusicOrder({
			order: musicOrder.orderColumn === fieldName && musicOrder.order === 'ASC' ? 'DESC' : 'ASC',
			orderColumn: fieldName
		});
	};

	const columns = useMemo(() => {
		if (activeMusicTab === 'library' && musicData.length > 0) {
			let libraryTable: any = [
				{
					name: (
						<div className='flex-center'>
							<Music className='music-icon' />
							<h5 className='mb-0 ml-10 font-weight--600 font--16px'>Title</h5>
						</div>
					),
					center: true,
					cell: ({ title, imageUrl }: IMusic) => {
						return (
							<div className='music-user-img'>
								<img src={imageUrl ?? MusicImage} alt='userImage' />
								<span
									className='text-truncate  text--red-hat-display font--14px font-weight-700'
									title={title}
								>
									{title || '-'}
								</span>
							</div>
						);
					},
					width: '30%'
				},

				{
					name: (
						<div className='flex-center'>
							<Artist />
							<h5 className='mb-0 ml-10 font-weight--600 font--16px'>Artist</h5>
						</div>
					),
					selector: (row: { artist: string }) => <p className='music-info-text'>{row.artist || '-'}</p>,
					flex: 1,
					center: true,
					width: '20%'
				},
				{
					name: (
						<div className='flex-center' onClick={() => handleMusicOrder(OrderColumn.DURATION)}>
							<Clock />
							<h5 className='mb-0 ml-5 mr-5 font-weight--600 font--16px'>Duration</h5>
							<TableFilter />
						</div>
					),
					center: true,
					selector: (row: { duration: string }) => {
						return (
							<p className='music-info-text'>
								{row.duration != undefined ? getMinSecDuration(row.duration) : '-'}
							</p>
						);
					},
					width: '20%'
				},
				{
					name: (
						<div className='flex-center' onClick={() => handleMusicOrder(OrderColumn.LINK_OUTS)}>
							<h5 className='mb-0 mr-5 font-weight--600 font--16px'>Link Outs</h5>
							<TableFilter />
						</div>
					),
					center: true,
					selector: (row: { linkOuts: string }) => {
						return row.linkOuts != undefined ? row.linkOuts : '-';
					},
					width: '10%',
					minWidth: '160px !important'
				},
				{
					name: (
						<div className='flex-center' onClick={() => handleMusicOrder(OrderColumn.TOTAL_PLAY)}>
							<h5 className='mb-0 mr-5 font-weight--600 font--16px'>Plays</h5>
							<TableFilter />
						</div>
					),
					center: true,
					selector: (row: { plays: string }) => {
						return row.plays != undefined ? row.plays : '-';
					},
					width: '10%'
				},
				{
					name: (
						<div className='flex-center' onClick={() => handleMusicOrder(OrderColumn.TOTAL_USED)}>
							<h5 className='mb-0 mr-5 font-weight--600 font--16px'>Used</h5>
							<TableFilter />
						</div>
					),
					center: true,
					selector: (row: { used: string }) => {
						return row.used != undefined ? row.used : '-';
					},
					width: '10%'
				}
			];

			if (isInPopUp) {
				libraryTable = [
					{
						name: '',
						cell: (row: IMusic) => {
							return (
								<Button onClick={() => handleMusicSelection(row)} className='add-music-modal-btn'>
									{selectedRows.find((music: IMusic) => music.id === row.id) ? (
										<IconTikMark className='icon-tik' />
									) : (
										<div className='icon-plus'>
											<AddHashTagIcon />
										</div>
									)}
								</Button>
							);
						},
						width: '100px'
					},
					...libraryTable
				];
			}
			return libraryTable;
		} else if (activeMusicTab === 'ugc' && musicData.length > 0) {
			return [
				{
					name: (
						<div className='flex-center'>
							<Music className='music-icon' />
							<h5 className='mb-0 ml-10 font-weight--600 font--16px'>Title</h5>
						</div>
					),
					center: true,
					cell: ({ title, imageUrl }: IMusic) => {
						return (
							<div className='music-user-img'>
								<img src={imageUrl ?? MusicImage} alt='userImage' />
								<span className='text-truncate text--red-hat-display' title={title}>
									{title || '-'}
								</span>
							</div>
						);
					},
					width: '20%'
				},
				{
					name: (
						<div className='flex-center'>
							<Artist />
							<h5 className='mb-0 ml-10 font-weight--600 font--16px'>User</h5>
						</div>
					),
					center: true,
					selector: (row: { userName: string }) => {
						return row.userName ? row.userName : '-';
					},
					width: '10%',
					minWidth: '150px'
				},
				{
					name: (
						<div className='flex-center'>
							<Music className='music-icon' />
							<h5 className='mb-0 ml-10 font-weight--600 font--16px'>Song</h5>
						</div>
					),
					center: true,
					selector: (row: { title: string }) => {
						return row.title ? row.title : '--';
					},
					width: '20%',
					minWidth: '200px'
				},
				{
					name: (
						<div className='flex-center'>
							<Artist />
							<h5 className='mb-0 ml-10 font-weight--600 font--16px'>Artist</h5>
						</div>
					),
					selector: (row: { artist: string }) => <p className='music-info-text'>{row.artist || '-'}</p>,
					flex: 1,
					width: '15%',
					center: true
				},
				{
					name: (
						<div className='flex-center' onClick={() => handleMusicOrder(OrderColumn.DURATION)}>
							<Clock />
							<h5 className='mb-0 ml-5 mr-5 font-weight--600 font--16px'>Duration</h5>
							<TableFilter />
						</div>
					),
					center: true,
					selector: (row: { duration: string }) => {
						return (
							<p className='music-info-text'>
								{row.duration != undefined ? getMinSecDuration(row.duration) : '-'}
							</p>
						);
					},
					width: '15%'
				},
				{
					name: (
						<div className='flex-center' onClick={() => handleMusicOrder(OrderColumn.TOTAL_PLAY)}>
							<h5 className='mb-0 mr-5 font-weight--600 font--16px'>Plays</h5>
							<TableFilter />
						</div>
					),
					center: true,
					selector: (row: { plays: string }) => {
						return row.plays != undefined ? row.plays : '-';
					},
					width: '10%'
				},
				{
					name: (
						<div className='flex-center' onClick={() => handleMusicOrder(OrderColumn.TOTAL_USED)}>
							<h5 className='mb-0 mr-5 font-weight--600 font--16px'>Used</h5>
							<TableFilter />
						</div>
					),
					center: true,
					selector: (row: { used: string }) => {
						return row.used != undefined ? row.used : '-';
					},
					width: '10%'
				}
			];
		} else {
			return [];
		}
	}, [activeMusicTab, musicData, selectedRows]);

	return (
		<div className='App'>
			<DataTable
				className='music-table-wrapper'
				columns={columns as TableColumn<any>[]}
				data={musicData}
				highlightOnHover
				progressPending={musicLoader}
				progressComponent={<Loader className='music-data-loading' />}
				theme='solarized'
				noDataComponent={
					<div className='user-profile-icon'>
						<p className='user-profile-text'>No Music Found</p>
					</div>
				}
			/>
			{pagination.total > 0 && !musicLoader && (
				<Pagination
					handlePageClick={(page: number) => handlePagination(page, recordPerPage)}
					handlePageSelection={(perPage: number) => handlePagination(currentPage, perPage)}
					loading={musicLoader}
					pagination={pagination}
				/>
			)}
		</div>
	);
};

export default MusicList;
