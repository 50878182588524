import React, { PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';

import { SliderCloseIcon } from 'shared/icons/icons';

export interface IViralUniversalModalProps extends PropsWithChildren {
	show: boolean;
	handleClose?: () => void;
	className?: string;
	loading?: boolean;
	size?: 'lg' | 'sm' | 'xl';
}

const ViralUniversalModal: React.FC<IViralUniversalModalProps> = (props) => {
	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			className={`${props.className || ''} fadeIn`}
			size={props.size || 'lg'}
		>
			<Modal.Body>
				<div className='modal-close-btn' onClick={props.handleClose}>
					<SliderCloseIcon />
				</div>
				{props.children}
			</Modal.Body>
		</Modal>
	);
};

export default ViralUniversalModal;
