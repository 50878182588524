import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

const errorMessages = {
	required: (field) => i18n.t(localizationConstants.requiredFieldValidation, { field: i18n.t(field) }),
	select: (field) => i18n.t(localizationConstants.fieldSelectionValidation, { field: i18n.t(field) }),
	exactLength: (field, length) =>
		i18n.t(localizationConstants.exactLengthValidation, { field: i18n.t(field), length }),
	minLength: (field, length) =>
		i18n.t(localizationConstants.minLengthStrValidation, { field: i18n.t(field), length }),
	minLengthArray: (field, length) =>
		i18n.t(localizationConstants.minLengthArrValidation, { field: i18n.t(field), length }),
	maxLength: (field, length) =>
		i18n.t(localizationConstants.maxLengthStrValidation, { field: i18n.t(field), length }),
	maxLengthArray: (field, length) =>
		i18n.t(localizationConstants.maxLengthArrValidation, { field: i18n.t(field), length }),
	minValue: (field, value) => i18n.t(localizationConstants.minValueValidation, { field: i18n.t(field), value }),
	maxValue: (field, value) => i18n.t(localizationConstants.maxValueValidation, { field: i18n.t(field), value }),
	validType: (field) => i18n.t(localizationConstants.typeValidation, { field: i18n.t(field), type: i18n.t(field) }),
	hardwareKeyValidate: () => i18n.t(localizationConstants.hardwareKeyValidation),
	passwordValidate: () => i18n.t(localizationConstants.passwordValidation),
	passwordMatchValidate: () => i18n.t(localizationConstants.passwordMatchValidation),
	amountValidate: (field) => i18n.t(localizationConstants.amountValidation, { field: i18n.t(field) })
};

const onConfirmMessages = {
	confirmDelete: (field) => i18n.t(localizationConstants.confirmDeleteMsg, { field: i18n.t(field) }),
	confirmLogout: () => i18n.t(localizationConstants.confirmLogoutMsg)
};

export { errorMessages, onConfirmMessages };
