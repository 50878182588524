/* tslint:disable */
const de = {
	dashboardTitle: 'Dashboard',
	autoGeneratedLicenseGraphTitle: 'Automatisch generierte Lizenz',
	upcomingCreatedLicenseGraphTitle: 'Insgesamt erstellte Lizenz',
	daily: 'täglich',
	weekly: 'wöchentlich',
	monthly: 'monatlich',
	today: 'Heute',
	yesterday: 'Gestern',
	thisWeek: 'Diese Woche',
	lastWeek: 'Letzte Woche',
	thisMonth: 'Diesen Monat',
	lastMonth: 'Im vergangenen Monat',
	thisYear: 'Dieses Jahr',
	lastYear: 'Vergangenes Jahr',
	custom: 'Datum wählen (Brauch)',
	backBtnText: 'Zurück',
	organizationsTitle: 'Organisationen',
	superAdmin: 'superAdmin',
	license: 'Lizenz',
	support: 'Support',
	licenses: 'Lizenzen',
	incomingLicensesTitle: 'Lizenzanfragen',
	machineListTitle: 'Maschinenliste',
	excludeDebAutoId: 'DEB-Auto-IDs ausschließen',
	enterDebAutoIds: 'DEB-Auto-IDs eingeben',
	excludeCustomerId: 'Kundennummern ausschließen',
	enterCustomerIds: 'Kundennummern eingeben',
	excludedIdsList: 'Liste der ausgeschlossenen IDs',
	excludeFilterPlaceholder: 'XXXXX ⏎',
	excludeFilterNote: 'Drücken Sie die Eingabetaste (⏎) oder die Leertaste, um das Tag zu erstellen.',
	excludeFilterErrorMessage: 'Die eingegebene ID ist bereits ausgeschlossen, bitte geben Sie eine andere ID ein.',
	addedRemovedTagMessage:
		'Sie haben eine ID eingegeben, die Sie gerade zum Entfernen markiert haben, sodass diese ID jetzt nicht entfernt wird.',
	undoRemove: 'Vorgang zum Entfernen rückgängig machen',
	remove: 'Entfernen',
	excludedId: 'Ausgeschlossene ID',
	toBeRemoveId: 'Zu entfernende ID',
	goingToExcludeId: 'ID wird ausgeschlossen',
	excludeFilter: 'Filter ausschließen',
	autoUpdateMachineTitle: 'Automatisches Update der Maschine',
	licenseNumberView: 'Lizenznummer anzeigen',
	orderCodeExtensions: 'Bestellcode-Erweiterungen',
	orderCodeExtensionsList: 'Bestellcode-Erweiterungsliste',
	configurations: 'Konfigurationen',
	configurationsList: 'Konfigurationsliste',
	editConfigurationValue: 'Konfigurationswert bearbeiten',
	order_code_id: 'id',
	order_code_used: 'wird eingesetzt?',
	order_code_used_at: 'verwendet bei',
	users: 'Benutzer',
	user: 'Benutzer',
	list: 'liste',
	create: 'erstellen',
	yesCreateAndSendMail: 'JA, E-MAIL ERSTELLEN UND SENDEN',
	createAndSendMail: 'e-mail erstellen und senden',
	yesCreate: 'JA, ERSTELLEN',
	yesReCreate: 'JA, NEU ERSTELLEN',
	rolesAndPermissions: 'Rollen & Berechtigungen',
	roles: 'Rollen',
	role: 'Rolle',
	status: 'Status',
	permissions: 'Berechtigungen',
	addUser: 'Nutzer hinzufügen',
	supportList: 'Support-Liste',
	licenseList: 'Lizenzliste',
	superAdminList: 'super-admin-liste',
	logout: 'Ausloggen',
	home: 'Hauptmenü',
	view: 'Ansicht',
	createLicense: 'Lizenz erstellen',
	delete: 'Löschen',
	restore: 'Wiederherstellen',
	edit: 'Bearbeiten',
	save: 'sparen',
	all: 'Alles',
	select: 'Auswählen',
	customer: 'Kunde',
	Customer: 'Kunde',
	partner: 'Partner',
	Partner: 'Partner',
	customers: 'Kunden',
	customerList: 'Kundenliste',
	leads: 'Führt',
	search: 'Suche',
	licenseNumbers: 'Lizenznummern',
	licenseInformation: 'Lizenzinformationen',
	historyLogOfMachineFor: 'Verlaufsprotokoll der Maschine für',
	historyLogOfLicenseFor: 'Verlaufsprotokoll der Lizenz für',
	unassigned: 'Nicht zugewiesen',
	organizationDetails: 'Organisationsdetails',
	profile: 'Profil',
	editUser: 'Benutzer bearbeiten',
	notFoundMsg:
		'Es tut uns leid, aber die von dir gesuchte Seite wurde nicht gefunden. Überprüfe die URL auf Fehler und klicke dann in deinem Browser auf die Schaltfläche Aktualisieren oder versuche etwas anderes in unserer App zu finden.',
	pageNotFound: 'Seite nicht gefunden',
	actions: 'Aktionen',
	orgTimeline: 'Organisationszeitleiste',
	noDataFoundText: 'Keine Daten gefunden',
	serialNumber: 'Nr.',
	name: 'Name',
	code: 'Code',
	taxRate: 'Steuersatz',
	taxSet: 'Steuersatz',
	taxRateName: 'Name des Steuersatzes',
	country: 'Land',
	update: 'Aktualisieren',
	email: 'Email',
	recipientEmail: 'Empfänger E-Mail',
	senderEmail: 'Absender E-Mail',
	password: 'Passwort',
	phoenixPassword: 'Bitte lege dein Passwort fest',
	phoenixPasswordSuccessfullyReset: 'Passwort erfolgreich zurückgesetzt!',
	oldPassword: 'Altes Passwort',
	newPassword: 'Neues Passwort',
	confirmPassword: 'Passwort bestätigen',
	passwordConfirmation: 'Passwort Bestätigung',
	createdAt: 'Erstellt am',
	updatedAt: 'Aktualisiert am',
	functions: 'Funktionen',
	hardwareKey: 'Hardwarekey',
	noOfRecords: 'Anzahl der Maschinen',
	noOfRecordsMinimumValidationMessage: 'Der Wert muss größer als 0 (Null) sein',
	hw_id: 'Hardware ID',
	licenseNumber: 'Lizenznummer',
	expiryDateType: 'Art des Ablaufdatums',
	week: 'Woche',
	weeks: 'Wochen',
	year: 'Jahr',
	month: 'Monat',
	months: 'Monate',
	typeValue: 'Typ Wert',
	value: 'Wert',
	nextButton: 'Weiter',
	expiryDate: 'Ablaufdatum',
	autoLicense: 'Autolizenz',
	version: 'Ausführung',
	timestampInsert: 'Eingefügt um',
	timestampUpdate: 'Aktualisiert am',
	activeLicense: 'Aktive Lizenz',
	nextLicense: 'Aktive Lizenz',
	address: 'Adresse',
	postal_code: 'Postleitzahl',
	update_able: 'aktualisierbar',
	version_2: 'Version 2',
	sql_server: 'SQL Server',
	beauty: 'Beauty',
	boutique: 'Boutique',
	friseur: 'Friseur',
	direktverkauf: 'Direktverkauf',
	baeckerei: 'Bäckerei',
	kiosk: 'Kiosk',
	hospitality: 'Gastro',
	retail: 'Verkauf',
	fun_version: 'Fun Version',
	change_of_days: 'Wechsel der Tage',
	pb_p_version: 'PB P Version',
	db_version: 'DB Version',
	is_locked: 'Ist gesperrt',
	hashcode: 'Hash-Code',
	auto_license: 'Autolizenz',
	auto_update: 'Auto-Update',
	windows_version: 'Windows Version',
	mpos: 'mpos',
	wawi: 'WaWi',
	order_code: 'Bestellcode',
	configuration_name: 'Konfigurationsname',
	configuration_value: 'Konfigurationswert',
	timestamp_insert: 'Zeitstempel einfügen',
	timestamp_update: 'Zeitstempel-Update',
	partner_id: 'Partner-ID',
	swn_name: 'SWN Name',
	swn_surname: 'SWN Familienname',
	sepa_name: 'SEPA Name',
	sepa_surname: 'SEPA Nachname',
	bank_account_name: 'Kontoname',
	bank_name: 'Bank Name',
	bank_iban: 'Bank IBAN',
	iban: 'IBAN',
	amount: 'Betragen',
	software: 'Software',
	bank_bic: 'Bank BIC',
	autoUpdate: 'Automatisches Update',
	autoUpdateOn: 'Schalten Sie die automatische Aktualisierung ein',
	autoUpdateOff: 'Automatisches Update deaktivieren',
	validFrom: 'Gültig ab',
	validTo: 'Gültig bis',
	notUploaded: 'Nicht hochgeladen',
	newLicense: 'Neue Lizenz',
	manageModules: 'Module verwalten',
	viewModules: 'Module anzeigen',
	lock: 'Sperren',
	locked: 'Verschlossen',
	unlock: 'Freischalten',
	unlocked: 'Entsperrt',
	licenseLock: 'Lizenzsperre',
	licenseUnlock: 'Lizenz entsperren',
	machineLock: 'Maschinenschloss',
	machineUnlock: 'Maschine entsperren',
	licenseLocked: 'Lizenznummer gesperrt',
	licenseUnlocked: 'Lizenznummer freigeschaltet',
	machineLocked: 'Maschine gesperrt',
	machineUnlocked: 'Maschine entsperrt',
	machineAutoLicenseEnabled: 'Automatische Computerlizenz aktiviert',
	machineAutoLicenseDisabled: 'Automatische Computerlizenz deaktiviert',
	sendLicenseEmail: 'Lizenz-E-Mail senden',
	generate: 'Generieren',
	copyEmailAndPasswordToClipboard: 'E-Mail und Passwort in die Zwischenablage kopieren',
	submit: 'Speichern',
	submitWithEmail: 'Mit E-Mail senden',
	cancel: 'Abbrechen',
	yes: 'Ja',
	no: 'Nein',
	on: 'Auf',
	off: 'Aus',
	archived: 'archiviert',
	archive: 'Archiv',
	active: 'aktiv',
	Active: 'Aktiv',
	addNewUser: 'Neuen Benutzer hinzufügen',
	createTaxRule: 'Neue Steuerregel erstellen',
	id: 'Id',
	module: 'Modul',
	close: 'Schließen',
	noUsersAvailable: 'Keine Benutzer verfügbar',
	slug: 'Schnecke',
	language: 'Sprache',
	german: 'Deutsch',
	english: 'Englisch',
	noPermissionsAvailable: 'Keine Berechtigungen verfügbar',
	changePassword: 'Passwort ändern',
	updateProfileTitle: 'Profil Aktualisieren',
	changePasswordTitle: 'Ändere Dein Passwort',
	editUserDetails: 'Benutzerdetails bearbeiten',
	newRegisteredUser: 'Neuer Benutzer registrieren',
	inActive: 'inaktiv',
	blocked: 'Gesperrt',
	incomingLicenseList: 'Lizenzanfragen',
	licenseNumbersList: 'Liste der Lizenznummern',
	forgetPassword: 'Passwort vergessen',
	getPasswordResetCode: 'Email senden',
	resetInstructionsAreSentToYourEmail:
		'Wir haben eine Nachricht an die angegebene Email-Adresse gesendet. Dort findest Du einen Link um das Passwort zurückzusetzen.',
	backToLogin: 'Zurück zur Anmeldung',
	noMachinesFound: 'Keine Maschinen gefunden',
	resetYourPassword: 'Passwort zurücksetzen',
	resetPassword: 'Passwort zurücksetzen',
	generatePassword: 'Generiere Passwort',
	login: 'Anmeldung',
	hello: 'Hallo',
	licenseDetailsAreHere: 'Deine Lizenzdetails findest du hier',
	thankYou: 'Danke',
	licenseReport: 'Lizenzbericht',
	standard: 'Standard',
	premium: 'Premium',
	demo: 'Demo',
	fun: 'Fun',
	default: 'Standard',
	sql: 'SQL',
	addItem: 'Artikel hinzufügen',
	item: 'Artikel',
	OK: 'okay',
	inventoryGroupName: 'Name der Inventargruppe',
	addLicense: 'Lizenz hinzufügen',
	addHardware: 'Hardware hinzufügen',
	add: 'Hinzufügen',
	addWithEmail: 'Mit E-Mail hinzufügen',
	send: 'senden',
	openEmail: 'E-Mail öffnen',
	addNewRole: 'Neue Rolle hinzufügen',
	editRole: 'Rolle bearbeiten',
	customerData: 'Kundendaten',
	name1: 'Name 1',
	name2: 'Name 2',
	street: 'Straße',
	zipCode: 'Postleitzahl',
	city: 'Stadt',
	nation: 'Nation',
	phone: 'Telefon',
	fax: 'Fax',
	ust: 'Ust',
	expiryDateValidate: 'Bitte wähle das Ablaufdatum',
	'Activate Auto License': 'Autolizenz aktivieren',
	to: 'an',
	cc: 'CC',
	subject: 'Betreff',
	content: 'Inhalt',
	requiredFieldValidation: '{{field}} ist erforderlich',
	fieldSelectionValidation: 'Bitte {{field}} auswählen',
	exactLengthValidation: '{{field}} muss genau {{length}} Zeichen haben!',
	minLengthStrValidation: '{{field}} muss länger als {{length}} Zeichen sein!',
	minLengthArrValidation: '{{field}} muss mindestens {{length}} Elemente enthalten!',
	maxLengthStrValidation: '{{field}} muss kürzer als {{length}} Zeichen sein!',
	maxLengthArrValidation: '{{field}} darf maximal {{length}} artikel enthalten!',
	minValueValidation: '{{field}} muss größer oder gleich {{value}} sein!',
	maxValueValidation: '{{field}} muss kleiner oder gleich {{value}}!',
	typeValidation: '{{field}} muss eine gültige {{type}} sein!',
	hardwareKeyValidation: 'Der Hardwarekey darf nur Ziffern enthalten (darf nicht mit 0 beginnen)',
	passwordValidation:
		'Das Passwort muss mindestens 8 Zeichen enthalten, einschließlich eines Großbuchstabens, eines Kleinbuchstaben und einer Zahl sowie eines Sonderzeichens. Es darf keine Leerzeichen enthalten',
	passwordMatchValidation: 'Passwörter stimmen nicht überein',
	amountValidation: '{{field}} muss ein gültiger Wert sein!',
	confirmRestoreMsg: 'Möchtest du den {{field}} wirklich wiederherstellen?',
	confirmDeleteMsg: 'Möchtest du den {{field}} wirklich entfernen?',
	confirmLogoutMsg: 'Möchtest du dich wirklich abmelden?',
	confirmAutoLicense:
		'Möchtest du den Status der automatischen Lizenz für eine bestimmte Lizenz wirklich aktualisieren?',
	confirmChangeLicenseVersion: 'Möchtest du die Lizenzversion wirklich ändern?',
	confirmChangeLockStatus: 'Möchtest du den Lizenzsperrstatus wirklich ändern?',
	confirmChangeMachineLockStatus: 'Möchtest du den Status der Maschinensperre wirklich ändern?',
	confirmMachineAutoLicense: 'Möchtest du den Status der automatischen Computerlizenz wirklich ändern?',
	noLicensesFound: 'Keine Lizenzen gefunden',
	noOrderCodeExtensionFound: 'Keine Bestellcode-Erweiterung gefunden',
	noApiKeyFound: 'Kein API-Schlüssel gefunden',
	noTaxRuleFound: 'Name des Steuersatzes',
	noExpiredLicensesFound: 'Keine abgelaufenen Lizenzen gefunden',
	addHardwareNotAllowed:
		'Du kannst für diese Organisation keine Hardware hinzufügen, da die Kunden-ID nicht gültig ist',
	customerIdRequirements: '(Das Feld Kundennummer muss eine Zahl sein und zwischen 1 und 99999 liegen.)',
	givenCustomerIdIsNotValid: 'Kunden-ID - {{customerId}} ist ungülti',
	relateMachineToCustomer: 'Maschine dem Kunden zuordnen',
	relateCompanyToCustomer: 'Unternehmen mit Kunden in Verbindung bringen',
	newCustomer: 'Neukunde',
	customerId: 'Kundennummer',
	customerIds: 'Kundennummern',
	customer_id: 'Kundennummer',
	copied: 'Kopiert',
	copyText: 'Text Kopieren',
	difference: 'Unterschied',
	relate: 'zuordnen',
	relateAndJumpToCustomer: 'Verknüpfe und springe zum Kunden',
	details: 'Einzelheiten',
	totalRecords: 'Gesamtzahl der Datensätze',
	apply: 'Anwenden',
	canNotCreateCustomerMsg: 'Du kannst keinen neuen Kunden erstellen.',
	next: 'Nächste',
	previous: 'früher',
	perPage: 'Pro Seite',
	paid: 'Bezahlt',
	unpaid: 'Unbezahlt',
	manual: 'Manuell',
	invalid: 'Geplatzt',

	internal: 'intern',
	global: 'global',
	local: 'lokal',

	birdhubAdmin: 'birdhubAdmin',
	birdhubAdminTitle: 'Birdhub Admin',
	credentials: 'Referenzen',
	credentialList: 'Anmeldeinformationsliste',
	addNewCredential: 'Neuen Berechtigungsnachweis hinzufügen',
	addCredential: 'Anmeldeinformationen hinzufügen',
	updateCredential: 'Anmeldeinformationen aktualisieren',
	credentialInfo: 'Anmeldeinformationen',
	orderDetail: 'Bestelldetails',
	ordersList: 'Bestellliste',
	orderItems: 'Auftragspositionen',
	order_id: 'Auftragsnummer',
	customer_title: 'Kunde',
	order_date: 'Auftragsdatum',
	shop_code: 'Shop-Code',
	total_discount: 'Totaler Rabatt',
	total_gross: 'Gesamtbrutto',
	total_net: 'Gesamtnetto',
	total_shipping_cost: 'Gesamtversandkosten',
	total_vat: 'Gesamt MwSt',
	item_id: 'Id',
	item_text: 'Artikel',
	single_price: 'Stückpreis',
	total_price: 'Gesamtpreis',
	new: 'Neu',
	dispatched: 'Versendet',
	delivered: 'Geliefert',
	refused: 'Abgelehnt',
	cancelled: 'Abgesagt',
	clients: 'Kunden',

	apiId: 'API-ID',
	apiSecretKey: 'Geheimer Api-Schlüssel',
	type: 'Art',
	note: 'Hinweis',
	notes: 'Anmerkungen',

	companyList: 'Firmenliste',
	companyDetail: 'Firmendetail',
	companyEdit: 'Firma bearbeiten',
	companyId: 'Firmen-ID',
	markAsCreated: 'Als erstellt markieren',
	markAsComplete: 'Als abgeschlossen markieren',
	transactionId: 'Transaktions-ID',
	phoenixIncomingLicenseList: 'Phoenix Eingehende Lizenzliste',
	phoenixIncomingLicenseTitle: 'Phoenix Incoming-Lizenzen',
	isEmailVerified: 'Ist die E-Mail-Adresse bestätigt?',
	emailVerifiedAt: 'E-Mail bestätigt um',
	verified: 'verifiziert',
	priceInInventoryGroup: 'Preis in Inventargruppe',
	priceNotInInventoryGroup: 'Preis nicht in Inventargruppe',
	pending: 'steht aus',
	phoenix: 'Phoenix',
	accepted: 'Akzeptiert',
	rejected: 'Hat abgelehnt',
	completed: 'Abgeschlossen',
	failed: 'Gescheitert',
	expired: 'abgelaufen',
	added: 'Hinzugefügt',
	notAdded: 'Nicht hinzugefügt',
	subscriptions: 'Abonnements',
	subscriptionId: 'Abonnement-ID',
	additionalUsers: 'Zusätzliche Benutzer',
	additionalFeatures: 'Zusatzfunktionen',
	bankInfo: 'Bank Info',
	universeId: 'Universe-ID',
	companyName: 'Name der Firma',
	isVerified: 'isVerified',
	unlimited: 'Unbegrenzt',
	setUnlimited: 'Unbegrenzt einstellen',
	manageSubscription: 'Abonnement verwalten',
	resetMasterPassword: 'Master-Passwort zurücksetzen',
	manageExpiryDate: 'Ablaufdatum verwalten',
	addTaxRule: 'Steuerregel hinzufügen',
	editTaxRule: 'Steuerregel bearbeiten',
	manageUser: 'Benutzer verwalten',
	companyLocked: 'Firma gesperrt',
	companyUnlocked: 'Unternehmen freigeschaltet',
	confirmChangeCompanyLockStatus: 'Möchtest du den Sperrstatus der Firma wirklich ändern?',
	confirmCancelSubscription: 'Möchten Sie das Abonnement wirklich kündigen?',
	confirmSetPhoenixLicenseDateUnlimited:
		'Möchtest du das Ablaufdatum der Lizenz wirklich auf unbegrenzt setzen? (01-01-1970)?',
	confirmChangeActiveLicense: 'Möchtest du diese Lizenz wirklich als aktiv festlegen?',
	confirmDeleteLicense: 'Möchtest du diese Lizenz wirklich löschen?',
	confirmGetValidLicense: 'Möchtest du wirklich gültige Lizenzen für diese Hardware erhalten?',
	confirmResetPassword: 'Möchtest du das Passwort wirklich zurücksetzen?',
	confirmGeneratePassword: 'Möchtest du sicher ein Passwort generieren?',
	companySubscriptionEmptyMessage:
		'Es gibt kein Abonnement. Bitte wähle ein Abonnement, bevor du die Module auswählst.',
	getValidLicense: 'gültige Lizenz erhalten',
	confirmChangeUsedOrderCodeStatus: 'Möchten Sie den Status des Bestellcodes wirklich ändern?',

	modules: 'Module',
	moduleList: 'Modulliste',
	addNewModule: 'Neues Modul hinzufügen',
	addModule: 'Modul hinzufügen',
	updateModule: 'Modul aktualisieren',
	price: 'Preis',

	package: 'Paket',
	packages: 'Pakete',
	packageList: 'Paketliste',
	updatePackage: 'Paket aktualisieren',
	phoenixId: 'Phoenix Id',
	description: 'Beschreibung',

	apiKeyList: 'API-Schlüsselliste',
	defaultUserCount: 'Standardbenutzer',
	currentDefaultUsers: 'Aktuelle Standardbenutzer',
	currentTotalUsers: 'Aktuelle Benutzer insgesamt',
	newDefaultUsers: 'Neue Standardbenutzer',
	newTotalUsers: 'Neue Benutzer insgesamt',

	jumpbirdIncomingLicenseList: 'Jumpbird Eingehende Lizenzliste',
	jumpbirdIncomingLicenseTitle: 'Jumpbird Incoming-Lizenzen',
	agb_gdpdr: 'AGB & Datenschutz',
	sepa_accepted_at: 'Wird SEPA akzeptiert?',
	licenseInfo: 'Lizenz Info',
	packageInfo: 'Paket Info',
	companies: 'Firmen',
	company_name: 'Company Name',
	is_advertising: 'Is Advertising?',
	contact_person_first_name: 'Contact Person First Name',
	contact_person_last_name: 'Contact Person Last Name',
	registration_date: 'Registration Date',
	updated_at: 'Updated At',
	onboard_from: 'OnBoard From',
	terms_conditions_url: 'Terms Conditions URL',
	package_code: 'Package Code',
	start_date: 'Start Date',
	end_date: 'End Date',
	subscription_status: 'Subscription Status',
	bank_account_owner_name: 'Bank Account Owner Name',
	tax_id: 'Tax Id',
	// "iban": "IBAN",
	bic: 'BIC',
	website: 'Website',
	block_reason: 'Sperrgrund',
	paymentMethod: 'Bezahlverfahren',
	manageSepa: 'SEPA verwalten',
	accept: 'Annehmen',
	reject: 'Ablehnen',
	reason: 'Grund',
	rejectReasonLabel: 'Schreiben Sie einen Ablehnungsgrund.',
	blockReasonLabel: 'Schreiben Sie einen Grund für die Sperrung des Unternehmens.',
	cancelSubscriptionReasonLabel: 'Schreiben Sie einen Grund für die Kündigung des Abonnements.',
	companyBlocked: 'Unternehmen gesperrt',
	companyUnblocked: 'Unternehmen entsperrt',
	searchByCompanyNameOrEmail: 'Suche nach Firmennamen oder E-Mail',
	cancelSubscription: 'Abonnement kündigen',
	nex_billing_date: 'Nächstes Abrechnungsdatum',
	assignPackage: 'Abonnement zuweisen',
	bankAccountOwnerName: 'Name des Bankkontoinhabers',
	billingCycles: 'Abrechnungszyklen',

	jumpbirdAdmin: 'jumpbirdAdmin',
	jumpbirdAdminTitle: 'Jumpbird Admin',

	tse: 'TSE',
	tseSerialUpload: 'TSE Serial Upload',
	tseDevice: 'TSE-Gerät',
	tseDeviceDetail: 'TSE-Gerätedetail',
	tseDeviceList: 'TSE-Geräteliste',
	importCSV: 'CSV importieren',
	deviceFile: 'Gerätedatei',
	file: 'Datei',
	upload: 'Hochladen',
	supportedType: 'Unterstützter Typ',
	downloadSampleFile: 'Beispieldatei herunterladen',
	activate: 'aktivieren Sie',
	confirmDeviceActivateMsg: 'Möchtest du die Maschine wirklich aktivieren?',
	date: 'Datum',
	deviceDetail: 'Gerätedetail',
	fieldName: 'Feldname',
	changedValue: 'Geänderter Wert',
	originalValue: 'Original Wert',
	activityLogs: 'Aktivitätsprotokolle',
	adminPin: 'Admin Pin',
	admin_pin: 'Admin Pin',
	adminPuk: 'Admin Puk',
	admin_puk: 'Admin Puk',
	timeAdminPin: 'Zeit Admin Pin',
	time_admin_pin: 'Zeit Admin Pin',
	timeAdminPuk: 'Zeit Admin Puk',
	time_admin_puk: 'Zeit Admin Puk',
	app: 'App',
	publicKey: 'Öffentlicher Schlüssel',
	public_key: 'Öffentlicher Schlüssel',
	secretKey: 'Geheimer Schlüssel',
	secret_key: 'Geheimer Schlüssel',
	activatedOn: 'Aktiviert am',
	activated_on: 'Aktiviert am',
	expiredAt: 'Abgelaufen bei',
	expired_at: 'Abgelaufen bei',
	uploadCount: 'Upload Count',
	tseDashboardTitle: 'TSE-Dashboard',
	tseBackupUpload: 'TSE Backup Upload',
	universeCustomers: 'Universe-Kunden',
	universeCustomer: 'Universe-Kunde',
	universeCustomerList: 'Universe-Kundenliste',
	tseCustomers: 'TSE-Kunden',
	tseCustomer: 'TSE-Kunde',
	tseCustomerList: 'TSE Kundenliste',
	tseCustomerDetail: 'TSE Kundendetail',
	backupStatistics: 'Sicherungsstatistik',
	portalAccount: 'Portal-Konto',
	backupCount: 'Backup-Anzahl',
	inProgressUpload: 'In Bearbeitung Hochladen',
	successfulUpload: 'Erfolgreiches Hochladen',
	failedUpload: 'Upload fehlgeschlagen',
	firstUploadAt: 'Erstes Upload-Datum',
	lastUploadAt: 'Letztes Upload-Datum',
	backupList: 'Sicherungsliste',

	downloadRequests: 'Anfragen herunterladen',
	downloadRequestList: 'Anforderungsliste herunterladen',

	debAutoId: 'DEB-Auto',
	suggested: 'Vorgeschlagene',
	fileName: 'Dateiname',
	fileSize: 'Dateigröße',
	serial_number: 'Ordnungsnummer',
	checksum: 'Prüfsumme',
	workingDate: 'Arbeitsdatum',
	expiringDate: 'Ablaufdatum',
	progressing: 'Fortschritt',
	inProgress: 'In Bearbeitung',
	uploaded: 'Hochgeladen',
	downloaded: 'Heruntergeladen',
	createDownloadRequest: 'Download-Anfrage erstellen',
	activeBackupData: 'Aktive Sicherungsdaten',
	backupInProgressText: 'Dein Backup wird ausgeführt ...',
	backupReadyText: 'Dein Backup ist fertig',
	backupFiles: 'Sicherungs-Dateien',
	generateDownloadLink: 'Download-Link generieren',
	generatedLinkText: 'Der Backup-Link wird generiert. Klicke auf den Download-Button',
	downloadBackup: 'Backup herunterladen',
	confirmCreateDownloadRequest:
		'Wenn du eine neue Sicherung anforderst, wird die alte heruntergeladene Sicherung entfernt. Möchtest du wirklich fortfahren?',
	addNewCustomer: 'Neuen Kunden hinzufügen',
	sendMailText: 'Bestätigungs-E-Mail an den Kunden senden?',
	sendMailNoteText: 'Wenn nein, wird das Kundenkonto automatisch verifiziert.',

	tools: 'Werkzeuge',
	zmList: 'ZM-Liste',
	selectFile: 'Datei aussuchen',
	csvUpload: 'Hochladen der ZMList-Eingabedatei',
	inputCloudPath: 'Cloud-Pfad eingeben',
	processing: 'Wird bearbeitet',
	processed: 'Verarbeitet',
	requestDownload: 'Download anfordern',
	refresh: 'Aktualisieren',
	download: 'Herunterladen',
	please_select_csv_file: 'Bitte CSV-Datei auswählen.',
	please_select_valid_csv_file: 'Bitte wählen Sie eine gültige CSV-Datei aus.',

	emailVerification: 'E-Mail-Verifizierung',
	verifyingEmail: 'E-Mail überprüfen ...',
	successfulEmailVerified: 'E-Mail erfolgreich verifiziert!',
	companyCreation: 'Unternehmensgründung',
	creatingCompany: 'Unternehmen gründen ...',
	startPhoenixNow: 'Starte jetzt mit Phoenix',
	redirectToPhoenix: 'Gehe zum Phoenix Portal',
	companyAlreadyValidatedMessage: 'Deine Firma ist schon eingerichtet! Klicke auf den Link um dich einzuloggen',
	companyVerificationSuccessMessage: 'Deine Anmeldung war erfolgreich!',
	companyVerificationFailMessage: 'Es tut uns leid. Deine Firma konnte nicht verifiziert werden!',
	companyVerificationTimeoutMessage:
		'Das erstellen deiner Firma dauert unerwartet ein wenig länger, du kannst dich aber gleich unter folgendem Link einloggen',

	fetchDataText: 'Filter anwenden, um Daten abzurufen',
	confirmChangeAutoUpdateMachineStatus: 'Möchtest du den Computer wirklich automatisch aktualisieren?',
	filter: 'Filter',
	selectFilterOptionText: 'Filteroption auswählen',
	filterSelectedText: 'Filter ausgewählt',
	noFilterSelected: 'Kein Filter ausgewählt',
	pbpVersion: 'PB P Version',
	dbVersion: 'DB Version',

	enable: 'Aktivieren',
	disable: 'Deaktivieren',
	enabled: 'Aktiviert',
	disabled: 'Deaktiviert',

	gep: 'GEP',
	bez: 'BEZ',
	changings: 'Änderungen',
	phoenixGEP: 'Phoenix GEP',
	phoenixBEZ: 'Phoenix BEZ',
	blockAllLicenses: 'Alle Lizenzen blockieren',
	unblockAllLicenses: 'Alle Lizenzen entsperren',
	blockAllCompany: 'Alle Unternehmen blockieren',
	unblockAllCompany: 'Alle Unternehmen entsperren',
	key: 'Schlüssel',
	oldValue: 'Alter Wert',
	newValue: 'Neuer Wert',
	same: 'gleich',
	changed: 'geändert',
	confirmFieldSubmitMsg: 'Bist du sicher, dass du die Formularfelddaten senden möchtest?',
	confirmDeleteTaxRule: 'Möchten Sie die Steuerregel wirklich löschen?',
	sendEmail: 'E-Mail senden',
	reset: 'Neutral',
	doNothing: 'Nichts tun',

	no_label: 'Kein Etikett',
	non_customer: 'Nicht Kunde',
	non_partner: 'Nicht Partner',
	vip: 'VIP',
	non_vip: 'Nicht VIP',

	none: 'Keiner',
	showMore: 'Zeig mehr',
	showLess: 'Zeige weniger',

	provision: 'Bereitstellung',
	accounting: 'Buchhaltung',
	invoices: 'Rechnungen',
	invoice: 'Rechnung',
	inventories: 'Lagerbestände',
	inventoryGroup: 'Inventargruppe',
	invoiceGroupList: 'Rechnungsgruppenliste',
	inventoryGroupList: 'Inventargruppen Liste',
	addInventoryGroup: 'Inventargruppe hinzufügen',
	removeInvoice: 'Rechnung entfernen',
	downloadInvoicePDF: 'Rechnung PDF herunterladen',
	taxRules: 'Steuerregeln',
	documents: 'Unterlagen',
	documentList: 'Dokumentenliste',
	sevDeskNotLinked: 'SevDesk ist noch nicht mit diesem Partner verbunden.',
	sevDeskNotInitiated: 'SevDesk ist noch nicht gestartet.',
	sevDeskTaxRules: 'SevDesk-Steuerregeln',
	createNote: 'Notiz erstellen',
	createInvoice: 'Rechnung erstellen',
	generateInvoicePDF: 'Rechnungs-PDF generieren',
	createInventoriyGroup: 'Inventargruppe erstellen',
	updateInventoriyGroup: 'Inventargruppe aktualisieren',
	invoiceWithoutTax: 'Rechnung ohne MwSt',
	reCreateNote: 'Notiz neu erstellen',
	creditNote: 'Gutschrift',
	periodOfService: 'Leistungszeitraum',
	serviceDuration: 'Dienstdauer',
	bankRetrievalDate: 'Bankabrufdatum',
	columnMapping: 'CSV-Spaltenzuordnung',
	mapped: 'Abgebildet',
	expand: 'Expandieren',
	expandAll: 'Alle erweitern',
	collapse: 'Zusammenbruch',
	collapseAll: 'Alles ausblenden',
	noteAlreadyCreated: 'Hinweis bereits erstellt',
	invoiceAlreadyCreated: 'Rechnung bereits erstellt',
	reportList: 'Berichtsliste',
	generateReport: 'Bericht generieren',
	bulkCreateCreditNote: 'Bulk-Gutschrift erstellen',
	creatingCreditNotes: 'Gutschriften erstellen',
	loading: 'Wird geladen',
	quarter: 'Quartal',
	reportDetail: 'Detail melden',
	exportCSV: 'CSV exportieren',
	manageProvisionReportExport: 'Provision Report Export exportieren',
	tradingDate: 'Handelsdatum',
	partnerList: 'Partnerliste',
	partnerId: 'Partner-ID',
	contractBeginDate: 'Vertragsbeginn',
	provisionAmount: 'Rückstellungsbetrag',
	provisionW: 'Rückstellung W.',
	totalMonth: 'Gesamtmonat',
	totalCommission: 'Gesamtprovision',
	sum: 'Summe',
	orderBy: 'Sortieren nach',
	orderByColumn: 'Nach Spalte sortieren',
	partner_deb_auto: 'Partner-ID',
	partner_org_name: 'Name der Partnerorganisation',
	total_amount: 'Gesamtmenge',
	currentTotal: 'Aktuelle Summe',
	netAmount: 'Netto-Betrag',
	invoiceAmount: 'Erwarteter Betrag',
	remaining: 'Verblieben',
	salesTax: 'Mehrwertsteuer',
	tax_amount: 'Steuerbetrag',
	inventoriesNotAvailable: 'Lagerbestände sind nicht vorhanden.',
	amountMatchedMessage: 'Gesamtbetrag stimmt mit erwartetem Betrag überein',
	amountNotMatchedMessage: 'Gesamtbetrag stimmt nicht mit dem erwarteten Betrag überein',
	asc: 'Aufsteigend',
	desc: 'Absteigend',
	commissionStatus: 'Provisionsstatus',
	commissionCalculationStatus: 'Provisionsberechnungsstatus',
	reportStatus: 'Berichtsstatus',
	confirmDeletePartnerCommission: 'Möchtest du die Partnerprovision wirklich entfernen?',
	confirmDeletePartnerCreditNote: 'Möchten Sie die Partnergutschrift wirklich entfernen?',
	confirmDeleteCustomerCommission: 'Möchtest du die Kundenprovision wirklich entfernen?',
	confirmCreateInvoiceMessage: 'Möchten Sie wirklich eine Rechnung erstellen?',
	confirmDeleteInvoiceMessage: 'Möchten Sie die Rechnung wirklich entfernen?',
	confirmArchiveInvoiceGroupMessage: 'Are you sure you want to archive this invoice group?',
	addCustomerCommission: 'Kundenprovision hinzufügen',
	searchCustomer: 'Kunden suchen',
	product: 'Produkt',
	quantity: 'Menge',
	autoCompleteSearchText: 'Gib mindestens 3 Zeichen ein, um zu suchen ...',
	noCustomerFound: 'Kein Kunde gefunden',
	somethingWentWrong: 'Etwas ist schief gelaufen. Bitte versuche es erneut!!',

	not_allowed: ' nicht erlaubt',
	pwd_s_amount_lowercase: 'mind. 1 Kleinbuchstabe',
	pwd_p_amount_lowercase: 'mind. {{count}} Kleinbuchstaben',
	pwd_s_amount_uppercase: 'mind. 1 Großbuchstabe',
	pwd_p_amount_uppercase: 'mind. {{count}} Großbuchstaben',
	pwd_s_amount_numbers: 'mind. 1 Zahl',
	pwd_p_amount_numbers: 'mind. {{count}} Zahlen',
	pwd_s_amount_special_characters: 'mind. 1 Sonderzeichen',
	pwd_p_amount_special_characters: 'mind. {{count}} Sonderzeichen',
	pwd_s_string_length: '1 oder mehr Zeichen',
	pwd_p_string_length: '{{count}} oder mehr Zeichen',

	licenseModule: 'Lizenzmodul',
	licenseModuleList: 'Liste der Lizenzmodule',
	modType: 'Mod-Typ',

	HardwareAdded: 'neue Hardware hinzugefügt',
	AssignedCustomer: 'hat einem Kunden eine Maschine zugewiesen',
	MachineUpdated: 'hat eine Maschine aktualisiert',
	PaymentStatusUpdated: 'hat einen Zahlungsstatus aktualisiert',
	LicenseGenerated: 'Lizenz generiert',
	LicenseActivated: 'Lizenz aktiviert',
	LicenseInactivated: 'Lizenz deaktiviert',
	LicenseAdded: 'hat eine Lizenz hinzugefügt',
	LicenseUpdated: 'hat eine Lizenz aktualisiert',
	LicenseDeleted: 'hat eine Lizenz gelöscht',
	LicenseEmailSent: 'hat Lizenzdetails per E-Mail gesendet',
	FutureLicenseActivated: 'hat eine zukünftige Lizenz aktiviert',
	ModuleUpdated: 'hat ein Modul aktualisiert',
	HardwareCloned: 'Hardware wurde geklont',
	LicenseCloned: 'Lizenz wurde geklont'
};
export default de;
