import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';

const challengeDate: string[] = [
	'challengeRegStartAt',
	'challengeRegEndAt',
	'challengeVoteStartAt',
	'challengeVoteEndAt'
];

const challengeTextArea = ['description', 'prize_description'];

interface IObj {
	[key: string]: string;
}

const scrollBehavior = { behavior: 'smooth', block: 'end' };

const ScrollToFieldError: FC = () => {
	const { submitCount, isValid, errors } = useFormikContext();

	useEffect(() => {
		if (isValid) return;

		const fieldErrorNames = getFieldErrorNames(errors);

		let element;
		if (fieldErrorNames[0] === 'prize_description' && fieldErrorNames.includes('description')) {
			element = document.querySelector('textarea[name=description]');
		} else {
			element = document.querySelector(
				`${challengeTextArea.includes(fieldErrorNames[0]) ? 'textarea' : 'input'}[${getFieldElement(
					fieldErrorNames[0]
				)}=${fieldErrorNames[0]}]`
			);
		}

		if (!element) return;

		// Scroll to first known error into view
		element.scrollIntoView(scrollBehavior as any);

		// Formik doesn't (yet) provide a callback for a client-failed submission,
		// thus why this is implemented through a hook that listens to changes on
		// the submit count.
	}, [submitCount, isValid, errors]);

	return <></>;
};

const getFieldErrorNames = (formikErrors) => {
	const transformObjectToDotNotation = (obj: IObj, prefix = '', result = []) => {
		Object.keys(obj).forEach((key) => {
			const value = obj[key];
			if (!value) return;
			const nextKey = /^[0-9]+$/.test(key) ? `${prefix}[${key}]` : prefix ? `${prefix}.${key}` : key;

			if (typeof value === 'object') {
				transformObjectToDotNotation(value, nextKey, result);
			} else {
				result.push(nextKey);
			}
		});
		return result;
	};

	return transformObjectToDotNotation(formikErrors);
};

const getFieldElement = (ele: string) => {
	let eleType = '';
	switch (ele) {
		case 'category':
			eleType = 'aria-label';
			break;
		case challengeDate.includes(ele) && ele:
			eleType = 'aria-labelledby';
			break;
		default:
			eleType = 'name';
			break;
	}

	return eleType;
};

export default ScrollToFieldError;
