import { useState } from 'react';

import { IAllVideoReports, IUserDetail, UserType } from '../interface/userManagement';

import SlickSLider from 'shared/components/slider/slick';
import { VideoIcon } from 'shared/icons/icons';
import UserManagementModal from '../modals/userManagementModal';
import { Loader } from 'shared/components/spinner/spinner';

export interface IUsersListProps {
	videoReportsData: IAllVideoReports;
	type: UserType;
	reportActionFlag?: boolean;
	reportTitle?: string;
	userDetails?: IUserDetail;
	reportCategoryId?: string;
	loading?: boolean;
	handleReportData?: (index: number) => void;
}

export const UsersReports: React.FC<IUsersListProps> = (props) => {
	const {
		userDetails,
		videoReportsData,
		type,
		reportTitle,
		loading,
		reportCategoryId,
		handleReportData,
		reportActionFlag
	} = props;

	const [showModalSlider, setShowModalSlider] = useState(false);
	const [currentClickedVideoIndex, setCurrentClickedVideoIndex] = useState(0);

	const handleVideoDetails = (index: number) => {
		setCurrentClickedVideoIndex(index);
		setShowModalSlider(true);
	};

	return (
		<>
			<section className='user-reports'>
				<div className='reports'>
					{type !== 'report' && type !== 'all' && (
						<div className='user-reports-data'>
							<h3 className='reports-id'>{`Reports ${type} @${
								userDetails?.userName ? userDetails?.userName : ''
							}`}</h3>
							<h3 className='reports-id-no'>{videoReportsData?.total}</h3>
						</div>
					)}
					{type == 'report' && (
						<div className='user-reports-data'>
							<h3 className='reports-category-title'>{` ${reportTitle}`}</h3>
						</div>
					)}
				</div>
				<div className='reports-slider-wrapper'>
					{loading && <Loader className='custom-loader' />}
					{!loading && (
						<>
							{videoReportsData.data?.length > 0 ? (
								<SlickSLider
									key={type}
									className={`report-slider ${
										type == 'report'
											? 'report-category-slider'
											: type == 'all'
											? 'report-slider-for-all-video'
											: ''
									}`}
									settings={type == 'all' ? sliderSettingsAllVideo : sliderSettings}
									data={videoReportsData.data}
									handleDetail={handleVideoDetails}
									categoryId={reportCategoryId}
									type={type}
									playingVideo
								/>
							) : (
								<>
									{type != 'report' && type != 'all' && (
										<div className='category-details'>
											<VideoIcon />
											<p className='video-text'>{`This user doesn't got any ${
												type !== ('report' as string) ? 'reports' : ''
											} ${type} yet.`}</p>
										</div>
									)}
									{type == 'all' && (
										<div className='category-details'>
											<VideoIcon />
											<p className='video-text'>{`This user doesn't got any videos yet.`}</p>
										</div>
									)}
									{type == 'report' && (
										<div className='category-details'>
											<VideoIcon />
											<p className='video-text'>{`This ${reportTitle} category doesn't got any videos yet.`}</p>
										</div>
									)}
								</>
							)}
						</>
					)}
				</div>
			</section>
			<UserManagementModal
				show={showModalSlider}
				handleClose={() => setShowModalSlider(false)}
				data={videoReportsData.data}
				className='viral-universal-modal'
				currentClickedVideoIndex={currentClickedVideoIndex}
				videoModalType={type}
				reportActionFlag={reportActionFlag}
				handleReportData={handleReportData}
			/>
		</>
	);
};

const sliderSettings = {
	slidesToShow: 6,
	slidesToScroll: 1,
	infinite: false,
	arrows: true,
	responsive: [
		{
			breakpoint: 1700,
			settings: {
				slidesToShow: 6
			}
		},
		{
			breakpoint: 1600,
			settings: {
				slidesToShow: 5
			}
		},
		{
			breakpoint: 1500,
			settings: {
				slidesToShow: 5
			}
		},
		{
			breakpoint: 1400,
			settings: {
				slidesToShow: 4
			}
		},
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 3
			}
		},
		{
			breakpoint: 1000,
			settings: {
				slidesToShow: 2
			}
		},
		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1
			}
		}
	]
};

const sliderSettingsAllVideo = {
	slidesToShow: 6,
	slidesToScroll: 1,
	infinite: false,
	arrows: false,
	rows: 10,
	slidesPerRow: 6,
	centerMode: true,
	centerPadding: '50px'
};
