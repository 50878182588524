import { combineReducers } from 'redux';
import login from 'features/auth/store/auth.reducer';
import { IState, IAction } from 'shared/interface';

import * as actionTypes from 'store/action-types';

const appReducer = combineReducers({
	auth: login
});

const rootReducer = (state: IState | undefined, action: IAction): IState => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS) {
		localStorage.removeItem('authData');
		localStorage.removeItem('userData');
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
