export interface IPerformanceGraph {
	timestamp: number;
	count: number;
}

export interface IDashboardStatsInfo {
	performanceGraph: IPerformanceGraph[];
	total: number;
	performancePer: number;
}

type IDashboardDetailsActionOptions =
	| 'all-user'
	| 'active-user'
	| 'average-time-spend'
	| 'video-watched'
	| 'all-votes'
	| 'link-visits';

export type IDashboardDetailsMapper = {
	[key in IDashboardDetailsActionOptions]: IDashboardStatsInfo;
};

const DetailsActions = {
	AllUser: 'all-user',
	ActiveUser: 'active-user',
	AverageTimeSpend: 'average-time-spend',
	VideoWatched: 'video-watched',
	AllVotes: 'all-votes',
	LinkVisits: 'link-visits'
};

const GraphTitle = {
	'all-user': 'All Users',
	'active-user': 'Active User',
	'average-time-spend': 'Average Time Spend',
	'video-watched': 'Videos watched',
	'all-votes': 'Votes',
	'link-visits': 'Link Visits'
};

const GraphPreTitle = {
	all: '',
	day: 'Daily',
	week: 'Weekly',
	month: 'Monthly',
	year: 'Yearly'
};

const dashboardTabs = [
	{ name: 'All', value: 'all' },
	{ name: 'Day', value: 'day' },
	{ name: 'Week', value: 'week' },
	{ name: 'Month', value: 'month' },
	{ name: 'Year', value: 'year' }
];

const DateLabelMapper = {
	day: ['hh', 'mm', 'ss'],
	all: [' ', 'MMM', 'YYYY'],
	week: ['Do', 'MMM', 'YYYY'],
	month: ['Do', 'MMM', 'YYYY'],
	year: [' ', 'MMM', 'YYYY']
};

const AvgTimeGraph = 'average-time-spend';

export { DetailsActions, GraphTitle, GraphPreTitle, dashboardTabs, DateLabelMapper, AvgTimeGraph };
