import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';

import { Loader } from 'shared/components/spinner/spinner';
import Button from 'shared/components/form/button';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import Pagination, { IPagination } from 'shared/components/pagination/pagination';
import Confirm from 'shared/components/confirm/confirm';
import { debounce } from 'shared/util/utility';

interface IProps {
	campaignSearch: string;
	isUpdateList: boolean;
	handleUpdateList: () => void;
	activeCampaignTab: string;
}

createTheme('solarized', {
	background: {
		default: 'transparent'
	},
	context: {
		background: '#cb4b16',
		text: '#FFFFFF'
	},
	divider: {
		default: '#cecece'
	},
	action: {
		button: 'rgba(0,0,0,.54)',
		disabled: 'rgba(0,0,0,.12)'
	}
});

const initPagination = {
	currentPage: 1,
	nextPage: null,
	recordPerPage: 10,
	remainingCount: 0,
	total: 0,
	totalPages: 1
};

const CampaignList = (props: IProps) => {
	const { campaignSearch, isUpdateList, handleUpdateList, activeCampaignTab } = props;

	const [campaignData, setCampaignData] = useState({ data: [], total: 0 });
	const [isDeleteCampaign, setIsDeleteCampaign] = useState<boolean>(false);
	const [battleCampaignData, setBattleCampaignData] = useState({ data: [], total: 0 });
	const [isGettingCampaign, setIsGettingCampaign] = useState<boolean>(false);
	const [pagination, setPagination] = useState<IPagination>(initPagination);
	const [isDeleteBattleCampaign, setIsDeleteBattleCampaign] = useState<boolean>(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [currentCampaignId, setCurrentCampaignId] = useState('');
	const [columnData, setColumnData] = useState([]);

	const { currentPage, recordPerPage } = pagination;

	const fetchCampaigns = useCallback(
		(currentPage = 1, recordPerPage = 10, search = '') => {
			const params = { page: currentPage, recordPerPage, search };
			setIsGettingCampaign(true);
			httpService
				.get(`${API_CONFIG.path.campaigns}`, params)
				.then((response) => {
					const { currentPage, recordPerPage, nextPage, remainingCount, total, totalPages } = response;
					setCampaignData(response);
					setIsGettingCampaign(false);
					handleUpdateList();
					setPagination({
						...pagination,
						currentPage,
						recordPerPage,
						nextPage,
						remainingCount,
						total,
						totalPages
					});
				})
				.catch((error) => {
					console.error('error', error);
					setIsGettingCampaign(false);
				});
		},
		[currentPage, recordPerPage]
	);

	const deleteSingleCampaigns = (id: string) => {
		setIsDeleteCampaign(true);
		httpService
			.deleteRequest(`${API_CONFIG.path.campaigns}/${id}`)
			.then(() => {
				setIsDeleteCampaign(false);
				setIsConfirmOpen(false);
				const data = [...campaignData.data];
				data.forEach((info, index) => {
					if (info.id === id) {
						data.splice(index, 1);
						fetchCampaigns();
					}
				});
				if (data.length === 0 && currentPage > 1) {
					handlePagination(currentPage - 1, recordPerPage);
				}
				setPagination({ ...pagination, total: data.length });
				setCampaignData({ data: data, total: data.length });
			})
			.catch((error) => {
				console.error('error', error);
				setIsDeleteCampaign(false);
				setIsConfirmOpen(false);
			});
	};

	const fetchBattleCampaigns = useCallback(
		(currentPage = 1, recordPerPage = 10, search = '') => {
			const params = { page: currentPage, recordPerPage, search };
			setIsGettingCampaign(true);
			httpService
				.get(`${API_CONFIG.path.battleAdvertisement}`, params)
				.then((response) => {
					const { currentPage, recordPerPage, nextPage, remainingCount, total, totalPages } = response;
					setBattleCampaignData(response);
					setIsGettingCampaign(false);
					handleUpdateList();
					setPagination({
						...pagination,
						currentPage,
						recordPerPage,
						nextPage,
						remainingCount,
						total,
						totalPages
					});
				})
				.catch((error) => {
					console.error('error', error);
					setIsGettingCampaign(false);
				});
		},
		[currentPage, recordPerPage]
	);

	const deleteBattleCampaigns = (id: string) => {
		setIsDeleteBattleCampaign(true);
		httpService
			.deleteRequest(`${API_CONFIG.path.battleAdvertisement}/${id}`)
			.then(() => {
				setIsDeleteBattleCampaign(false);
				setIsConfirmOpen(false);
				const data = [...battleCampaignData.data];
				data.forEach((info, index) => {
					if (info.id === id) {
						data.splice(index, 1);
						fetchBattleCampaigns();
					}
				});
				if (data.length === 0 && currentPage > 1) {
					handlePagination(currentPage - 1, recordPerPage);
				}

				setPagination({ ...pagination, total: data.length });
				setBattleCampaignData({ data: data, total: data.length });
			})
			.catch((error) => {
				console.error('error', error);
				setIsDeleteBattleCampaign(false);
				setIsConfirmOpen(false);
			});
	};

	const handleDeleteCampaign = (id: string) => {
		setCurrentCampaignId(id);
		setIsConfirmOpen(true);
	};

	const handleConfirmClose = () => {
		setIsConfirmOpen(false);
	};

	const handleConfirmDelete = () => {
		if (activeCampaignTab == 'single') {
			deleteSingleCampaigns(currentCampaignId);
		} else {
			deleteBattleCampaigns(currentCampaignId);
		}
	};

	const handlePagination = (page: number, perPageVal: number) => {
		const newPagination = {
			...pagination,
			currentPage: page,
			recordPerPage: perPageVal
		};
		if (activeCampaignTab === 'single') {
			fetchCampaigns(page, perPageVal);
		} else {
			fetchBattleCampaigns(page, perPageVal);
		}
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 100);
		setPagination({ ...newPagination });
	};

	useEffect(() => {
		activeCampaignTab === 'single'
			? fetchCampaigns(1, undefined, campaignSearch)
			: fetchBattleCampaigns(1, undefined, campaignSearch);
	}, [activeCampaignTab]);

	useEffect(() => {
		if (activeCampaignTab === 'single') {
			isUpdateList && fetchCampaigns();
		} else {
			isUpdateList && fetchBattleCampaigns();
		}
	}, [isUpdateList]);

	useEffect(() => {
		handleCampaignSearch(campaignSearch);
	}, [campaignSearch]);

	const handleCampaignSearch = useMemo(
		() =>
			debounce((value: string) => {
				activeCampaignTab === 'single'
					? fetchCampaigns(1, undefined, value.trim())
					: fetchBattleCampaigns(1, undefined, value.trim());
			}),
		[activeCampaignTab, fetchCampaigns, fetchBattleCampaigns]
	);

	const renderColumns = () => {
		let column = [];
		if (activeCampaignTab === 'single' && campaignData.data.length > 0) {
			column = [
				{
					name: 'Id',
					center: true,
					selector: (_row: number, index: number) =>
						index > 8 ? `${currentPage}0` : `${currentPage - 1}${index + 1}`
				},
				{
					name: 'Title',
					center: true,
					selector: (row: { title: string }) => row.title
				},
				{
					name: 'Website Url',
					center: true,
					selector: (row: { websiteUrl: string }) => {
						return row.websiteUrl ? (
							<a href={row.websiteUrl} target='_blank' className='website-url'>
								{row.websiteUrl}
							</a>
						) : (
							'--'
						);
					}
				},
				{
					name: 'Video',
					center: true,
					selector: (row: { videoUrl: string }) => (
						<div className='text-align-center '>
							{row.videoUrl ? (
								<video controls>
									<source src={row.videoUrl} type='video/mp4' />
								</video>
							) : (
								'--'
							)}
						</div>
					)
				},
				{
					name: 'Image',
					center: true,
					selector: (row: { thumbnailUrl: string }) => {
						return row.thumbnailUrl ? (
							<img width='150' height='150' className='battle-image' src={row.thumbnailUrl} alt='Image' />
						) : (
							'--'
						);
					}
				},
				{
					name: 'Icon',
					center: true,
					selector: (row: { iconUrl: string }) => {
						return row.iconUrl ? (
							<img
								width='150'
								height='150'
								className='advertisement-icon'
								src={row.iconUrl}
								alt='Image'
							/>
						) : (
							'--'
						);
					}
				},
				{
					name: 'Action',
					center: true,
					selector: (row: { id: string }) => (
						<Button
							btnType='danger'
							addBtnType='remove'
							onClick={(e) => {
								e.stopPropagation();
								handleDeleteCampaign(row.id);
							}}
						/>
					)
				}
			];
		} else if (activeCampaignTab === 'battle' && battleCampaignData.data.length > 0) {
			column = [
				{
					name: 'Id',
					center: true,
					selector: (_row: number, index: number) =>
						index > 8 ? `${currentPage}0` : `${currentPage - 1}${index + 1}`
				},
				{
					name: 'Title',
					center: true,
					selector: (row: { title: string }) => row.title
				},
				{
					name: 'Video 1',
					center: true,
					selector: (row: { advertisement1: { videoUrl: string } }) => {
						return (
							<div className='text-align-center '>
								{row.advertisement1 && row.advertisement1.videoUrl ? (
									<video controls>
										<source src={row.advertisement1.videoUrl} type='video/mp4' />
									</video>
								) : (
									'--'
								)}
							</div>
						);
					}
				},
				{
					name: 'Video 2 ',
					center: true,
					selector: (row: { advertisement2: { videoUrl: string } }) => (
						<div className='text-align-center '>
							{row.advertisement2 && row.advertisement2.videoUrl ? (
								<video controls>
									<source src={row.advertisement2.videoUrl} type='video/mp4' />
								</video>
							) : (
								'--'
							)}
						</div>
					)
				},
				{
					name: 'Action',
					center: true,
					selector: (row: { id: string }) => (
						<Button
							btnType='danger'
							addBtnType='remove'
							onClick={(e) => {
								e.stopPropagation();
								handleDeleteCampaign(row.id);
							}}
						/>
					)
				}
			];
		}
		return column;
	};

	useEffect(() => {
		if (campaignData.data.length > 0 || battleCampaignData.data.length > 0) {
			const columns = renderColumns();
			setColumnData(columns);
		}
	}, [campaignData, activeCampaignTab, battleCampaignData]);

	return (
		<div className='App'>
			{
				<DataTable
					className='advertisement-table-wrapper'
					columns={columnData}
					data={activeCampaignTab === 'single' ? campaignData.data : battleCampaignData.data}
					highlightOnHover
					progressPending={isGettingCampaign}
					progressComponent={<Loader className='campaign-data-loading' />}
					theme='solarized'
					noDataComponent={
						<div className='user-profile-icon'>
							<p className='user-profile-text'>No Campaign Found</p>
						</div>
					}
				/>
			}
			{pagination.total > 0 && !isGettingCampaign && (
				<Pagination
					handlePageClick={(page: number) => handlePagination(page, recordPerPage)}
					handlePageSelection={(perPage: number) => handlePagination(currentPage, perPage)}
					loading={isGettingCampaign}
					pagination={pagination}
				/>
			)}
			{isConfirmOpen && (
				<Confirm
					loading={activeCampaignTab === 'single' ? isDeleteCampaign : isDeleteBattleCampaign}
					show={true}
					message={`Are you sure want to delete this ${
						activeCampaignTab == 'single' ? 'Campaign' : 'Battle'
					}?`}
					handleConfirm={handleConfirmDelete}
					handleClose={handleConfirmClose}
					cancelBtnText='No'
					submitBtnText='Yes'
					className='viral-confirm-modal'
				/>
			)}
		</div>
	);
};

export default CampaignList;
