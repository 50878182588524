/* tslint:disable */
const en = {
	dashboardTitle: 'dashboard',
	autoGeneratedLicenseGraphTitle: 'Auto Generated License',
	upcomingCreatedLicenseGraphTitle: 'Total Created License',
	daily: 'daily',
	weekly: 'weekly',
	monthly: 'monthly',
	today: 'Today',
	yesterday: 'Yesterday',
	thisWeek: 'This Week',
	lastWeek: 'Last Week',
	thisMonth: 'This Month',
	lastMonth: 'Last Month',
	thisYear: 'This Year',
	lastYear: 'Last Year',
	custom: 'Custom',
	backBtnText: 'Back',
	organizationsTitle: 'organizations',
	superAdmin: 'super admin',
	license: 'license',
	support: 'support',
	licenses: 'licenses',
	incomingLicensesTitle: 'incoming licenses',
	machineListTitle: 'machine list',
	excludeDebAutoId: 'Exclude DEB-Auto Ids',
	enterDebAutoIds: 'Enter DEB-Auto Ids',
	excludeCustomerId: 'Exclude Customer Ids',
	enterCustomerIds: 'Enter Customer Ids',
	excludedIdsList: "Excluded ID's List",
	excludeFilterPlaceholder: 'XXXXX ⏎',
	excludeFilterNote: 'Press Enter(⏎) key or Space bar to create tag.',
	excludeFilterErrorMessage: 'Entered ID is already excluded, please enter another ID.',
	addedRemovedTagMessage:
		'You entered an id which you just marked to be removed, so now that id will not going to remove.',
	undoRemove: 'Undo Remove Operation',
	remove: 'Remove',
	excludedId: 'Excluded ID',
	toBeRemoveId: 'To be removed ID',
	goingToExcludeId: 'Going to exclude ID',
	excludeFilter: 'Exclude Filter',
	autoUpdateMachineTitle: 'Auto Update Machine',
	licenseNumberView: 'license number view',
	orderCodeExtensions: 'Order Code Extensions',
	orderCodeExtensionsList: 'Order Code Extensions List',
	configurations: 'Configurations',
	configurationsList: 'Configurations List',
	editConfigurationValue: 'Edit configuration value',
	order_code_id: 'id',
	order_code_used: 'is used?',
	order_code_used_at: 'used at',
	users: 'users',
	user: 'User',
	list: 'list',
	create: 'create',
	yesCreateAndSendMail: 'YES, CREATE AND SEND EMAIL',
	createAndSendMail: 'create and send email',
	yesCreate: 'YES, CREATE',
	yesReCreate: 'YES, RECREATE',
	rolesAndPermissions: 'roles & permissions',
	roles: 'roles',
	role: 'Role',
	status: 'Status',
	permissions: 'permissions',
	addUser: 'add user',
	supportList: 'support list',
	licenseList: 'license list',
	superAdminList: 'super admin list',
	logout: 'Log out',
	home: 'Home',
	view: 'view',
	createLicense: 'create license',
	delete: 'Delete',
	restore: 'Restore',
	edit: 'Edit',
	save: 'Save',
	all: 'All',
	select: 'Select',
	customer: 'Customer',
	Customer: 'Customer',
	customers: 'Customers',
	customerList: 'Customer List',
	partner: 'Partner',
	leads: 'Leads',
	search: 'search',
	licenseNumbers: 'license numbers',
	licenseInformation: 'License Information',
	historyLogOfMachineFor: 'History Log Of Machine For',
	historyLogOfLicenseFor: 'History Log Of License For',
	unassigned: 'Unassigned',
	organizationDetails: 'organization details',
	profile: 'profile',
	editUser: 'edit user',
	notFoundMsg:
		'Sorry, but the page you are looking for has note been found. Try checking the URL for error, then hit the refresh button on your browser or try found something else in our app.',
	pageNotFound: 'Page Not Found',
	actions: 'Actions',
	orgTimeline: 'Organization Timeline',
	noDataFoundText: 'No data found',
	serialNumber: 'No.',
	name: 'Name',
	code: 'Code',
	taxRate: 'Tax rate',
	taxSet: 'Tax set',
	taxRateName: 'Tax Rate Name',
	country: 'Country',
	update: 'Update',
	email: 'Email',
	recipientEmail: 'recipient email',
	senderEmail: 'sender email',
	password: 'Password',
	phoenixPassword: 'Please set your Password',
	phoenixPasswordSuccessfullyReset: 'Password successfully reset!',
	oldPassword: 'Old Password',
	newPassword: 'New Password',
	confirmPassword: 'Confirm Password',
	passwordConfirmation: 'Password Confirmation',
	createdAt: 'Created At',
	updatedAt: 'Updated At',
	functions: 'Functions',
	hardwareKey: 'Hardware Key',
	noOfRecords: 'Number Of Machines',
	noOfRecordsMinimumValidationMessage: 'The value must be greater than 0 (Zero).',
	hw_id: 'Hardware Id',
	licenseNumber: 'License Number',
	expiryDateType: 'Expiry Date Type',
	week: 'Week',
	weeks: 'Weeks',
	year: 'Year',
	month: 'Month',
	months: 'Months',
	typeValue: 'Type Value',
	value: 'Value',
	nextButton: 'Next',
	expiryDate: 'Expiry Date',
	autoLicense: 'Auto License',
	version: 'Version',
	timestampInsert: 'Inserted At',
	timestampUpdate: 'Updated At',
	activeLicense: 'Active License',
	nextLicense: 'Next License',
	address: 'Address',
	postal_code: 'Postal Code',
	update_able: 'updateable',
	version_2: 'version 2',
	sql_server: 'sql server',
	beauty: 'Beauty',
	boutique: 'Boutique',
	friseur: 'Friseur',
	direktverkauf: 'Direktverkauf',
	baeckerei: 'Baeckerei',
	kiosk: 'Kiosk',
	hospitality: 'Hospitality',
	retail: 'Retail',
	fun_version: 'fun version',
	change_of_days: 'change of days',
	pb_p_version: 'PB P version',
	db_version: 'DB version',
	is_locked: 'Is Locked',
	hashcode: 'hashcode',
	auto_license: 'auto license',
	auto_update: 'auto update',
	windows_version: 'window version',
	mpos: 'mpos',
	wawi: 'WaWi',
	order_code: 'order code',
	configuration_name: 'Configuration Name',
	configuration_value: 'Configuration Value',
	timestamp_insert: 'Timestamp Insert',
	timestamp_update: 'Timestamp Update',
	partner_id: 'Partner Id',
	swn_name: 'SWN name',
	swn_surname: 'SWN surname',
	sepa_name: 'SEPA name',
	sepa_surname: 'SEPA surname',
	bank_account_name: 'Bank account name',
	bank_name: 'Bank Name',
	bank_iban: 'Bank IBAN',
	iban: 'IBAN',
	amount: 'Amount',
	software: 'Software',
	bank_bic: 'Bank BIC',
	autoUpdate: 'Auto Update',
	autoUpdateOn: 'Turn Auto Update On',
	autoUpdateOff: 'Turn Auto Update Off',
	validFrom: 'Valid From',
	validTo: 'Valid To',
	notUploaded: 'Not Uploaded',
	newLicense: 'New License',
	manageModules: 'Manage Modules',
	viewModules: 'View Modules',
	lock: 'Lock',
	locked: 'Locked',
	unlock: 'Unlock',
	unlocked: 'Unlocked',
	licenseLock: 'License lock',
	licenseUnlock: 'License unlock',
	machineLock: 'Machine lock',
	machineUnlock: 'Machine unlock',
	licenseLocked: 'License number locked',
	licenseUnlocked: 'License number unlocked',
	machineLocked: 'Machine locked',
	machineUnlocked: 'Machine unlocked',
	machineAutoLicenseEnabled: 'Machine Auto License Enabled',
	machineAutoLicenseDisabled: 'Machine Auto License Disabled',
	sendLicenseEmail: 'Send license email',
	generate: 'Generate',
	copyEmailAndPasswordToClipboard: 'copy email and password to clipboard',
	submit: 'submit',
	submitWithEmail: 'submit with email',
	cancel: 'cancel',
	yes: 'YES',
	no: 'NO',
	on: 'On',
	off: 'Off',
	archived: 'archived',
	archive: 'Archive',
	active: 'active',
	Active: 'Active',
	addNewUser: 'Add New User',
	createTaxRule: 'Create New Tax Rule',
	id: 'Id',
	module: 'Module',
	close: 'Close',
	noUsersAvailable: 'no users available',
	slug: 'Slug',
	language: 'Language',
	german: 'German',
	english: 'English',
	noPermissionsAvailable: 'no permissions available',
	changePassword: 'Change Password',
	updateProfileTitle: 'Update Your Profile',
	changePasswordTitle: 'Change Your Password',
	editUserDetails: 'Edit User Details',
	newRegisteredUser: 'New Register User',
	inActive: 'InActive',
	blocked: 'Blocked',
	incomingLicenseList: 'Incoming License List',
	licenseNumbersList: 'License Numbers List',
	forgetPassword: 'Forgot Password',
	getPasswordResetCode: 'Send email',
	resetInstructionsAreSentToYourEmail:
		'We have sent a message to the email address you provided. There you will find a link to reset the password.',
	backToLogin: 'Back to Login',
	noMachinesFound: 'no machines found',
	resetYourPassword: 'Reset Your Password',
	resetPassword: 'Reset Password',
	generatePassword: 'Generate Password',
	login: 'Login',
	hello: 'Hello',
	licenseDetailsAreHere: 'Your License details are here',
	thankYou: 'Thank You',
	licenseReport: 'License Report',
	standard: 'Standard',
	premium: 'Premium',
	demo: 'Demo',
	fun: 'Fun',
	default: 'Default',
	sql: 'SQL',
	addItem: 'Add Item',
	item: 'Item',
	inventoryGroupName: 'Inventory Group Name',
	OK: 'OK',
	addLicense: 'Add License',
	addHardware: 'Add Hardware',
	add: 'Add',
	addWithEmail: 'add with email',
	send: 'send',
	openEmail: 'open email',
	addNewRole: 'Add New Role',
	editRole: 'Edit Role',
	customerData: 'Customer Data',
	name1: 'Name 1',
	name2: 'Name 2',
	street: 'Street',
	zipCode: 'Zip Code',
	city: 'City',
	nation: 'Nation',
	phone: 'Phone',
	fax: 'Fax',
	ust: 'Ust',
	expiryDateValidate: 'Please select expiry date',
	activateAutoLicense: 'Activate Auto License',
	to: 'To',
	cc: 'CC',
	subject: 'Subject',
	content: 'Content',
	requiredFieldValidation: '{{field}} is required!',
	fieldSelectionValidation: 'please select {{field}}',
	exactLengthValidation: '{{field}} must be having exact {{length}} characters!',
	minLengthStrValidation: '{{field}} must be longer than {{length}} characters!',
	minLengthArrValidation: '{{field}} must contain minimum {{length}} items!',
	maxLengthStrValidation: '{{field}} must be shorter than {{length}} characters!',
	maxLengthArrValidation: '{{field}} must contain maximum {{length}} items!',
	minValueValidation: '{{field}} must be greater than or equal to {{value}}!',
	maxValueValidation: '{{field}} must be less than or equal to {{value}}!',
	typeValidation: '{{field}} must be a valid {{type}}!',
	hardwareKeyValidation: 'hardware key must contain digits only (must not start with 0)',
	passwordValidation:
		'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter and one number and one special character and must not contain white space',
	passwordMatchValidation: 'Passwords do not match',
	amountValidation: '{{field}} must be a valid value!',
	confirmRestoreMsg: 'Are you sure you want to restore {{field}}?',
	confirmDeleteMsg: 'Are you sure you want to remove {{field}}?',
	confirmLogoutMsg: 'Are you sure you want to logout?',
	confirmAutoLicense: 'Are you sure you want to update auto-license state of given license?',
	confirmChangeLicenseVersion: 'Are you sure you want to change license version?',
	confirmChangeLockStatus: 'Are you sure you want to change license lock status?',
	confirmChangeMachineLockStatus: 'Are you sure you want to change machine lock status?',
	confirmMachineAutoLicense: 'Are you sure you want to change machine auto-license status?',
	noLicensesFound: 'no licenses found',
	noOrderCodeExtensionFound: 'no order code extension found',
	noApiKeyFound: 'no api key found',
	noTaxRuleFound: 'no tax rule found',
	noExpiredLicensesFound: 'no expired licenses found',
	addHardwareNotAllowed: 'You can not add hardware for this organization, as the customer ID is not valid',
	customerIdRequirements: '(Customer ID field must be a number, and must be between 1 and 99999)',
	givenCustomerIdIsNotValid: 'Customer ID - {{customerId}} is not valid',
	relateMachineToCustomer: 'Relate Machine To Customer',
	relateCompanyToCustomer: 'Relate Company To Customer',
	newCustomer: 'New Customer',
	customerId: 'Customer Id',
	customerIds: 'Customer Ids',
	customer_id: 'Customer Id',
	copied: 'Copied',
	copyText: 'Copy Text',
	difference: 'Difference',
	relate: 'Relate',
	relateAndJumpToCustomer: 'Relate and jump to Customer',
	details: 'Details',
	totalRecords: 'Total Records',
	apply: 'Apply',
	canNotCreateCustomerMsg: 'You can not create a new customer.',
	next: 'Next',
	previous: 'Previous',
	perPage: 'Per Page',
	paid: 'Paid',
	unpaid: 'Unpaid',
	manual: 'Manual',
	invalid: 'Invalid',

	internal: 'internal',
	global: 'global',
	local: 'local',

	birdhubAdmin: 'birdhubAdmin',
	birdhubAdminTitle: 'Birdhub Admin',
	credentials: 'Credentials',
	credentialList: 'Credential List',
	addNewCredential: 'Add New Credential',
	addCredential: 'Add Credential',
	updateCredential: 'Update Credential',
	credentialInfo: 'Credential Info',
	orderDetail: 'Order Details',
	ordersList: 'Orders List',
	orderItems: 'Order Items',
	order_id: 'Order Id',
	customer_title: 'Customer',
	order_date: 'Order Date',
	shop_code: 'Shop Code',
	total_discount: 'Total Discount',
	total_gross: 'Total Gross',
	total_net: 'Total Net',
	total_shipping_cost: 'Total Shipping Cost',
	total_vat: 'Total Vat',
	item_id: 'Id',
	item_text: 'Item',
	single_price: 'Single Price',
	total_price: 'Total Price',
	new: 'New',
	dispatched: 'Dispatched',
	delivered: 'Delivered',
	refused: 'Refused',
	cancelled: 'Cancelled',
	clients: 'Clients',

	apiId: 'Api Id',
	apiSecretKey: 'Api Secret Key',
	type: 'Type',
	note: 'Note',
	notes: 'Notes',

	companyList: 'Company List',
	companyDetail: 'Company Detail',
	companyEdit: 'Company Edit',
	companyId: 'Company Id',
	markAsCreated: 'Mark As Created',
	markAsComplete: 'Mark As Complete',
	transactionId: 'Transaction Id',
	phoenixIncomingLicenseList: 'Phoenix Incoming License List',
	phoenixIncomingLicenseTitle: 'Phoenix Incoming Licenses',
	isEmailVerified: 'Is Email Verified',
	emailVerifiedAt: 'Email Verified At',
	verified: 'verified',
	priceInInventoryGroup: 'Price in inventory Group',
	priceNotInInventoryGroup: 'Price not in inventory Group',
	pending: 'Pending',
	phoenix: 'Phoenix',
	accepted: 'Accepted',
	rejected: 'Rejected',
	completed: 'Completed',
	failed: 'Failed',
	expired: 'Expired',
	added: 'Added',
	notAdded: 'Not Added',
	subscriptions: 'Subscriptions',
	subscriptionId: 'Subscription Id',
	additionalUsers: 'Additional Users',
	additionalFeatures: 'Additional Features',
	bankInfo: 'Bank Info',
	universeId: 'Universe Id',
	companyName: 'Company Name',
	isVerified: 'isVerified',
	unlimited: 'Unlimited',
	setUnlimited: 'Set Unlimited',
	manageSubscription: 'Manage Subscription',
	resetMasterPassword: 'Reset Master Password',
	manageExpiryDate: 'Manage Expiry Date',
	addTaxRule: 'Add Tax Rule',
	editTaxRule: 'Edit Tax Rule',
	manageUser: 'Manage User',
	companyLocked: 'Company Locked',
	companyUnlocked: 'Company Unlocked',
	confirmChangeCompanyLockStatus: 'Are you sure you want to change company lock status?',
	confirmCancelSubscription: 'Are you sure you want to cancel subscription?',
	confirmSetPhoenixLicenseDateUnlimited:
		'Are you sure you want to set license expiry date to unlimited (01-01-1970)?',
	confirmChangeActiveLicense: 'Are you sure you want set this license as active?',
	confirmDeleteLicense: 'Are you sure you want to delete this license?',
	confirmGetValidLicense: 'Are you sure you want to get valid licenses of this hardware?',
	confirmResetPassword: 'Are you sure you want to reset password?',
	confirmGeneratePassword: 'Are you sure you want to generate password?',
	companySubscriptionEmptyMessage:
		'There is no Subscription, please select Subscription before selecting the Modules.',
	getValidLicense: 'get valid license',
	confirmChangeUsedOrderCodeStatus: 'Are you sure you want change the status of the order code?',

	customerName: 'Customer Name',
	shopCode: 'Shop Code',
	totalDiscount: 'Total Discount',
	totalGross: 'Total Gross',
	totalNet: 'Total Net',
	totalShippingCost: 'Total Shipping Cost',
	totalVat: 'Total Vat',

	modules: 'Modules',
	moduleList: 'Module List',
	addNewModule: 'Add New Module',
	addModule: 'Add Module',
	updateModule: 'Update Module',
	price: 'Price',

	package: 'Package',
	packages: 'Packages',
	packageList: 'Package List',
	updatePackage: 'Update Package',
	phoenixId: 'Phoenix Id',
	description: 'Description',

	apiKeyList: 'API Key List',
	defaultUserCount: 'Default Users',
	currentDefaultUsers: 'Current Default Users',
	currentTotalUsers: 'Current Total User',
	newDefaultUsers: 'New Default Users',
	newTotalUsers: 'New Total User',

	jumpbirdIncomingLicenseList: 'Jumpbird Incoming License List',
	jumpbirdIncomingLicenseTitle: 'Jumpbird Incoming License',
	agb_gdpdr: 'AGB & Datenschutz',
	sepa_accepted_at: 'Is SEPA Accepted?',
	licenseInfo: 'License Info',
	packageInfo: 'Package Info',
	companies: 'Companies',
	company_name: 'Company Name',
	is_advertising: 'Is Advertising?',
	contact_person_first_name: 'Contact Person First Name',
	contact_person_last_name: 'Contact Person Last Name',
	registration_date: 'Registration Date',
	updated_at: 'Updated At',
	onboard_from: 'OnBoard From',
	terms_conditions_url: 'Terms Conditions URL',
	package_code: 'Package Code',
	start_date: 'Start Date',
	end_date: 'End Date',
	subscription_status: 'Subscription Status',
	bank_account_owner_name: 'Bank Account Owner Name',
	tax_id: 'Tax Id',
	// "iban": "IBAN",
	bic: 'BIC',
	website: 'Website',
	block_reason: 'Block Reason',
	paymentMethod: 'Payment Method',
	manageSepa: 'Manage SEPA',
	accept: 'Accept',
	reject: 'Reject',
	reason: 'Reason',
	rejectReasonLabel: 'Write a reason for rejection.',
	blockReasonLabel: 'Write a reason for block the company.',
	cancelSubscriptionReasonLabel: 'Write a reason for cancelling subscription.',
	companyBlocked: 'Company Blocked',
	companyUnblocked: 'Company Unblocked',
	searchByCompanyNameOrEmail: 'Search by company name or e-mail',
	cancelSubscription: 'Cancel Subscription',
	nex_billing_date: 'Next billing date',
	assignPackage: 'Assign Subscription',
	bankAccountOwnerName: 'Bank Account Owner Name',
	billingCycles: 'Billing Cycles',

	jumpbirdAdmin: 'jumpbirdAdmin',
	jumpbirdAdminTitle: 'Jumpbird Admin',

	tse: 'TSE',
	tseSerialUpload: 'TSE Serial Upload',
	tseDevice: 'TSE Device',
	tseDeviceDetail: 'TSE Device Detail',
	tseDeviceList: 'TSE Device List',
	importCSV: 'Import CSV',
	deviceFile: 'Device File',
	file: 'File',
	upload: 'Upload',
	supportedType: 'Supported Type',
	downloadSampleFile: 'Download Sample File',
	activate: 'Activate',
	confirmDeviceActivateMsg: 'Are you sure you want to activate machine?',
	date: 'Date',
	deviceDetail: 'Device Detail',
	activityLogs: 'Activity Logs',
	adminPin: 'Admin Pin',
	admin_pin: 'Admin Pin',
	adminPuk: 'Admin Puk',
	admin_puk: 'Admin Puk',
	timeAdminPin: 'Time Admin Pin',
	time_admin_pin: 'Time Admin Pin',
	timeAdminPuk: 'Time Admin Puk',
	time_admin_puk: 'Time Admin Puk',
	app: 'App',
	publicKey: 'Public Key',
	public_key: 'Public Key',
	secretKey: 'Secret Key',
	secret_key: 'Secret Key',
	activated_on: 'Activated On',
	activatedOn: 'Activated On',
	expiredAt: 'Expired At',
	expired_at: 'Expired At',
	fieldName: 'Field Name',
	changedValue: 'Changed Value',
	originalValue: 'Original Value',
	uploadCount: 'Upload Count',

	tseDashboardTitle: 'TSE Dashboard',
	tseBackupUpload: 'TSE Backup Upload',
	universeCustomers: 'Universe Customers',
	universeCustomer: 'Universe Customer',
	universeCustomerList: 'Universe Customer List',
	tseCustomers: 'TSE Customers',
	tseCustomer: 'TSE Customer',
	tseCustomerList: 'TSE Customer List',
	tseCustomerDetail: 'TSE Customer Detail',
	backupStatistics: 'Backup Statistics',
	portalAccount: 'Portal Account',
	backupCount: 'Backup Count',
	inProgressUpload: 'In Progress Upload',
	successfulUpload: 'Successful Upload',
	failedUpload: 'Failed Upload',
	firstUploadAt: 'First Upload Date',
	lastUploadAt: 'Last Upload Date',
	backupList: 'Backup List',

	downloadRequests: 'Download Requests',
	downloadRequestList: 'Download Request List',

	debAutoId: 'DEB-Auto',
	suggested: 'Suggested',
	fileName: 'File Name',
	fileSize: 'File Size',
	serial_number: 'Serial Number',
	checksum: 'Checksum',
	workingDate: 'Working Date',
	expiringDate: 'Expiring Date',
	progressing: 'Progressing',
	inProgress: 'In Progress',
	uploaded: 'Uploaded',
	downloaded: 'Downloaded',
	createDownloadRequest: 'Create Download Request',
	activeBackupData: 'Active Backup Data',
	backupInProgressText: 'Your backup is in progress ...',
	backupReadyText: 'Your backup is ready',
	backupFiles: 'Backup files',
	generateDownloadLink: 'Generate Download Link',
	generatedLinkText: 'Backup link is generated click on download button',
	downloadBackup: 'Download Backup',
	confirmCreateDownloadRequest:
		'If you request for new backup, then old downloaded backup will be removed. Are you sure want to proceed?',
	addNewCustomer: 'Add New Customer',
	sendMailText: 'Send verification email to customer?',
	sendMailNoteText: 'If no, Customer account will auto verified.',

	tools: 'Tools',
	zmList: 'ZM List',
	csvUpload: 'ZMList Input File Upload',
	selectFile: 'Select File',
	inputCloudPath: 'Input Cloud Path',
	processing: 'Processing',
	processed: 'Processed',
	requestDownload: 'Request Download',
	refresh: 'Refresh',
	download: 'Download',
	please_select_csv_file: 'Please select CSV file.',
	please_select_valid_csv_file: 'Please select valid CSV file.',

	emailVerification: 'Email verification',
	verifyingEmail: 'Verifying email...',
	successfulEmailVerified: 'Email verified successfully!!',
	companyCreation: 'Company creation',
	creatingCompany: 'Creating company...',
	startPhoenixNow: 'Start Phoenix now',
	redirectToPhoenix: 'Go To Phoenix Portal',
	companyAlreadyValidatedMessage: 'Your company is already set up! Click on the link to log in',
	companyVerificationSuccessMessage: 'Your registration was successfull!',
	companyVerificationFailMessage: 'Sorry. Your company could not be verified!',
	companyVerificationTimeoutMessage:
		'The creation of your company takes a little longer unexpectedly, but you can log in at the following link',

	fetchDataText: 'Apply filter to fetch data',
	confirmChangeAutoUpdateMachineStatus: 'Are you sure you want to auto update machine?',
	filter: 'Filter',
	selectFilterOptionText: 'Select filter option',
	filterSelectedText: 'filter selected',
	noFilterSelected: 'No Filter Selected',
	pbpVersion: 'PB P Version',
	dbVersion: 'DB Version',

	enable: 'Enable',
	disable: 'Disable',
	enabled: 'Enabled',
	disabled: 'Disabled',

	gep: 'GEP',
	bez: 'BEZ',
	changings: 'Changings',
	phoenixGEP: 'Phoenix GEP',
	phoenixBEZ: 'Phoenix BEZ',
	blockAllLicenses: 'Block All Licenses',
	unblockAllLicenses: 'Unblock All Licenses',
	blockAllCompany: 'Block All Company',
	unblockAllCompany: 'Unblock All Company',
	key: 'Key',
	oldValue: 'Old value',
	newValue: 'New value',
	same: 'same',
	changed: 'changed',
	confirmFieldSubmitMsg: 'Are you sure you want to submit form field data?',
	confirmDeleteTaxRule: 'Are you sure you want to delete tax rule?',
	sendEmail: 'Send Email',
	reset: 'Reset',
	doNothing: 'Do Nothing',

	no_label: 'No Label',
	non_customer: 'Non Customer',
	non_partner: 'Non Partner',
	vip: 'VIP',
	non_vip: 'Non VIP',

	none: 'None',
	showMore: 'Show More',
	showLess: 'Show Less',

	provision: 'Provision',
	accounting: 'Accounting',
	invoices: 'Invoices',
	invoice: 'Invoice',
	inventories: 'Inventories',
	inventoryGroup: 'Inventory Group',
	invoiceGroupList: 'Invoice Group List',
	inventoryGroupList: 'Inventory Group List',
	addInventoryGroup: 'Add Inventory Group',
	removeInvoice: 'Remove invoice',
	downloadInvoicePDF: 'Download Invoice PDF',
	taxRules: 'Tax Rules',
	documents: 'Documents',
	documentList: 'Document List',
	sevDeskNotLinked: 'SevDesk is not linked with this partner yet.',
	sevDeskNotInitiated: 'SevDesk is not initiated yet.',
	sevDeskTaxRules: 'SevDesk Tax Rules',
	createNote: 'Create Note',
	createInvoice: 'Create Invoice',
	generateInvoicePDF: 'Generate Invoice PDF',
	createInventoriyGroup: 'Create Inventory Group',
	updateInventoriyGroup: 'Update Inventory Group',
	invoiceWithoutTax: 'Invoice Without Tax',
	reCreateNote: 'Re-create Note',
	creditNote: 'Credit Note',
	periodOfService: 'Period Of Service',
	serviceDuration: 'Service Duration',
	bankRetrievalDate: 'Bank Retrieval Date',
	columnMapping: 'CSV Column Mapping',
	mapped: 'Mapped',
	expand: 'Expand',
	expandAll: 'Expand All',
	collapse: 'Collapse',
	collapseAll: 'Collapse All',
	noteAlreadyCreated: 'Note Already Created',
	invoiceAlreadyCreated: 'Invoice Already Created',
	reportList: 'Report List',
	generateReport: 'Generate Report',
	bulkCreateCreditNote: 'Bulk Create Credit Note',
	creatingCreditNotes: 'Creating Credit Notes',
	loading: 'Loading',
	quarter: 'Quarter',
	reportDetail: 'Report Detail',
	exportCSV: 'Export CSV',
	manageProvisionReportExport: 'Manage Provision Report Export',
	tradingDate: 'Trading Date',
	partnerList: 'Partner List',
	partnerId: 'Partner Id',
	contractBeginDate: 'Contract Begin Date',
	provisionAmount: 'Provision Amount',
	provisionW: 'Provision W',
	totalMonth: 'Total Month',
	totalCommission: 'Total Commission',
	sum: 'Sum',
	orderBy: 'Order By',
	orderByColumn: 'Order By Column',
	partner_deb_auto: 'Partner Id',
	partner_org_name: 'Partner Org Name',
	total_amount: 'Total Amount',
	currentTotal: 'Current Total',
	netAmount: 'Net Amount',
	invoiceAmount: 'Invoice Amount',
	remaining: 'Remaining',
	salesTax: 'Sales Tax',
	tax_amount: 'Tax Amount',
	inventoriesNotAvailable: 'Inventories are not available.',
	amountMatchedMessage: 'Total amount matched with expected amount',
	amountNotMatchedMessage: 'Total amount not matching with expected amount',
	asc: 'Ascending',
	desc: 'Descending',
	commissionStatus: 'Commission Status',
	commissionCalculationStatus: 'Commission Calculation Status',
	reportStatus: 'Report Status',
	confirmDeletePartnerCommission: 'Are you sure you want to remove partner commission?',
	confirmDeletePartnerCreditNote: 'Are you sure you want to remove the partner credit note?',
	confirmDeleteCustomerCommission: 'Are you sure you want to remove customer commission?',
	confirmCreateInvoiceMessage: 'Are you sure you want to create invoice?',
	confirmDeleteInvoiceMessage: 'Are you sure you want to remove invoice?',
	confirmArchiveInvoiceGroupMessage: 'Are you sure you want to archive this invoice group?',
	addCustomerCommission: 'Add Customer Commission',
	searchCustomer: 'Search Customer',
	product: 'Product',
	quantity: 'Quantity',
	autoCompleteSearchText: 'Type atleast 3 character to search...',
	noCustomerFound: 'No Customer Found',
	somethingWentWrong: 'Something went wrong. Please try again!!',

	not_allowed: ' not allowed',
	pwd_s_amount_lowercase: 'at least 1 lowercase letter',
	pwd_p_amount_lowercase: 'at least {{count}} lowercase letters',
	pwd_s_amount_uppercase: 'at least 1 uppercase letter',
	pwd_p_amount_uppercase: 'at least {{count}} uppercase letters',
	pwd_s_amount_numbers: 'at least 1 number',
	pwd_p_amount_numbers: 'at least {{count}} numbers',
	pwd_s_amount_special_characters: 'at least 1 special character',
	pwd_p_amount_special_characters: 'at least {{count}} special characters',
	pwd_s_string_length: '1 or more characters',
	pwd_p_string_length: '{{count}} or more characters',

	licenseModule: 'License Module',
	licenseModuleList: 'License Module List',
	modType: 'Mod Type',

	HardwareAdded: 'added new hardware',
	AssignedCustomer: 'has assigned a machine to a customer',
	MachineUpdated: 'has updated a machine',
	PaymentStatusUpdated: 'has updated a payment status',
	LicenseGenerated: 'License generated',
	LicenseActivated: 'License activated',
	LicenseInactivated: 'License inactivated',
	LicenseAdded: 'has added a license',
	LicenseUpdated: 'has updated a license',
	LicenseDeleted: 'has deleted a license',
	LicenseEmailSent: 'has sent license details via email',
	FutureLicenseActivated: 'has activated a future license',
	ModuleUpdated: 'has updated a module',
	HardwareCloned: 'Hardware has been cloned',
	LicenseCloned: 'License has been cloned'
};

export default en;
