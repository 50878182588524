import { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import 'react-datepicker/dist/react-datepicker.css';

import { formatFileName, uploadFileToS3 } from 'shared/util/utility';
import { IDropDownAndCheckboxOptions } from 'shared/components/form/inputTypes';
import CustomModal from 'shared/components/modal/modal';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { addChallengesValidationSchema, editChallengesValidationSchema } from 'shared/constants/validation-schema';

import { IMusic } from 'features/music/interface/music';
import ScrollToFieldError from '../components/scrollToFieldError';
import { IAllChallenges, IChallenge, IChallengeDetails, ModalTypes } from '../interface/challenges';
import ChallengeForm from './challengeModalField';

interface IProps {
	show: boolean;
	listOfCategory: IDropDownAndCheckboxOptions[];
	type: ModalTypes;
	currentChallengeId: string;
	challengeData: IAllChallenges;
	handleClose: () => void;
	fetchChallenges: () => void;
	setIsModalOpen: (value: boolean) => void;
	setChallengeData: (values: IAllChallenges) => void;
	setCurrentModalType: (value: ModalTypes) => void;
}

interface IChallengeInitVal {
	title: string;
	category: string;
	challengeRegStartAt: string | number;
	minAge: string;
	description: string;
	challengeRegEndAt: string | number;
	challengeVoteStartAt: string | number;
	challengeVoteEndAt: string | number;
	prize_description: string;
	previewUrl: string;
	prize_name: string;
	prizeImage: string;
	categoryId?: string;
	challengeType?: string;
	isPrivate?: boolean;
	partnerImage?: string;
	challengeIcon?: string;
	performerType?: string;
	imageUrl?: string;
	videoUrl?: string;
	videoPreviewUrl?: string;
	musicIds?: IMusic[];
}

export const challengeInitValues: IChallengeInitVal = {
	title: '',
	category: '',
	challengeRegStartAt: '',
	challengeRegEndAt: '',
	challengeVoteStartAt: '',
	challengeVoteEndAt: '',
	description: '',
	minAge: '',
	prize_description: '',
	prizeImage: '',
	previewUrl: '',
	prize_name: ''
};

interface IMediaDetails {
	file: File;
	setFieldValue: (name: string, value: string) => void;
	fileUploaderType: string;
}

const AddChallengeModal = (props: IProps) => {
	const {
		type,
		show,
		listOfCategory,
		setIsModalOpen,
		fetchChallenges,
		handleClose,
		currentChallengeId,
		setChallengeData,
		challengeData,
		setCurrentModalType
	} = props;

	const formikRef: any = useRef();

	const [active, setActive] = useState('single');
	const [previewUrl, setPreviewUrl] = useState('');
	const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
	const [videoUrl, setVideoUrl] = useState('');
	const [uploading, setUploading] = useState(false);
	const [prizeUploading, setPrizeUploading] = useState(false);
	const [challengeInitVal, setChallengeInitVal] = useState<IChallengeInitVal>(challengeInitValues);
	const [currentChallengeStatus, setCurrentChallengeStatus] = useState('');
	const [currentFileType, setCurrentFileType] = useState('');
	const [isEditChallengeModalLoader, setIsEditChallengeModalLoader] = useState<boolean>(false);
	const [isAddingChallengeLoader, setIsAddingChallengeLoader] = useState<boolean>(false);
	const [isGettingChallengeDetailsLoader, setIsGettingChallengeDetailsLoader] = useState<boolean>(false);
	const [challengeDetailsData, setChallengeDetailsData] = useState<IChallengeDetails>();
	const [addHashTag, setAddHashTag] = useState<string[]>([]);
	const [bgMediaInfo, setBgMediaInfo] = useState<IMediaDetails>();
	const [previewMediaInfo, setPreviewMediaInfo] = useState<IMediaDetails>();

	const isDisable = type === 'edit' && currentChallengeStatus !== 'upcoming';
	const isDisableActive = type === 'edit' && currentChallengeStatus === 'active';

	const addChallenge = useCallback(
		(data: IChallenge) => {
			setIsAddingChallengeLoader(true);
			httpService
				.post(`${API_CONFIG.path.challenge}`, data)
				.then(() => {
					setIsAddingChallengeLoader(false);
					setIsModalOpen(false);
					fetchChallenges();
					setVideoUrl('');
					setVideoPreviewUrl('');
					setPreviewUrl('');
					setAddHashTag([]);
					setCurrentModalType('');
					formikRef.current?.resetForm();
				})
				.catch((error) => {
					console.error('error', error);
					setIsAddingChallengeLoader(false);
				});
		},
		[challengeData.data]
	);

	const editChallenge = useCallback(
		(data: IChallenge, id: string) => {
			return new Promise((resolve, reject) => {
				setIsEditChallengeModalLoader(true);
				httpService
					.put(`${API_CONFIG.path.challenge}/${id}`, data)
					.then((response) => {
						setIsEditChallengeModalLoader(false);
						setIsModalOpen(false);
						resolve(response);
					})
					.catch((error) => {
						console.error('error', error);
						setIsEditChallengeModalLoader(false);
						setIsModalOpen(false);
						reject(null);
					});
			});
		},
		[setIsModalOpen]
	);

	const fetchChallengesDetails = useCallback((challengeId: string) => {
		setIsGettingChallengeDetailsLoader(true);
		httpService
			.get(`${API_CONFIG.path.challenge}/${challengeId}`)
			.then((response) => {
				setChallengeDetailsData(response);
				setIsGettingChallengeDetailsLoader(false);
			})
			.catch((error) => {
				console.error('error', error);
				setIsGettingChallengeDetailsLoader(false);
			});
	}, []);

	useEffect(() => {
		if (type === 'edit' && !challengeDetailsData) {
			fetchChallengesDetails(currentChallengeId);
		}
	}, [fetchChallengesDetails, currentChallengeId, type, challengeDetailsData]);

	const handleEditChallenge = useCallback(
		(data: IChallenge, id: string) => {
			const handleEdit = async () => {
				const updatedData = await editChallenge(data, id);

				if (updatedData) {
					const appendedData: any = [...challengeData.data];
					const updatedAppendedData = appendedData.map((item) => {
						if (item.id === id) {
							return updatedData;
						}
						return item;
					});

					setChallengeData({
						data: updatedAppendedData,
						total: updatedAppendedData.length
					});
					setChallengeDetailsData(undefined);
				}
			};

			handleEdit();
		},
		[challengeData]
	);

	const handleChange = (
		file: File,
		setFieldValue: (name: string, value: string) => void,
		fileUploaderType: string
	) => {
		if (fileUploaderType == 'withImageOnly') {
			setPrizeUploading(true);
			const reader: any = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = function () {
				setPreviewUrl(reader.result);
				setFieldValue('previewUrl', reader.result);
				setPrizeUploading(false);
				setPreviewMediaInfo({ file, setFieldValue, fileUploaderType });
			}.bind(this);
		}
		if (fileUploaderType != 'withImageOnly') {
			setUploading(true);
			const reader: any = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = function () {
				setVideoPreviewUrl(reader.result);
				setFieldValue('videoPreviewUrl', reader.result);
				setUploading(false);
				setBgMediaInfo({ file, setFieldValue, fileUploaderType });
			}.bind(this);
		}
	};

	const updatedHashtagData = (hashTagData) => {
		let latestHashtag = [];
		hashTagData.forEach((hashtag) => {
			latestHashtag = [...latestHashtag, ...hashtag.split(' ')];
		});
		return latestHashtag;
	};

	const handleUploadAssets = useCallback(async (uploadData: IMediaDetails) => {
		const { file } = uploadData;
		const formData = new FormData();
		formData.append('name', 'challengeImages/' + formatFileName(file.name));
		if (formData) {
			const res = await httpService.post(
				`${API_CONFIG.path.challengeUpload}`,
				{ fileName: file.name, type: 'challenge-prize' },
				{ contentType: 'multipart/form-data' }
			);

			await toast.promise(
				uploadFileToS3(res, file).then(() => {
					if (previewMediaInfo?.fileUploaderType === 'withImageOnly') {
						setPreviewUrl(res.previewUrl);
						setPrizeUploading(false);
					} else if (previewMediaInfo?.fileUploaderType !== undefined) {
						setVideoUrl(res.previewUrl);
						setCurrentFileType(file.type);
						setUploading(false);
					}
				}),
				{
					loading: 'Uploading...',
					success: <b>File Uploaded!</b>,
					error: <b>Could not upload.</b>
				}
			);
			return res;
		}
	}, []);

	const handleSubmit = useCallback(
		async (formData) => {
			let previewS3Url = previewUrl;
			let videoS3Url = videoUrl;
			if (!isEmpty(bgMediaInfo)) {
				const videoS3UrlData = await handleUploadAssets(bgMediaInfo);
				videoS3Url = videoS3UrlData.previewUrl;
			}

			if (!isEmpty(previewMediaInfo)) {
				const previewS3UrlData = await handleUploadAssets(previewMediaInfo);
				previewS3Url = previewS3UrlData.previewUrl;
			}

			if (currentFileType == 'video/mp4') {
				setCurrentFileType('video/mp4');
			}
			if (type == 'add') {
				const prize = {
					title: formData.prize_name ?? '',
					prizeImage: previewS3Url ?? '',
					imageUrl: videoS3Url && currentFileType !== 'video/mp4' ? videoS3Url : '',
					videoUrl: videoS3Url && currentFileType === 'video/mp4' ? videoS3Url : '',
					description: formData.prize_description ? formData.prize_description : ''
				};

				const modifiedData = {
					...formData,
					challengeRegEndAt: moment(moment(formData.challengeRegEndAt).format()).valueOf(),
					challengeRegStartAt: moment(moment(formData.challengeRegStartAt).format()).valueOf(),
					challengeVoteEndAt: moment(moment(formData.challengeVoteEndAt).format()).valueOf(),
					challengeVoteStartAt: moment(moment(formData.challengeVoteStartAt).format()).valueOf(),
					hashtags: addHashTag,
					isPrivate: true,
					partnerImage: '',
					challengeIcon: '',
					categoryId: formData.category,
					challengeType: 'private',
					minAge: Number(formData.minAge),
					prize,
					performerType: active,
					musicIds: formData.musicIds ? formData.musicIds.map((music: IMusic) => music.id) : []
				};

				delete modifiedData.category;
				delete modifiedData.prize_name;
				delete modifiedData.prize_description;
				delete modifiedData.videoUrl;
				delete modifiedData.previewUrl;
				delete modifiedData.videoPreviewUrl;
				delete modifiedData.prizeImage;

				if (formData.category) {
					addChallenge(modifiedData);
				}
			} else {
				// edit Data Goes Here.
				const isVideo = currentFileType == 'video/mp4' ? true : false;
				const modifiedEditData = {
					title: formData.title,
					challengeRegEndAt: moment(moment(formData.challengeRegEndAt).format()).valueOf(),
					challengeRegStartAt: moment(moment(formData.challengeRegStartAt).format()).valueOf(),
					challengeVoteEndAt: moment(moment(formData.challengeVoteEndAt).format()).valueOf(),
					challengeVoteStartAt: moment(moment(formData.challengeVoteStartAt).format()).valueOf(),
					description: formData.description,
					challengeType: 'private',
					isPrivate: true,
					partnerImage: '',
					challengeIcon: '',
					categoryId: formData.category,
					performerType: active,
					minAge: Number(formData.minAge),
					prize: {
						title: formData.prize_name ? formData.prize_name : ' ',
						prizeImage: previewS3Url ?? '',
						imageUrl: videoS3Url && !isVideo ? videoS3Url : '',
						videoUrl: videoS3Url && isVideo ? videoS3Url : '',
						description: formData.prize_description ? formData.prize_description : ''
					},
					hashtags: updatedHashtagData(addHashTag),
					musicIds: formData.musicIds.map((music: IMusic) => music.id)
				};

				handleEditChallenge(modifiedEditData, challengeDetailsData.id);
			}
		},
		[
			bgMediaInfo,
			previewMediaInfo,
			previewUrl,
			currentFileType,
			type,
			handleUploadAssets,
			videoUrl,
			addHashTag,
			active,
			addChallenge,
			handleEditChallenge,
			challengeDetailsData?.id
		]
	);

	useEffect(() => {
		const currentDate = moment(moment().format()).valueOf();
		if (type == 'edit' && !isEmpty(challengeDetailsData)) {
			setAddHashTag(challengeDetailsData?.hashtags);

			setActive(challengeDetailsData?.performerType && challengeDetailsData?.performerType.toLocaleLowerCase());
			setPreviewUrl(challengeDetailsData?.prize?.prizeImage);
			if (
				challengeDetailsData?.prize?.videoUrl &&
				challengeDetailsData?.prize?.videoUrl.split('.').slice(-1)[0] == 'mp4'
			) {
				setCurrentFileType('video/mp4');
				setVideoPreviewUrl(challengeDetailsData?.prize?.videoUrl);
				setVideoUrl(challengeDetailsData?.prize?.videoUrl);
			} else {
				setCurrentFileType('image/jpeg');
				setVideoPreviewUrl(challengeDetailsData?.prize?.imageUrl);
			}

			const challengeData = {
				title: challengeDetailsData.title,
				description: challengeDetailsData.description,
				challengeType: challengeDetailsData.challengeType,
				challengeRegStartAt: challengeDetailsData.challengeRegStartAt,
				challengeRegEndAt: challengeDetailsData.challengeRegEndAt,
				challengeVoteStartAt: challengeDetailsData.challengeVoteStartAt,
				challengeVoteEndAt: challengeDetailsData.challengeVoteEndAt,
				isPrivate: challengeDetailsData.isPrivate,
				partnerImage: challengeDetailsData.partnerImage,
				challengeIcon: challengeDetailsData.challengeIcon,
				categoryId: challengeDetailsData.category?.id,
				performerType: challengeDetailsData.performerType,
				minAge: `${challengeDetailsData.minAge}`,
				prize_name: !isEmpty(challengeDetailsData.prize) ? challengeDetailsData.prize?.title : '',
				prizeImage: !isEmpty(challengeDetailsData.prize) ? challengeDetailsData.prize?.prizeImage : '',
				imageUrl: !isEmpty(challengeDetailsData.prize)
					? currentFileType != 'video/mp4'
						? challengeDetailsData.prize?.imageUrl
						: ''
					: '',
				videoUrl: !isEmpty(challengeDetailsData.prize)
					? currentFileType == 'video/mp4'
						? challengeDetailsData.prize?.videoUrl
						: ''
					: '',
				prize_description: !isEmpty(challengeDetailsData.prize) ? challengeDetailsData.prize?.description : '',
				category: challengeDetailsData.category?.id,
				previewUrl: '',
				videoPreviewUrl: '',
				musicIds: challengeDetailsData.musics || []
			};

			setChallengeInitVal(challengeData);

			//set edit challenge value
			formikRef?.current?.resetForm({ values: { ...challengeData } });

			if (challengeDetailsData.challengeRegStartAt > currentDate) {
				setCurrentChallengeStatus('upcoming');
			} else if (
				challengeDetailsData.challengeRegStartAt <= currentDate &&
				currentDate <= challengeDetailsData.challengeVoteEndAt
			) {
				setCurrentChallengeStatus('active');
			}
		} else {
			setChallengeInitVal(challengeInitValues);
			setActive('single');
		}
	}, [currentFileType, challengeDetailsData, type]);

	useEffect(() => {
		const file = document.getElementsByName('file');
		if (file[0]) {
			file[0].blur();
		}
		document.addEventListener('keydown', (e) => {
			const activeEle = document.activeElement as HTMLInputElement;
			if (!['file', 'description', 'prize_description'].includes(activeEle.name) && e.which === 13) {
				e.preventDefault();
			}
		});
	}, [document.activeElement]);

	const handleMusicSelection = (
		music: IMusic,
		field: string,
		musicList: IMusic[] = [],
		setFieldValue: (name: string, value: any) => void
	) => {
		if (musicList.indexOf(music) >= 0) {
			setFieldValue(
				field,
				musicList.filter((data) => data.id != music.id)
			);
		} else {
			setFieldValue(field, [...musicList, music]);
		}
	};

	return (
		<>
			<CustomModal
				show={show}
				handleClose={() => {
					handleClose();
					setUploading(false);
					setPrizeUploading(false);
				}}
				closeOnOutSideClick
				className='add-challenge-modal'
			>
				{
					<Formik
						innerRef={formikRef}
						initialValues={challengeInitVal}
						onSubmit={handleSubmit}
						validationSchema={
							type == 'add' ? addChallengesValidationSchema : editChallengesValidationSchema
						}
						validateOnBlur={false}
						validateOnChange={true}
						validateOnMount={false}
					>
						{({ handleSubmit, setFieldValue, values, resetForm, errors }) => {
							return (
								<>
									<ScrollToFieldError />
									<ChallengeForm
										type={type}
										isDisableActive={isDisableActive}
										listOfCategory={listOfCategory}
										values={values}
										isDisable={isDisable}
										active={active}
										videoPreviewUrl={videoPreviewUrl}
										currentFileType={currentFileType}
										isBrowseFileLoader={isAddingChallengeLoader || isEditChallengeModalLoader}
										previewUrl={previewUrl}
										isSubmitLoader={
											isAddingChallengeLoader ||
											isEditChallengeModalLoader ||
											isGettingChallengeDetailsLoader ||
											uploading ||
											prizeUploading
										}
										addHashTag={addHashTag}
										uploading={uploading}
										prizeUploading={prizeUploading}
										setAddHashTag={setAddHashTag}
										handleSubmit={handleSubmit}
										setFieldValue={setFieldValue}
										setChallengeInitVal={setChallengeInitVal}
										handleClose={() => {
											handleClose();
											setUploading(false);
											setPrizeUploading(false);
										}}
										handleMusicSelection={handleMusicSelection}
										setActive={setActive}
										setVideoPreviewUrl={setVideoPreviewUrl}
										setVideoUrl={setVideoUrl}
										handleChange={handleChange}
										resetForm={resetForm}
										setCurrentModalType={setCurrentModalType}
										setPreviewUrl={setPreviewUrl}
										setChallengeDetailsData={() => setChallengeDetailsData(undefined)}
										errors={errors}
									/>
								</>
							);
						}}
					</Formik>
				}
			</CustomModal>
		</>
	);
};

export default AddChallengeModal;
