import { useNavigate } from 'react-router-dom';

import { numberFormat } from 'shared/helpers';
import { CalenderUserWinnerIcon } from 'shared/icons/icons';
import { formatDateDmy } from 'shared/util/formatFn';

import { IChallengeInfo } from '../interface/challenges';

export interface IChallengesCalender {
	calenderItemData: IChallengeInfo;
	handleCalendarEvent: (id: string, type: 'view') => void;
}

export const CalenderCard: React.FC<IChallengesCalender> = (props) => {
	const { calenderItemData, handleCalendarEvent } = props;

	const totalVotes = calenderItemData.totalVotes;
	const navigate = useNavigate();
	const usersCounts = calenderItemData.totalParticipants;

	return (
		<div className='calender-card-wrapper' onClick={() => handleCalendarEvent(calenderItemData.id, 'view')}>
			<div className='users-details'>
				<div className='calender-user-data'>
					<h4>{calenderItemData.title}</h4>
					<span>{`${formatDateDmy(calenderItemData.challengeRegStartAt, 'DD.MM.YYYY')} - ${formatDateDmy(
						calenderItemData.challengeVoteEndAt,
						'DD.MM.YYYY'
					)}`}</span>
				</div>
				<div className='avatars'>
					{calenderItemData.topUsers.length > 0 && (
						<>
							<ul>
								{calenderItemData.topUsers.slice(1, 5).map((user, index) => {
									return (
										<li
											className='avatar'
											key={index}
											title={user.userName}
											onClick={() => navigate(`/user-management/${user.id}`)}
										>
											<figure>
												<img src={user.image} />
											</figure>
										</li>
									);
								})}
							</ul>
							{usersCounts > 5 && (
								<div className='user-counts'>
									<span>+{usersCounts - 5}</span>
								</div>
							)}
						</>
					)}
				</div>
			</div>
			<div className='new-request-btn-wrapper'>
				<div className='btn-wrapper'>
					<div className='new-request-btn'>New Request: 28</div>
				</div>
				<div className='winner-icon-wrapper'>
					<CalenderUserWinnerIcon />
					<span>{`${numberFormat(totalVotes ? (totalVotes == 0 ? 0 : totalVotes) : 0, 1)}`}</span>
				</div>
			</div>
		</div>
	);
};

export default CalenderCard;
