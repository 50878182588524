import React, { FC } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { ErrorMessage } from 'formik';
import { videoFileTypes } from 'shared/constants/constants';
import Button from 'shared/components/form/button';
import { Loader } from 'shared/components/spinner/spinner';
import { fileTypes } from 'features/challenges/utility/challengeConstants';
import { Input } from 'shared/components/form/inputTypes';
import { IOnboardUserDetail } from '../interface/onboarding';

interface IOnboardingFormProps {
	handleChange: (
		key: string,
		name: string,
		file: File,
		setFieldValue: (name: string, value: string) => void,
		setFieldError: (field: string, message: string) => void,
		fileUploaderType: string
	) => void;
	setFieldValue: (name: string, value: string) => void;
	setFieldError: (field: string, message: string) => void;
	values: IOnboardUserDetail;
	isLoading: boolean;
	handleSubmit: () => void;
	uploadingStates: any;
	isUpdate: boolean;
}

const OnboardingForm: FC<IOnboardingFormProps> = ({
	handleChange,
	setFieldValue,
	setFieldError,
	values,
	uploadingStates,
	handleSubmit,
	isLoading,
	isUpdate
}) => (
	<form onSubmit={handleSubmit} className='full-width d-flex justify-content-space-between'>
		<div className='d-flex full-width'>
			{!isLoading &&
				Object.keys(values.value).map((key, index) => (
					<div key={key} className='user-card-wrapper'>
						<div className='card-inner-wrapper'>
							<div className='user-form-wrapper'>
								<h4 className='text--red-hat-display font-weight-700 text-align-center'>
									User {index + 1}
								</h4>
								<div className='form-inner-wrapper'>
									<div className='form-group'>
										<h5>User Video</h5>
										<div className='common-file-upload-wrapper'>
											<FileUploader
												handleChange={(file: File) =>
													handleChange(
														key,
														`value.${key}.videoUrl`,
														file,
														setFieldValue,
														setFieldError,
														'videoOnly'
													)
												}
												name={`value.${key}.videoUrl`}
												types={videoFileTypes}
												className='full-width'
											>
												{values.value[key].videoUrl &&
												!uploadingStates[`value.${key}.videoUrl`] ? (
													<div className='cursor-pointer'>
														{values.value[key].videoUrl && (
															<video controls className='uploaded-media-preview'>
																<source src={values.value[key].videoUrl} />
															</video>
														)}
													</div>
												) : (
													<div className='file-upload-wrapper bg--white cursor-pointer'>
														{!uploadingStates[`value.${key}.videoUrl`] ? (
															<>
																<h4 className='upload-photo-text'>
																	Drag & Drop background video here.
																</h4>
																<p className='photo-size-text'>
																	Supported format: MP4 or Webm.
																</p>
																<span className='file-upload'>or</span>
																<div className='browse-button-wrapper'>
																	<Button btnType='default' className='browse-button'>
																		Browse files
																	</Button>
																</div>
															</>
														) : (
															<Loader />
														)}
													</div>
												)}
											</FileUploader>
										</div>
										<ErrorMessage
											name={`value.${key}.videoUrl`}
											component='div'
											className='error'
										/>
									</div>
									<div className='form-group'>
										<h5>User Picture</h5>
										<div className='user-file-upload-wrapper'>
											<FileUploader
												handleChange={(file: File) =>
													handleChange(
														key,
														`value.${key}.userProfile`,
														file,
														setFieldValue,
														setFieldError,
														'imageOnly'
													)
												}
												name={`value.${key}.userProfile`}
												types={fileTypes}
											>
												<div className='file-upload-wrapper bg--white  cursor-pointer'>
													{values.value[key].userProfile &&
													!uploadingStates[`value.${key}.userProfile`] ? (
														<img
															src={values.value[key].userProfile}
															alt='file upload image'
															className='file-image'
														/>
													) : (
														<div className='image-text-wrapper'>
															{!uploadingStates[`value.${key}.userProfile`] ? (
																<h4 className='upload-photo-text'>
																	Drag & Drop user photo here
																</h4>
															) : (
																<Loader />
															)}
														</div>
													)}
												</div>
											</FileUploader>
										</div>
										<ErrorMessage
											name={`value.${key}.userProfile`}
											component='div'
											className='error text-align-center'
										/>
									</div>
									<div className='form-group'>
										<h5>User Thumbnail</h5>
										<div className='common-file-upload-wrapper'>
											<FileUploader
												handleChange={(file: File) =>
													handleChange(
														key,
														`value.${key}.thumbnailUrl`,
														file,
														setFieldValue,
														setFieldError,
														'thumbnail'
													)
												}
												name={`value.${key}.thumbnailUrl`}
												types={fileTypes}
												className='full-width'
											>
												{values.value[key].thumbnailUrl &&
												!uploadingStates[`value.${key}.thumbnailUrl`] ? (
													<img
														src={values.value[key].thumbnailUrl}
														alt='file upload image'
														className='uploaded-media-preview'
													/>
												) : (
													<div className='file-upload-wrapper bg--white cursor-pointer'>
														<div className='image-text-wrapper'>
															{!uploadingStates[`value.${key}.thumbnailUrl`] ? (
																<>
																	<h4 className='upload-photo-text'>
																		Drag & Drop thumbnail photo here
																	</h4>
																	<p className='photo-size-text'>
																		Supported format: JPG, JPEG, GIF, PNG.
																	</p>
																</>
															) : (
																<Loader />
															)}
														</div>
													</div>
												)}
											</FileUploader>
										</div>
										<ErrorMessage
											name={`value.${key}.thumbnailUrl`}
											component='div'
											className='error'
										/>
									</div>
									<div className='form-group'>
										<Input
											type='text'
											name={`value.${key}.userName`}
											placeholder='Start typing...'
											setFieldValue={setFieldValue}
											showLabels
											className='full-width input-field'
											config={{
												name: `value.${key}.userName`,
												type: 'text',
												label: 'User Name'
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			{isLoading && <Loader className='onboarding-loader' />}
		</div>
		<Button
			btnType='primary'
			type='submit'
			disabled={isLoading || Object.values(uploadingStates).some((state) => state)}
			className='line-height--20px mt-12 height--40px width-100px'
			onClick={handleSubmit}
		>
			{isUpdate ? 'Update' : 'Save'}
		</Button>
	</form>
);

export default OnboardingForm;
