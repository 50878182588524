import { FC, useCallback, useEffect, useState } from 'react';
import isNull from 'lodash/isNull';

import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

import { IAllVideoReports } from 'features/userManagement/interface/userManagement';
import { UsersReports } from 'features/userManagement/components/userReports';

interface IReportsSliderProps {
	categoryId: string;
	title: string;
}

const ReportsSlider: FC<IReportsSliderProps> = ({ categoryId, title }) => {
	const [reportsCategoryLoader, setReportsCategoryLoader] = useState(false);
	const [reportIndex, setReportIndex] = useState(null);
	const [reportsSliderData, setReportsSliderData] = useState<IAllVideoReports>({ data: [] });
	const [reportActionFlag, setReportActionFlag] = useState(false);

	const fetchReportDetails = useCallback(() => {
		setReportsCategoryLoader(true);
		const params = { categoryId };
		const detailUrl = `${API_CONFIG.path.report}`;
		httpService
			.get(detailUrl, params)
			.then((response) => {
				setReportsSliderData({ data: response.data });
				setReportsCategoryLoader(false);
			})
			.catch((error) => {
				setReportsCategoryLoader(false);
				console.error(error);
			});
	}, [categoryId]);

	useEffect(() => {
		fetchReportDetails();
	}, [fetchReportDetails]);

	const handleReportData = useCallback((index: number) => {
		setReportActionFlag(false);
		setReportIndex(index);
	}, []);

	useEffect(() => {
		if (!isNull(reportIndex)) {
			reportsSliderData.data.splice(reportIndex, 1);
			setReportsSliderData({ data: reportsSliderData.data });
			setReportActionFlag(true);
		}
	}, [reportIndex, handleReportData]);

	return (
		<div className='reports-category-wrapper' key={categoryId}>
			<UsersReports
				type='report'
				reportTitle={title}
				reportCategoryId={categoryId}
				videoReportsData={reportsSliderData}
				loading={reportsCategoryLoader}
				reportActionFlag={reportActionFlag}
				handleReportData={handleReportData}
			/>
		</div>
	);
};

export default ReportsSlider;
