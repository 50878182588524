import React, { ChangeEventHandler, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { ReportsFileWarningIcon } from 'shared/icons/icons';
import { IDropDownAndCheckboxOptions } from './inputTypes';
import { reactSelectStyles } from './reactSelectStyles';

interface IProps {
	className?: string;
	handleDropdown: (categoryType: string, filterType: string) => void;
	dropDownOptions: IDropDownAndCheckboxOptions[];
	customDropDownStyles?: StylesConfig<any, any>;
	filterType: string | 'challenges' | 'calenderChallenges' | 'reportsCategory' | 'userStatus';
	selected?: string;
	placeholder?: string;
}

const CustomDropDown: React.FC<IProps> = (props) => {
	const [currentSelectedOption, setCurrentSelectedOption] = useState<string>('');

	const handleChangeReact: ChangeEventHandler<any> = (e: any) => {
		setCurrentSelectedOption(e.value);
		props.handleDropdown(e.value, props.filterType);
	};

	return (
		<div className={`${props.className || ''} dropdown-category`}>
			{props.filterType == 'reportsCategory' && (
				<div className='reports-dropdown-icon'>
					<ReportsFileWarningIcon />
				</div>
			)}
			<Select
				key={props.dropDownOptions.length}
				placeholder={props.placeholder}
				styles={{ ...reactSelectStyles, ...(props.customDropDownStyles || {}) }}
				isClearable={false}
				name={currentSelectedOption}
				options={props.dropDownOptions}
				onChange={(selectedOption: any) => handleChangeReact(selectedOption)}
				classNamePrefix='select dropdown-select'
				disabled={false}
				isMulti={false}
				className={`form-control react-select`}
				isSearchable={false}
				isOptionDisabled={(option) => option.disabled}
				value={props.dropDownOptions.find((option) => option.value === props.selected)}
			/>
		</div>
	);
};
export default CustomDropDown;
