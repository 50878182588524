export const initialValues = {
	value: {
		userVideo1: {
			userName: '',
			userProfile: '',
			videoUrl: '',
			thumbnailUrl: ''
		},
		userVideo2: {
			userName: '',
			userProfile: '',
			videoUrl: '',
			thumbnailUrl: ''
		}
	}
};

export const ONBOARD_API_KEY = 'on_boarding_tutorial';
