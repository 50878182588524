import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import CustomModal from 'shared/components/modal/modal';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { Loader } from 'shared/components/spinner/spinner';
import Button from 'shared/components/form/button';
import { maxHashTagLimit, maxSongListLimit } from 'shared/constants/constants';
import { IconCircle } from 'shared/icons/icons';

import { IMusic } from 'features/music/interface/music';
import { IChallengeDetails } from '../interface/challenges';

interface IProps {
	show: boolean;
	currentChallengeId: string;
	handleClose: () => void;
}

const ViewChallengeModal = (props: IProps) => {
	const { show, handleClose, currentChallengeId } = props;

	const [challengeDetailsData, setChallengeDetailsData] = useState<IChallengeDetails>();
	const [isGettingChallengeDetails, setIsGettingChallengeDetails] = useState<boolean>(false);
	const [showMoreMusicList, setShowMoreMusicList] = useState(false);
	const [showMoreHashtags, setShowMoreHashtags] = useState(false);

	const formatDate = (timestamp) => {
		return moment.unix(timestamp / 1000).format('DD MMM YYYY hh:mm A');
	};

	const fetchChallengesDetails = useCallback((challengeId: string) => {
		setIsGettingChallengeDetails(true);
		httpService
			.get(`${API_CONFIG.path.challenge}/${challengeId}`)
			.then((response) => {
				setChallengeDetailsData(response);
				setIsGettingChallengeDetails(false);
			})
			.catch((error) => {
				console.error('error', error);
				setIsGettingChallengeDetails(false);
			});
	}, []);

	const toggleShowMusicList = () => {
		setShowMoreMusicList(!showMoreMusicList);
	};

	const toggleShowHashTags = () => {
		setShowMoreHashtags(!showMoreHashtags);
	};

	useEffect(() => {
		fetchChallengesDetails(currentChallengeId);
	}, [fetchChallengesDetails, currentChallengeId]);

	return (
		<CustomModal show={show} closeOnOutSideClick handleClose={handleClose} className='view-challenge-modal'>
			<h1>View Challenge</h1>
			{isGettingChallengeDetails || !challengeDetailsData || Object.keys(challengeDetailsData).length === 0 ? (
				<Loader className='custom-loader' />
			) : (
				<div className='view-challenge-modal-wrapper'>
					<div className='challenge-card-detail'>
						<h5>Challenge Name</h5>
						<h5 className='challenge-detail font-weight-700'>{challengeDetailsData.title}</h5>
					</div>
					<div className='challenge-card-detail'>
						<h5>Category</h5>
						<h5 className='challenge-detail font-weight-700'>{challengeDetailsData.category?.name}</h5>
					</div>
					<div>
						<div className='submission-dates'>
							<h5 className='submission'>submission</h5>
							<div className='challenge-card-detail submission-date-pickers'>
								<h5 className='start-date-text'>Start Date</h5>
								<span className='challenge-detail start-date'>
									{formatDate(challengeDetailsData.challengeRegStartAt)}
								</span>
							</div>
							<span className='submission-dates-line'>-</span>
							<div className='challenge-card-detail submission-date-pickers'>
								<h5 className='start-date-text'>End Date</h5>
								<span className='challenge-detail start-date'>
									{formatDate(challengeDetailsData.challengeRegEndAt)}
								</span>
							</div>
						</div>
						<div className='submission-dates'>
							<h5 className='voting'>voting</h5>
							<div className='challenge-card-detail voting-date-pickers'>
								<span className='challenge-detail start-date'>
									{formatDate(challengeDetailsData.challengeVoteStartAt)}
								</span>
							</div>
							<span className='voting-dates-line'>-</span>
							<div className='challenge-card-detail voting-date-pickers'>
								<span className='challenge-detail start-date'>
									{formatDate(challengeDetailsData.challengeVoteEndAt)}
								</span>
							</div>
						</div>
					</div>
					<div className='challenge-card-detail mt-20'>
						<h5>Description</h5>
						<p className='challenge-detail ml-10 font--14px'>{challengeDetailsData.description}</p>
					</div>
					{challengeDetailsData.hashtags.length > 0 && (
						<div className='challenge-card-detail'>
							<h5>Hashtags</h5>
							<div className='add-hash-tag-wrapper mt-0'>
								{[
									...(challengeDetailsData.hashtags.length > maxHashTagLimit && !showMoreHashtags
										? challengeDetailsData.hashtags.slice(0, maxHashTagLimit)
										: challengeDetailsData.hashtags)
								].map((hash, index) => (
									<span className='add-hash-tag hash-tag-text' key={index}>
										{hash}
									</span>
								))}
								{challengeDetailsData.hashtags.length > maxHashTagLimit && (
									<span
										className={`add-hash-tag hash-tag-text cursor-pointer
													${showMoreHashtags ? 'show-btn showmore' : 'show-btn'}`}
										onClick={toggleShowHashTags}
									>
										{showMoreHashtags ? 'Show Less' : 'Show More'}
									</span>
								)}
							</div>
						</div>
					)}
					{challengeDetailsData.musics.length > 0 && (
						<div className='challenge-card-detail'>
							<h5>Songs</h5>
							<div className='d-flex d-flex-dir-column'>
								<div>
									{[
										...(challengeDetailsData.musics.length > maxSongListLimit && !showMoreMusicList
											? challengeDetailsData.musics.slice(0, maxSongListLimit)
											: challengeDetailsData.musics)
									].map((music: IMusic) => {
										return (
											<div key={music.id} className='flex-space-between pb-10'>
												<div className='d-flex'>
													<IconCircle />
													<label className='ml-10' htmlFor='html'>
														{music.title}
													</label>
												</div>
											</div>
										);
									})}
								</div>
								{challengeDetailsData.musics.length > maxSongListLimit && (
									<span
										className='cursor-pointer text-underline align-self-end'
										onClick={toggleShowMusicList}
									>
										{showMoreMusicList ? 'Show Less' : 'Show More'}
									</span>
								)}
							</div>
						</div>
					)}
					<h2 className='mt-20'>Participants restrictions</h2>
					<div className='challenge-age-type '>
						<div className='challenge-card-detail pb-0 pt-15'>
							<h5>Minimum age</h5>
							<p className='challenge-detail minimum-age'>
								{challengeDetailsData.minAge ? challengeDetailsData.minAge : 'N/A'}
							</p>
						</div>
						<div className='challenge-card-detail performer-type'>
							<h5>Type</h5>
							<Button btnType='primary' type='submit' className='challenge-detail performer-type-btn'>
								{challengeDetailsData.performerType}
							</Button>
						</div>
					</div>
					<div className='challenge-card-detail mt-20'>
						<h5>Description</h5>
						<p className='challenge-detail ml-10 font--14px'>{challengeDetailsData.prize?.description}</p>
					</div>
					{(challengeDetailsData?.prize?.videoUrl || challengeDetailsData?.prize?.imageUrl) && (
						<div className='challenge-card-detail'>
							<h5>Background Picture / Video</h5>
							{(challengeDetailsData?.prize?.videoUrl || challengeDetailsData?.prize?.imageUrl) && (
								<>
									{challengeDetailsData?.prize?.videoUrl?.split('.').slice(-1)[0] == 'mp4' ? (
										<video width='100%' height='240' controls>
											<source src={challengeDetailsData?.prize?.videoUrl} type='video/mp4' />
										</video>
									) : (
										<img
											src={challengeDetailsData?.prize?.imageUrl}
											alt='file upload image'
											className='file-image'
										/>
									)}
								</>
							)}
						</div>
					)}
					<h2>Prize</h2>
					<div className={`prize-detail-wrapper`}>
						{challengeDetailsData?.prize?.prizeImage && (
							<div className='challenge-card-detail'>
								<h5>Prize Image</h5>
								<img src={challengeDetailsData?.prize?.prizeImage} alt='prize-image' />
							</div>
						)}
						<div className='challenge-card-detail'>
							<h5>Prize Name</h5>
							<span className='challenge-detail'>{challengeDetailsData.prize?.title}</span>
						</div>
					</div>
				</div>
			)}
		</CustomModal>
	);
};
export default ViewChallengeModal;
