import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Formik } from 'formik';

import { formatFileName, uploadFileToS3, validateFileSize } from 'shared/util/utility';
import { getAddOnboardingValidationSchema } from 'shared/constants/validation-schema';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

import { IMediaDetails, IOnboardUserDetail } from '../interface/onboarding';
import { ONBOARD_API_KEY, initialValues } from '../constants/onboardingConstant';
import OnboardingForm from '../components/onboardingForm';
import '../styles/onboarding.scss';

const Onboarding = () => {
	const [bgMediaInfo, setBgMediaInfo] = useState<IMediaDetails[]>([]);
	const [previewMediaInfo, setPreviewMediaInfo] = useState<IMediaDetails[]>([]);
	const [thumbnailMediaInfo, setThumbnailMediaInfo] = useState<IMediaDetails[]>([]);
	const [onboardUserDetail, setOnboardUserDetail] = useState<IOnboardUserDetail>(initialValues);
	const [uploadingStates, setUploadingStates] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const fetchUsersDetails = async () => {
		setIsLoading(true);
		try {
			const response = await httpService.get(`${API_CONFIG.path.onboarding}/on_boarding_tutorial`);
			setOnboardUserDetail({ value: response.value });
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error('error', error);
		}
	};

	// upload assets in s3
	const handleUploadAssets = useCallback(async (uploadData: IMediaDetails) => {
		const { file } = uploadData;
		const formData = new FormData();
		formData.append('name', 'settingImages/' + formatFileName(file.name));
		const res = await httpService.post(
			`${API_CONFIG.path.onboardUpload}`,
			{ fileName: file.name, type: ONBOARD_API_KEY },
			{ contentType: 'multipart/form-data' }
		);

		await toast.promise(uploadFileToS3(res, file).then(), {
			loading: 'Uploading...',
			success: <b>File Uploaded!</b>,
			error: <b>Could not upload.</b>
		});
		return res;
	}, []);

	const handleChange = (
		key: string,
		name: string,
		file: File,
		setFieldValue: (name: string, value: string) => void,
		setFieldError: (field: string, message: string) => void,
		fileUploaderType: string
	) => {
		// Validate file size
		if (!validateFileSize(file, fileUploaderType, setFieldError, name)) {
			return;
		}
		// Set uploading state
		setUploadingStates((prev) => ({ ...prev, [name]: true }));

		const reader = new FileReader();

		reader.onloadend = () => {
			// Set field value with file data
			setFieldValue(name, reader.result as string);

			// Define the media info update function
			const updateMediaInfo = {
				imageOnly: () =>
					setPreviewMediaInfo((prev) => [...prev, { file, setFieldValue, fileUploaderType, key }]),
				thumbnail: () =>
					setThumbnailMediaInfo((prev) => [...prev, { file, setFieldValue, fileUploaderType, key }]),
				default: () => setBgMediaInfo((prev) => [...prev, { file, setFieldValue, fileUploaderType, key }])
			};

			// Execute the update function based on fileUploaderType or default
			(updateMediaInfo[fileUploaderType] || updateMediaInfo.default)();
			setUploadingStates((prev) => ({ ...prev, [name]: false }));
		};

		reader.readAsDataURL(file);
	};

	const handleSubmit = useCallback(
		async (values: IOnboardUserDetail) => {
			setIsLoading(true); // Set loading state once at the beginning
			try {
				const updatedValues = { value: { ...onboardUserDetail.value } };

				const uploadPromises = Object.keys(values.value).map(async (key) => {
					const item = values.value[key];
					let videoS3Url = onboardUserDetail.value[key].videoUrl;
					let profileS3Url = onboardUserDetail.value[key].userProfile;
					let thumbnailS3Url = onboardUserDetail.value[key].thumbnailUrl;

					// Loop over bgMediaInfo for each key
					for (const bgMedia of bgMediaInfo) {
						if (
							bgMedia.key === key &&
							bgMedia.fileUploaderType === 'videoOnly' &&
							values.value[key].videoUrl !== videoS3Url
						) {
							const videoS3UrlData = await handleUploadAssets(bgMedia);
							videoS3Url = videoS3UrlData.previewUrl;
						}
					}

					// Loop over previewMediaInfo for each key
					for (const previewMedia of previewMediaInfo) {
						if (
							previewMedia.key === key &&
							previewMedia.fileUploaderType === 'imageOnly' &&
							values.value[key].userProfile !== profileS3Url
						) {
							const profileS3UrlData = await handleUploadAssets(previewMedia);
							profileS3Url = profileS3UrlData.previewUrl;
						}
					}

					// Loop over thumbnailMediaInfo for each key
					for (const thumbnailMedia of thumbnailMediaInfo) {
						if (
							thumbnailMedia.key === key &&
							thumbnailMedia.fileUploaderType === 'thumbnail' &&
							values.value[key].thumbnailUrl !== thumbnailS3Url
						) {
							const thumbnailS3UrlData = await handleUploadAssets(thumbnailMedia);
							thumbnailS3Url = thumbnailS3UrlData.previewUrl;
						}
					}

					updatedValues.value[key] = {
						userName: item.userName,
						userProfile: profileS3Url ?? '',
						thumbnailUrl: thumbnailS3Url ?? '',
						videoUrl: videoS3Url ?? ''
					};
				});

				await Promise.all(uploadPromises); // Wait for all uploads to complete

				const params = {
					key: ONBOARD_API_KEY,
					...updatedValues
				};

				if (!onboardUserDetail) {
					await httpService.post(`${API_CONFIG.path.onboarding}`, params);
				} else {
					await httpService.put(`${API_CONFIG.path.onboarding}/on_boarding_tutorial`, updatedValues);
				}
				fetchUsersDetails(); // Call after successful request
			} catch (error) {
				setIsLoading(false);
				console.error('error', error);
			}
		},
		[onboardUserDetail, bgMediaInfo, handleUploadAssets, previewMediaInfo, thumbnailMediaInfo]
	);

	useEffect(() => {
		fetchUsersDetails();
	}, []);

	return (
		<div className='onboarding-wrapper'>
			<Formik
				initialValues={onboardUserDetail || initialValues}
				onSubmit={(values: IOnboardUserDetail) => handleSubmit(values)}
				validationSchema={getAddOnboardingValidationSchema}
				validateOnChange={true}
				validateOnBlur={true}
				validateOnMount={true}
				enableReinitialize
			>
				{({ handleSubmit, setFieldValue, setFieldError, values }) => (
					<OnboardingForm
						handleChange={handleChange}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						values={values}
						isLoading={isLoading}
						handleSubmit={handleSubmit}
						uploadingStates={uploadingStates}
						isUpdate={onboardUserDetail ? true : false}
					/>
				)}
			</Formik>
		</div>
	);
};

export default Onboarding;
