import { Search } from 'shared/icons/icons';

interface ISearch {
	handleSearch: (value: string) => void;
	value?: string;
}

const SearchComponent = (props: ISearch) => {
	return (
		<>
			<Search />
			<input
				type='text'
				className=''
				placeholder='Search'
				value={props.value}
				onChange={(e) => {
					props.handleSearch(e.target.value);
				}}
			/>
		</>
	);
};

export default SearchComponent;
