import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ILoginResponse, IUserLoginData } from '../interface/auth';
import * as actionTypes from 'store/action-types';
import LoginWrapper from './loginWrapper';

import { createAction } from 'shared/util/utility';
import AuthService from 'shared/services/auth.service';
import { CustomForm } from 'shared/components/form/form';
import * as schema from 'shared/constants/validation-schema';
import { IFieldConfig } from 'shared/components/form/inputTypes';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

const LoginComponent: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const onLogin = (data: IUserLoginData) => {
		setLoading(true);
		httpService
			.post(
				API_CONFIG.path.login,
				data,
				{},
				{
					isAccessTokenRequire: false
				}
			)
			.then((response: ILoginResponse) => {
				AuthService.setAuthData(response);
				AuthService.setUserData(response.user);
				dispatch(createAction(actionTypes.AUTH_SUCCESS, response.user));
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<LoginWrapper>
			<CustomForm
				showLabels
				title={'Welcome to Viral Stage!!'}
				formClassName='m-t'
				schema={schema.loginFormValidationSchema}
				onSubmit={onLogin}
				loading={loading}
				fieldConfig={fieldConfig}
				submitBtnText={'Login'}
				submitBtnClassName='block full-width m-b'
			/>
		</LoginWrapper>
	);
};

const fieldConfig: IFieldConfig[] = [
	{ type: 'email', label: 'email', name: 'email' },
	{ type: 'password', label: 'password', name: 'password' }
];

export default LoginComponent;
