export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const NO_ACTION = 'NO_ACTION';
export const RESET_MODAL_STATUS = 'RESET_MODAL_STATUS';
export const RESET_MESSAGE = 'RESET_MESSAGE';
