import { useState } from 'react';
import toast from 'react-hot-toast';
import { Formik } from 'formik';

import { IChangePassword } from '../interface/auth';

import { challengeError } from 'features/challenges/utility/challengeConstants';

import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import { passwordChangeValidationSchema } from 'shared/constants/validation-schema';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { Input } from 'shared/components/form/inputTypes';

interface IProps {
	show: boolean;
	handleClose: () => void;
}

const PasswordChangeModal: React.FC<IProps> = ({ show, handleClose }) => {
	const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

	const changePassword = (data: IChangePassword) => {
		setIsChangingPassword(true);
		return new Promise((resolve, reject) => {
			httpService
				.put(`${API_CONFIG.path.passwordChange}`, data)
				.then(() => {
					setIsChangingPassword(false);
					handleClose();
					resolve(true);
				})
				.catch((error) => {
					setIsChangingPassword(false);
					reject(error);
				});
		});
	};

	const handleSubmit = (formData) => {
		toast.promise(changePassword(formData), {
			loading: 'Changing Password...',
			success: <b>Password Changed Successfully.</b>,
			error: <b>Could not changed password.</b>
		});
	};

	return (
		<CustomModal show={show} handleClose={handleClose} className={'view-challenge-modal'}>
			<h1>Password Change</h1>
			<div className='view-challenge-modal-wrapper'>
				<Formik
					initialValues={{ oldPassword: '', newPassword: '' }}
					onSubmit={handleSubmit}
					enableReinitialize
					validationSchema={passwordChangeValidationSchema}
					validateOnChange={false}
					validateOnBlur={false}
					validateOnMount={false}
				>
					{({ setFieldValue, handleSubmit, values, errors }) => {
						return (
							<form onSubmit={handleSubmit}>
								<div className='add-challenge-modal-wrapper'>
									<div className='form-group'>
										<Input
											type='text'
											name='oldPassword'
											placeholder='Start typing...'
											setFieldValue={setFieldValue}
											showLabels
											className='full-width input-field'
											config={{
												name: 'oldPassword',
												type: 'text',
												label: 'Old Password'
											}}
										/>
										{!values.oldPassword &&
											challengeError(errors, 'oldPassword', 'Old Password  is required.')}
									</div>
									<div className='form-group'>
										<Input
											type='text'
											name='newPassword'
											placeholder='Start typing...'
											setFieldValue={setFieldValue}
											showLabels
											className='full-width input-field'
											config={{
												name: 'newPassword',
												type: 'text',
												label: 'New Password'
											}}
										/>
										{!values.newPassword &&
											challengeError(errors, 'newPassword', 'New Password  is required.')}
									</div>
									<div className='d-flex mt-12'>
										<Button
											className='line-height--20px mt-12 mr-13 height--40px width-214px'
											onClick={handleClose}
										>
											Cancel
										</Button>
										<Button
											btnType='primary'
											type='submit'
											disabled={isChangingPassword}
											className='line-height--20px mt-12 height--40px width-214px'
											onClick={handleSubmit}
										>
											Update
										</Button>
									</div>
								</div>
							</form>
						);
					}}
				</Formik>
			</div>
		</CustomModal>
	);
};
export default PasswordChangeModal;
