import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Collapse from 'react-collapse';

import localizationConstants from 'shared/util/translation/constants';
import { ISideBarMenu } from 'features/auth/interface/auth';

interface IOwnProps {
	renderSideNav: () => void;
	menu: ISideBarMenu;
	isOpen: boolean;
	toggleIsOpen: (name: string) => void;
	hideSidebar: () => void;
}

/**
 * getPathElements - split any given navigation path at every `/` and return the array
 */
const getPathElements = (path: string) => {
	return path.split('/').filter((str) => str !== '');
};

const getActiveClass = (currentPathStr: string, actualPathStr: string): string => {
	// for base path, it's compulsary to have both path as `/`, in order to show `active` status
	if (actualPathStr === '/') {
		if (currentPathStr === actualPathStr) {
			return 'active';
		}
		return '';
	}
	const actualPath = getPathElements(actualPathStr);
	const currentPath = getPathElements(currentPathStr);
	// if the first level path matches for actual path, and current path, return `active`
	if (actualPath[0] === currentPath[0]) {
		return 'active';
	}
	return '';
};

const getSubActiveClass = (currentPathStr: string, actualPathStr: string): string => {
	const actualPath = getPathElements(actualPathStr);
	const currentPath = getPathElements(currentPathStr);
	// for sub menu to be active, first and second level path must match
	if (actualPath[0] === currentPath[0] && actualPath[1] === currentPath[1]) {
		return 'active';
	}
	return '';
};

const icons: { [key: string]: string } = {
	[localizationConstants.users]: 'bx-user',
	[localizationConstants.dashboardTitle]: 'bxs-dashboard',
	[localizationConstants.permissions]: 'key',
	[localizationConstants.role]: 'bxs-id-card',
	[localizationConstants.tools]: 'bx-cog'
};

const NavItem: React.FC<IOwnProps> = ({ menu, renderSideNav, isOpen, toggleIsOpen, hideSidebar }) => {
	const { name, children, permissionName, reactRoute, icon } = menu;
	const location = useLocation();
	const activeInitialPath = location.pathname === '/' ? '/challenges' : location.pathname;
	const className = getActiveClass(activeInitialPath, reactRoute);

	const handleNavClick = () => {
		renderSideNav();
		toggleIsOpen(name);
		if (!children) {
			hideSidebar();
		}
	};
	return children ? (
		<li className={className} onClick={handleNavClick}>
			<Link to={location.pathname}>
				<i className={`bx ${icons[permissionName]}`} />
				<span className='nav-label'>{name}</span>
				<span className='float-right'>
					<i className={`${!!className || isOpen ? 'bx bxs-chevron-down' : 'bx bxs-chevron-right'}`} />
				</span>
			</Link>

			<div className='mini-navbar-links-wrapper'>
				<ul className='nav nav-second-level'>
					{children.map((child) => (
						<li key={child.url} className={getSubActiveClass(location.pathname, child.url)}>
							<Link to={child.url} className='nav-label'>
								{child.name}
							</Link>
						</li>
					))}
				</ul>
			</div>

			<Collapse.Collapse
				className={`${className === 'active' && 'collapse-active'}`}
				isOpened={!!className || isOpen}
			>
				<ul className='nav nav-second-level'>
					{children.map((child) => (
						<li key={child.url} className={getSubActiveClass(location.pathname, child.url)}>
							<Link to={child.url} className='nav-label'>
								{child.name}
							</Link>
						</li>
					))}
				</ul>
			</Collapse.Collapse>
		</li>
	) : (
		<li className={className} onClick={handleNavClick} key={location.pathname}>
			<Link to={reactRoute}>
				{icon}
				<span className='nav-label'>{name}</span>
			</Link>
		</li>
	);
};

export default NavItem;
