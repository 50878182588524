import * as Yup from 'yup';
import { errorMessages } from 'shared/constants/messages';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { URL_REGEX } from './constants';

const getSchema = (schemaConfig: any): any => {
	return Yup.object().shape(schemaConfig);
};
const getLoginFormValidationSchema = () =>
	Yup.object().shape({
		email: Yup.string().email().required(errorMessages.required(localizationConstants.email)).strict(true),
		password: Yup.string().required(errorMessages.required(localizationConstants.password)).strict(true)
	});

const getForgetPasswordValidationSchema = () =>
	Yup.object().shape({
		email: Yup.string().email().required(errorMessages.required(localizationConstants.email)).strict(true)
	});

let loginFormValidationSchema = getLoginFormValidationSchema();

let forgetPasswordValidationSchema = getForgetPasswordValidationSchema();

const getAddChallengesValidationSchema = () => {
	return Yup.object().shape({
		title: Yup.string()
			.test('len', 'Title must be less or exact 40 characters', (val = '') => val.length <= 40)
			.required('Category title is required.')
			.strict(true),
		category: Yup.string().required('Category is required.').strict(true),
		challengeRegStartAt: Yup.string().required('Start Date is Required.').strict(false),
		challengeRegEndAt: Yup.string()
			.required('End Date is Required.')
			.test('is-greater', 'Enter valid End date', function (value) {
				const { challengeRegStartAt } = this.parent;

				// Check if both dates are valid before comparing
				const subStart = new Date(challengeRegStartAt);
				const subEnd = new Date(value);

				return subStart < subEnd;
			})
			.strict(false),
		challengeVoteStartAt: Yup.string()
			.required('Voting Start Date is Required.')
			.test('is-less-or-equal', 'Enter valid Voting Start date', function (value) {
				const { challengeRegEndAt, challengeRegStartAt } = this.parent;

				// Check if both dates are valid before comparing
				const subEnd = new Date(challengeRegEndAt);
				const subStart = new Date(challengeRegStartAt);
				const voteStartDate = new Date(value);

				return subEnd >= voteStartDate && subStart < voteStartDate;
			})
			.strict(false),
		challengeVoteEndAt: Yup.string()
			.required('Voting End Date is Required.')
			.test('is-greater', 'Enter valid Voting End date', function (value) {
				const { challengeVoteStartAt, challengeRegEndAt } = this.parent;

				// Check if both dates are valid before comparing
				const voteStartDate = new Date(challengeVoteStartAt);
				const subEnd = new Date(challengeRegEndAt);
				const voteEndDate = new Date(value);

				return voteEndDate > voteStartDate && voteEndDate >= subEnd;
			})
			.strict(false),
		description: Yup.string()
			.test('len', 'Description must be less or exact 100 characters', (val = '') => val.length <= 100)
			.required('Description is Required.')
			.strict(true),
		minAge: Yup.string()
			.required('Min Age is required.')
			.test(' ', 'Min Age is 13.', (val) => Number(val) >= 13)
			.strict(true),
		previewUrl: Yup.string().required('Prize Image is Required.').strict(true),
		prize_name: Yup.string().required('Prize Name is Required.').strict(true)
	});
};

const getAddSingleCampaignValidationSchema = () => {
	return Yup.object().shape({
		title: Yup.string().required(errorMessages.required).strict(true),
		websiteUrl: Yup.string()
			.matches(URL_REGEX, 'Please enter valid website url')
			.required(errorMessages.required)
			.strict(true),
		thumbnailUrl: Yup.string().required(errorMessages.required).strict(true),
		videoUrl: Yup.string().required(errorMessages.required).strict(true),
		iconUrl: Yup.string().required(errorMessages.required).strict(true)
	});
};

const getAddBattleCampaignValidationSchema = () => {
	return Yup.object().shape({
		title: Yup.string().required(errorMessages.required).strict(true),
		advertisementId1: Yup.string().required(errorMessages.required).strict(true),
		advertisementId2: Yup.string().required(errorMessages.required).strict(true)
	});
};

let addChallengesValidationSchema = getAddChallengesValidationSchema();

i18n.on('languageChanged', () => {
	loginFormValidationSchema = getLoginFormValidationSchema();
	forgetPasswordValidationSchema = getForgetPasswordValidationSchema();
	addChallengesValidationSchema = getAddChallengesValidationSchema();
	editChallengesValidationSchema = getEditChallengesValidationSchema();
});

const getEditChallengesValidationSchema = () => {
	return Yup.object().shape({
		title: Yup.string().required('Category title is required.').strict(true),
		category: Yup.string().required('Category is required.').strict(true),
		description: Yup.string()
			.test('len', 'Must be less or exact 100 characters', (val = '') => val.length <= 100)
			.required('Description is Required.')
			.strict(true),
		minAge: Yup.string()
			.required('Min Age is required.')
			.test(' ', 'Min Age is 13.', (val) => Number(val) >= 13)
			.strict(true),
		prize_name: Yup.string().required('Prize Name is Required.').strict(true)
	});
};

let editChallengesValidationSchema = getEditChallengesValidationSchema();

const passwordChangeValidationSchema = () => {
	return Yup.object().shape({
		oldPassword: Yup.string().required(errorMessages.required).strict(true),
		newPassword: Yup.string().required(errorMessages.required).strict(true)
	});
};

const getAddOnboardingValidationSchema = Yup.object().shape({
	value: Yup.object().shape({
		userVideo1: Yup.object().shape({
			userName: Yup.string().required('User Name is required'),
			userProfile: Yup.string().required('User Profile is required'),
			thumbnailUrl: Yup.string().required('User Thumbnail is required'),
			videoUrl: Yup.string().required('Video URL is required')
		}),
		userVideo2: Yup.object().shape({
			userName: Yup.string().required('User Name is required'),
			userProfile: Yup.string().required('User Profile is required'),
			thumbnailUrl: Yup.string().required('User Thumbnail is required'),
			videoUrl: Yup.string().required('Video URL is required')
		})
	})
});

export {
	loginFormValidationSchema,
	forgetPasswordValidationSchema,
	getSchema,
	addChallengesValidationSchema,
	getAddSingleCampaignValidationSchema,
	getAddBattleCampaignValidationSchema,
	editChallengesValidationSchema,
	passwordChangeValidationSchema,
	getAddOnboardingValidationSchema
};
