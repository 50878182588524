import React, { useCallback, useState } from 'react';

import ViralUniversalModal, { IViralUniversalModalProps } from 'shared/components/modal/viralUniversalModal';
import SlickModalSLider from 'shared/components/slider/slickModal';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

import { IAllVideoReportInfo, IVideoReportDetail, UserType } from '../interface/userManagement';

export interface IUserManagementModalProps extends IViralUniversalModalProps {
	data: IAllVideoReportInfo[];
	currentClickedVideoIndex: number;
	reportActionFlag: boolean;
	videoModalType?: UserType;
	handleReportData?: (index: number) => void;
}

const UserManagementModal: React.FC<IUserManagementModalProps> = (props) => {
	const { data, currentClickedVideoIndex, videoModalType, handleReportData, reportActionFlag } = props;

	const [isGettingVideoReportDetails, setIsGettingVideoReportDetails] = useState(false);
	const [isGettingAllVideoDetails, setIsGettingAllVideoDetails] = useState(false);
	const [videoReportsDetails, setVideoReportsDetails] = useState<IVideoReportDetail>();
	const [allVideosDetails, setAllVideosDetails] = useState<IVideoReportDetail>();

	const handleVideoStatus = useCallback((id: string, status: string, setReportStatus: () => void, index: number) => {
		const detailUrl = `${API_CONFIG.path.report}/${id}`;
		httpService
			.put(detailUrl, {
				status
			})
			.then(() => {
				setIsGettingVideoReportDetails(false);
				setReportStatus();
				handleReportData(index);
			})
			.catch((error) => {
				console.error('error', error);
				setIsGettingVideoReportDetails(false);
				setReportStatus();
			});
	}, []);

	// User Video Reports Details
	const getVideoReportDetails = useCallback((id: string) => {
		const detailUrl = `${API_CONFIG.path.user}/${id}/${API_CONFIG.path.videoReportDetail}`;
		setIsGettingVideoReportDetails(true);
		httpService
			.get(detailUrl)
			.then((response) => {
				setIsGettingVideoReportDetails(false);
				setVideoReportsDetails(response);
			})
			.catch((error) => {
				console.log('error : ', error);
				setIsGettingVideoReportDetails(false);
			});
	}, []);

	// User All Videos Details
	const getAllVideoDetails = useCallback((videoId: string) => {
		const detailUrl = `${API_CONFIG.path.user}/${videoId}/${API_CONFIG.path.allVideosDetail}`;
		setIsGettingAllVideoDetails(true);
		httpService
			.get(detailUrl)
			.then((response) => {
				const modifiedData: any = {
					category: undefined,
					challenge: response.challenge,
					id: response.id,
					description: undefined,
					user: undefined,
					video: {
						id: response.id,
						userId: response.userId,
						videoUrl: response.videoUrl,
						thumbnailUrl: response.thumbnailUrl,
						streamingUrl: response.streamingUrl,
						videoFileName: response.videoFileName,
						hashtags: response.hashtags,
						totalVotes: response.totalVotes,
						totalLikes: response.totalLikes,
						totalSaveVideo: response.totalSaveVideo,
						totalShares: response.totalShares,
						totalViews: response.totalViews,
						rank: response.rank,
						leftThumbnailUrl: response.leftThumbnailUrl,
						rightThumbnailUrl: response.rightThumbnailUrl,
						isShareOnlyFriend: response.isShareOnlyFriend
					}
				};
				setAllVideosDetails(modifiedData);
				setIsGettingAllVideoDetails(false);
			})
			.catch((error) => {
				console.log('error : ', error);
				setIsGettingAllVideoDetails(false);
			});
	}, []);

	return (
		<ViralUniversalModal
			show={props.show}
			handleClose={props.handleClose}
			className={data.length > 0 ? props.className : 'no-report-wrapper'}
		>
			{data.length > 0 ? (
				<SlickModalSLider
					data={data}
					detailsData={videoModalType == 'all' ? allVideosDetails : videoReportsDetails}
					handleDetail={videoModalType == 'all' ? getAllVideoDetails : getVideoReportDetails}
					handleVideoStatus={handleVideoStatus}
					currentClickedVideoIndex={currentClickedVideoIndex}
					videoModalType={videoModalType}
					loading={videoModalType == 'all' ? isGettingAllVideoDetails : isGettingVideoReportDetails}
					handleClose={props.handleClose}
					reportActionFlag={reportActionFlag}
				/>
			) : (
				<div>
					<p className='no-report-text'>This user didn't got any reports about yet.</p>
				</div>
			)}
		</ViralUniversalModal>
	);
};

export default UserManagementModal;
