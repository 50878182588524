import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LoginComponent from 'features/auth/container/login';

import Layout from 'shared/hoc/layout/component/layout';
import NotFound from 'shared/components/404/404';
import { IState } from 'shared/interface';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.min.css';

import 'assets/styles/index.scss';
import UserManagement from 'features/userManagement/container/userManagement';
import Challenges from 'features/challenges/container/challenges';
import ComingSoon from 'shared/components/comingSoon/comingSoon';
import Reports from 'features/reports/container/reports';
import Campaigns from 'features/campaigns/container/campaigns';
import { UserProfileDetails } from 'features/userManagement/components/userProfileDetails';
import Music from 'features/music/container/music';
import Dashboard from 'features/dashboard/container/dashboard';
import CsvComponent from 'features/csv/csvComponent';
import Onboarding from 'features/onboarding/container/onboarding';

/*
 * import async components in order to lazy load the containers, which will be rendered conditionally
 * based on the permissions user is having
 */

const App: React.FC = () => {
	const isLogin = useSelector((state: IState) => state.auth.isLogin);

	if (isLogin) {
		return (
			<Layout>
				<Routes>
					<Route path='/' element={<Challenges />} />
					<Route path='/dashboard' element={<Dashboard />} />
					<Route path='/company' element={<ComingSoon />} />
					<Route path='/products' element={<ComingSoon />} />
					<Route path='/campaigns' element={<Campaigns />} />
					<Route path='/insight' element={<ComingSoon />} />
					<Route path='/partnership' element={<ComingSoon />} />
					<Route path='/partnership' element={<ComingSoon />} />
					<Route path='/user-management' element={<UserManagement />}>
						<Route path=':userId' element={<UserProfileDetails />} />
					</Route>
					<Route path='/challenges' element={<Challenges />} />
					<Route path='/reports' element={<Reports />} />
					<Route path='/music-management' element={<Music />} />
					<Route path='/csv-management' element={<CsvComponent />} />
					<Route path='/onboarding' element={<Onboarding />} />
					<Route path='/404' element={<NotFound />} />
					<Route path='*' element={<Navigate replace to='/' />} />
				</Routes>
			</Layout>
		);
	} else {
		// lazy loading not needed for login, forget password, reset password APIs
		return (
			<Routes>
				<Route path='/login' element={<LoginComponent />} />
				<Route path='*' element={<Navigate replace to='/login' />} />
			</Routes>
		);
	}
};

export default App;
