import React from 'react';

import './spinner.scss';
interface ILoaderProps2 {
	className?: string;
}

const Loader: React.FC<ILoaderProps2> = ({ className }) => {
	return (
		<div className={`loader-wrap ${className ?? ''}`}>
			<span className='loader' />
		</div>
	);
};

export { Loader };
