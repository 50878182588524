import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../spinner/spinner';

interface IProps extends React.PropsWithChildren {
	fetchData: () => void;
	hasMore: boolean;
	total: number;
	endMessage?: string;
	height?: string;
	isSpinner?: boolean;
	className?: string;
}

const InfiniteScrollComponent: React.FC<IProps> = (props) => {
	const { fetchData, hasMore, children, endMessage, total, height, isSpinner, className } = props;

	return (
		<InfiniteScroll
			dataLength={total}
			hasMore={hasMore}
			next={fetchData}
			loader={isSpinner && <Loader className='infinite-scroll-loader' />}
			endMessage={
				<p className='text--primary mt--15 text--center font--bold user-list-infinity-scroll '>
					{endMessage ?? <></>}
				</p>
			}
			height={height || '100%'}
			className={`scrollbar--hidden ${className}`}
			scrollThreshold={1}
		>
			{children}
		</InfiniteScroll>
	);
};

export default InfiniteScrollComponent;
