const musicTabs = [
	{ name: 'Library', value: 'library' },
	{ name: 'UGC', value: 'ugc' }
];

const OrderColumn = {
	LINK_OUTS: 'linkOuts',
	TOTAL_PLAY: 'totalPlay',
	TOTAL_USED: 'totalUsed',
	DURATION: 'duration'
};

export { musicTabs, OrderColumn };
