import moment from 'moment';
import { holidays, maxTime, minTime } from './challengeConstants';

// this limits the timeline to -6 months ... +6 months
export const handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
	if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
		updateScrollCanvas(minTime, maxTime);
	} else if (visibleTimeStart < minTime) {
		updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
	} else if (visibleTimeEnd > maxTime) {
		updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
	} else {
		updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
	}
};

export const moveResizeValidator = (time) => {
	if (time < new Date().getTime()) {
		const newTime = Math.ceil(new Date().getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000);
		return newTime;
	}

	return time;
};

export const getMinutesOfDay = (date) => {
	return date.hours() * 60 + date.minutes();
};

export const verticalLineClassNamesForTime = (timeStart, timeEnd) => {
	const currentTimeStart = moment(timeStart);
	const currentTimeEnd = moment(timeEnd);

	const classes = [];

	// check for public holidays
	for (const holiday of holidays) {
		if (holiday.isSame(currentTimeStart, 'day') && holiday.isSame(currentTimeEnd, 'day')) {
			classes.push('holiday');
		}
	}

	const today = moment();
	if (today.isSame(timeStart, 'day')) {
		classes.push('today');
	}
	// highlight lunch break (12:00-13:00)
	const lunchStart = moment().hours(12).minutes(0).seconds(0);
	const lunchEnd = moment().hours(13).minutes(0).seconds(0);
	if (
		getMinutesOfDay(currentTimeStart) >= getMinutesOfDay(lunchStart) &&
		getMinutesOfDay(currentTimeEnd) <= getMinutesOfDay(lunchEnd)
	) {
		classes.push('lunch');
	}

	return classes;
};
