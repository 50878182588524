import { FC, useCallback, useState } from 'react';
import { Formik, FormikState, FormikValues } from 'formik';
import { FileUploader } from 'react-drag-drop-files';

import { ICampaignPost } from '../interface/campaigns';

import { challengeError } from 'features/challenges/utility/challengeConstants';

import CustomModal from 'shared/components/modal/modal';
import { Input } from 'shared/components/form/inputTypes';
import Button from 'shared/components/form/button';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { getAddSingleCampaignValidationSchema } from 'shared/constants/validation-schema';
import { formatFileName } from 'shared/util/utility';
import { fileTypes, videoFileTypes } from 'shared/constants/constants';

interface IProps {
	handleClose: () => void;
	handleSwitchTab: (selectedTab: string) => void;
	handleUpdateList: () => void;
}

const initialValues = {
	title: '',
	websiteUrl: '',
	thumbnailUrl: '',
	videoUrl: '',
	iconUrl: ''
};
const SingleCampaignsModal: FC<IProps> = (props) => {
	const { handleClose, handleSwitchTab, handleUpdateList } = props;

	const [thumbnailUrl, setThumbnailUrl] = useState('');
	const [iconUrl, setIconUrl] = useState('');
	const [videoUrl, setVideoUrl] = useState('');

	const [thumbnailDetails, setThumbnailDetails] = useState<File>();
	const [iconDetails, setIconDetails] = useState<File>();
	const [videoDetails, setVideoDetails] = useState<File>();

	const [uploading, setUploading] = useState(false);
	const [isCampaignsLoader, setIsCampaignsLoader] = useState<boolean>(false);

	// Add campaign
	const addSingleCampaigns = useCallback(
		(id: string, params: ICampaignPost, resetForm: (nextState?: Partial<FormikState<FormikValues>>) => void) => {
			setIsCampaignsLoader(true);
			httpService
				.put(`${API_CONFIG.path.campaigns}/${id}`, params)
				.then(() => {
					setIsCampaignsLoader(false);
					handleClose();
					handleSwitchTab('single');
					handleUpdateList();
					resetForm();
					setUploading(false);
				})
				.catch((error) => {
					console.error('error', error);
					setIsCampaignsLoader(false);
					handleClose();
					setUploading(false);
				});
		},
		[]
	);

	const handleChange = (
		file: File,
		setFieldValue: (name: string, value: string) => void,
		fileUploaderType: string
	) => {
		const reader: any = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function () {
			if (fileUploaderType == 'onlyImage') {
				setThumbnailUrl(reader.result);
				setFieldValue('thumbnailUrl', reader.result);
				setThumbnailDetails(file);
			}
			if (fileUploaderType == 'onlyIcon') {
				setIconUrl(reader.result);
				setFieldValue('iconUrl', reader.result);
				setIconDetails(file);
			}
			if (fileUploaderType == 'onlyVideo') {
				setVideoUrl(reader.result);
				setFieldValue('videoUrl', reader.result);
				setVideoDetails(file);
			}
		}.bind(this);
	};

	const getCredentialsAndUploadFile = (videoFileName: string, formData, resetForm) => {
		httpService
			.post(
				API_CONFIG.path.campaignsUpload,
				{ fileName: formatFileName(videoFileName) },
				{ contentType: 'multipart/form-data' }
			)
			.then((res) => {
				const { id, icon, video, thumbnail } = res;
				const assetsData = {
					iconUrl: {
						s3UploadDetails: icon,
						fileDetails: iconDetails
					},
					videoUrl: {
						s3UploadDetails: video,
						fileDetails: videoDetails
					},
					thumbnailUrl: {
						s3UploadDetails: thumbnail,
						fileDetails: thumbnailDetails
					}
				};

				getS3Details(id, assetsData, formData, resetForm);
			})
			.catch((err) => {
				console.error('Error : ', err);
				setUploading(false);
			});
	};

	const getS3Details = async (
		id: string,
		assetsData: Record<string, any>,
		formData: ICampaignPost,
		resetForm: (nextState?: Partial<FormikState<FormikValues>>) => void
	) => {
		const assetsS3Urls = {
			iconUrl: '',
			videoUrl: '',
			thumbnailUrl: ''
		};
		for (const assetsUrl in assetsData) {
			const { s3UploadDetails, fileDetails } = assetsData[assetsUrl];
			const previewS3Data = await uploadFileToS3(s3UploadDetails, fileDetails);
			assetsS3Urls[assetsUrl] = previewS3Data;
		}
		const modifiedData = {
			...formData,
			type: 'challenge',
			...assetsS3Urls
		};
		addSingleCampaigns(id, modifiedData, () => handleClearFormData(resetForm));
	};

	const uploadFileToS3 = (presignedData: any, file: File) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			Object.keys(presignedData.fields).forEach((key) => {
				formData.append(key, presignedData.fields[key]);
			});
			formData.append('file', file);
			const xhr = new XMLHttpRequest();
			xhr.onload = function () {
				this.status === 204 ? resolve(presignedData.previewUrl) : reject(this.responseText);
			};
			xhr.open('POST', presignedData.uploadUrl, true);
			xhr.send(formData);
		});
	};

	const handleSubmit = (
		formData: ICampaignPost,
		resetForm: (nextState?: Partial<FormikState<FormikValues>>) => void
	) => {
		setUploading(true);
		getCredentialsAndUploadFile(videoDetails.name, formData, resetForm);
	};

	const handleClearFormData = (resetForm?: (nextState?: Partial<FormikState<FormikValues>>) => void) => {
		setVideoUrl('');
		setThumbnailUrl('');
		setIconUrl('');
		resetForm && resetForm();
	};

	return (
		<CustomModal
			show={true}
			handleClose={() => {
				handleClose();
				handleClearFormData();
			}}
			closeOnOutSideClick
			className='add-challenge-modal'
		>
			<Formik
				initialValues={initialValues}
				onSubmit={(values: ICampaignPost, { resetForm }) => {
					handleSubmit(values, resetForm);
				}}
				validationSchema={getAddSingleCampaignValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
				validateOnMount={false}
				enableReinitialize
			>
				{({ handleSubmit, setFieldValue, errors, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<div className='form-group '>
								<Input
									type='text'
									name='title'
									placeholder='Start typing...'
									setFieldValue={setFieldValue}
									showLabels
									className='full-width input-field'
									config={{
										name: 'title',
										type: 'text',
										label: 'Title'
									}}
								/>
								{!values.title && challengeError(errors, 'title', 'Title  is required.')}
							</div>
							<div className='form-group '>
								<Input
									type='text'
									name='websiteUrl'
									placeholder='Start typing...'
									setFieldValue={setFieldValue}
									showLabels
									className='full-width input-field'
									config={{
										name: 'websiteUrl',
										type: 'text',
										label: 'Website Url'
									}}
								/>
								{!values.websiteUrl &&
									challengeError(errors, 'websiteUrl', 'Website URL  is required.')}
							</div>
							<div className='prize-wrapper'>
								<h5 className='prize-text'>Advertisement Video</h5>
							</div>
							<div className='common-file-upload-wrapper'>
								<FileUploader
									handleChange={(file: File) => {
										setVideoUrl('');
										handleChange(file, setFieldValue, 'onlyVideo');
									}}
									name='videoUrl'
									types={videoFileTypes}
									disabled={uploading}
								>
									{videoUrl ? (
										<video width='100%' height='240' controls>
											<source src={videoUrl} type='video/mp4' />
										</video>
									) : (
										<div className='file-upload-wrapper'>
											<h4 className='upload-photo-text'>Drag & Drop video here</h4>
											<p className='photo-size-text'>Supported format: MP4 or Webm.</p>
											<span className='file-upload'>or</span>
											<div className='browse-button-wrapper'>
												<Button
													btnType='default'
													disabled={isCampaignsLoader || uploading}
													className='browse-button'
												>
													Browse files
												</Button>
											</div>
										</div>
									)}
								</FileUploader>
								{!values.videoUrl && challengeError(errors, 'videoUrl', 'Video is Required.')}
							</div>
							<div className='prize-wrapper'>
								<h5 className='prize-text'>Advertisement Image</h5>
							</div>
							<div className='common-file-upload-wrapper'>
								<FileUploader
									handleChange={(file: File) => handleChange(file, setFieldValue, 'onlyImage')}
									name='thumbnailUrl'
									types={fileTypes}
									disabled={uploading}
								>
									{thumbnailUrl ? (
										<img src={thumbnailUrl} alt='file upload image' className='file-image' />
									) : (
										<div className='file-upload-wrapper'>
											<h4 className='upload-photo-text'>Drag & Drop photo here</h4>
											<p className='photo-size-text'>Supported format: JPG, JPEG, GIF or PNG.</p>
											<span className='file-upload'>or</span>
											<div className='browse-button-wrapper'>
												<Button
													btnType='default'
													disabled={isCampaignsLoader || uploading}
													className='browse-button'
												>
													Browse files
												</Button>
											</div>
										</div>
									)}
								</FileUploader>
							</div>
							{!values.thumbnailUrl && challengeError(errors, 'thumbnailUrl', 'Image is Required.')}
							<div className='prize-wrapper'>
								<h5 className='prize-text'>Advertisement Icon</h5>
							</div>
							<div className='common-file-upload-wrapper'>
								<FileUploader
									handleChange={(file: File) => handleChange(file, setFieldValue, 'onlyIcon')}
									name='iconUrl'
									types={fileTypes}
									disabled={uploading}
								>
									{iconUrl ? (
										<img src={iconUrl} alt='file upload image' className='file-image' />
									) : (
										<div className='file-upload-wrapper'>
											<h4 className='upload-photo-text'>Drag & Drop icon image here</h4>
											<p className='photo-size-text'>Supported format: JPG, JPEG, GIF or PNG.</p>
											<span className='file-upload'>or</span>
											<div className='browse-button-wrapper'>
												<Button
													btnType='default'
													disabled={isCampaignsLoader || uploading}
													className='browse-button'
												>
													Browse files
												</Button>
											</div>
										</div>
									)}
								</FileUploader>
								{!values.iconUrl && challengeError(errors, 'iconUrl', 'Icon Image is Required.')}
							</div>
							<div className='d-flex mt-12'>
								<Button
									className='line-height--20px mt-12 mr-13 height--40px width-214px'
									onClick={() => handleClose()}
								>
									Cancel
								</Button>
								<Button
									btnType='primary'
									type='submit'
									loading={isCampaignsLoader || uploading}
									className='line-height--20px mt-12 height--40px width-214px'
									onClick={handleSubmit}
								>
									Submit
								</Button>
							</div>
						</form>
					);
				}}
			</Formik>
		</CustomModal>
	);
};

export default SingleCampaignsModal;
