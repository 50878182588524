import { useCallback, useEffect, useState } from 'react';

import CustomDropDown from 'shared/components/form/customDropDown';
import ChallengesSlider from 'shared/components/slider/slickChallenge';
import { AddChallengesIcon } from 'shared/icons/icons';
import { IDropDownAndCheckboxOptions } from 'shared/components/form/inputTypes';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import Confirm from 'shared/components/confirm/confirm';
import { debounce } from 'shared/util/utility';
import SearchComponent from 'shared/components/search/search';
import { IPagination } from 'shared/components/pagination/pagination';
import { Loader } from 'shared/components/spinner/spinner';
import { colorStyles } from 'shared/components/form/reactSelectStyles';

import { IAllChallenges, ICategoryFilter, ModalTypes } from '../interface/challenges';
import { categoryStatusDropdownArray } from '../utility/challengeConstants';
import ViewChallengeModal from '../modals/viewChallengeModal';
import AddChallengeModal from '../modals/addChallengeModal';

interface IProps {
	categoryDropdownOption: IDropDownAndCheckboxOptions[];
	currentModalType: ModalTypes;
	currentChallengeId: string;
	isModalOpen: boolean;
	setCurrentModalType: (value: ModalTypes) => void;
	handleChallengeModal: (id: string, type: ModalTypes) => void;
	setIsModalOpen: (value: boolean) => void;
}

const ChallengeSlider = (props: IProps) => {
	const {
		currentModalType,
		categoryDropdownOption,
		currentChallengeId,
		isModalOpen,
		setIsModalOpen,
		setCurrentModalType,
		handleChallengeModal
	} = props;

	const [isGettingChallenge, setIsGettingChallenge] = useState<boolean>(true);
	const [challengeData, setChallengeData] = useState<IAllChallenges>({ data: [], total: 0 });
	const [challengeSearch, setChallengeSearch] = useState('');
	const [isDeleteChallenge, setIsDeleteChallenge] = useState<boolean>(false);
	const [challengePagination, setChallengeListPagination] = useState<IPagination>({} as IPagination);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [currentDeleteId, setCurrentDeleteId] = useState('');
	const [currentSelectedCategory, setCurrentSelectedCategory] = useState<ICategoryFilter>({} as ICategoryFilter);
	const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ICategoryFilter>({} as ICategoryFilter);
	const [challengeParams, setChallengeParams] = useState({
		isFromFilter: false,
		categoryId: '',
		activeState: ''
	});

	const fetchChallenges = useCallback(
		(
			categoryId?: string,
			status?: string,
			fromFilter = false,
			search = '',
			fromChallengeSearch = false,
			currentPage = 1
		) => {
			const params = {
				categoryId: !fromFilter
					? categoryId ||
					  (currentSelectedCategory.categoryType != 'all' ? currentSelectedCategory.categoryType : '')
					: categoryId,
				page: challengeData.data.length === 0 || fromFilter ? currentPage : 1,
				recordPerPage: 10,
				search,
				status: !fromFilter
					? status || (currentSelectedStatus.categoryType != 'all' ? currentSelectedStatus.categoryType : '')
					: status
			};
			httpService
				.get(`${API_CONFIG.path.challenge}`, params)
				.then((response) => {
					setChallengeListPagination(response);
					const showData = { ...response }.data || [];
					const appendedData =
						fromChallengeSearch && response.data.length > 0 && response.currentPage > 1
							? [...challengeData.data, ...showData]
							: [...showData];
					setChallengeData({ data: appendedData, total: appendedData.length });
					delete response.data;
					setIsGettingChallenge(false);
				})
				.catch((error) => {
					console.error('error', error);
					setIsGettingChallenge(false);
				});
		},
		[challengeData.data, challengePagination]
	);

	useEffect(() => {
		fetchChallenges(
			currentSelectedCategory.categoryType === 'all' ? undefined : currentSelectedCategory.categoryType,
			currentSelectedStatus.categoryType === 'all' ? undefined : currentSelectedStatus.categoryType,
			true,
			challengeSearch.trim(),
			true,
			challengePagination.currentPage
		);
	}, [challengeSearch, challengePagination.currentPage]);

	// Search Challenge
	const getSearchChallenge = (title: string) => {
		setChallengeSearch(title);
	};

	const handleSearch = debounce((value: string) => getSearchChallenge(value));

	const deleteChallenge = useCallback((id: string) => {
		setIsConfirmOpen(true);
		setIsDeleteChallenge(true);
		httpService
			.deleteRequest(`${API_CONFIG.path.challenge}/${id}`)
			.then(() => {
				setIsConfirmOpen(false);
				setIsDeleteChallenge(false);
				setCurrentDeleteId('');
			})
			.catch((error) => {
				setIsDeleteChallenge(false);
				console.error('error', error);
			});
	}, []);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleChallengeCategory = (categoryType: string, filterType: string) => {
		setCurrentSelectedCategory({ categoryType, filterType });
		fetchChallenges(
			categoryType === 'all' ? undefined : categoryType,
			currentSelectedStatus.categoryType === 'all' ? undefined : currentSelectedStatus.categoryType,
			true,
			challengeSearch.trim(),
			true
		);
	};

	const handleCategoryStatus = (categoryType: string, filterType: string) => {
		setCurrentSelectedStatus({ categoryType, filterType });
		setChallengeParams({ ...challengeParams, isFromFilter: true });
		fetchChallenges(
			currentSelectedCategory.categoryType === 'all' ? undefined : currentSelectedCategory.categoryType,
			categoryType === 'all' ? undefined : categoryType,
			true,
			challengeSearch.trim(),
			true
		);
	};

	const handleConfirmDelete = () => {
		if (currentDeleteId) {
			deleteChallenge(currentDeleteId);
		}
		const updatedData = challengeData.data.filter((item) => item.id !== currentDeleteId);
		setChallengeData({ data: updatedData, total: updatedData.length });
	};

	const handleDelete = (id: string) => {
		setCurrentDeleteId(id);
		setIsConfirmOpen(true);
	};

	const handleConfirmClose = () => {
		setIsConfirmOpen(false);
	};

	return (
		<div className='challenges-wrapper'>
			<div className='challenges-header-wrapper'>
				<h2 className='active-challenges'>
					{currentSelectedStatus.categoryType ? currentSelectedStatus.categoryType : 'All'} challenges
				</h2>
				<div className='category-status-wrapper'>
					<div className='challenge-search'>
						<SearchComponent handleSearch={handleSearch} />
					</div>
					<CustomDropDown
						handleDropdown={handleCategoryStatus}
						dropDownOptions={categoryStatusDropdownArray}
						filterType='status'
						className='status-dropdown'
						placeholder='All Status'
						customDropDownStyles={colorStyles}
					/>
					<CustomDropDown
						handleDropdown={handleChallengeCategory}
						dropDownOptions={categoryDropdownOption}
						filterType='challenges'
						className='category-dropdown'
						placeholder='All Category'
					/>
				</div>
			</div>
			<div className='slider-wrapper'>
				<div
					className='add-challenge-icon'
					onClick={() => {
						setIsModalOpen(true);
						setCurrentModalType('add');
					}}
				>
					<AddChallengesIcon />
				</div>
				{isGettingChallenge ? (
					<Loader className='custom-loader' />
				) : (
					<>
						{challengeData.data.length === 0 ? (
							<div className='user-profile-icon'>
								<p className='user-profile-text mr--80'>No Challenge Found</p>
							</div>
						) : (
							<ChallengesSlider
								data={challengeData.data}
								handleChallengeModal={handleChallengeModal}
								handleDelete={handleDelete}
								challengePagination={challengePagination}
								setPagination={setChallengeListPagination}
							/>
						)}
					</>
				)}
				{isConfirmOpen && (
					<Confirm
						loading={isDeleteChallenge}
						show={true}
						message='Are you sure want to delete this challenge?'
						handleConfirm={handleConfirmDelete}
						handleClose={handleConfirmClose}
						cancelBtnText='No'
						submitBtnText='Yes'
						className='viral-confirm-modal'
					/>
				)}
			</div>
			<AddChallengeModal
				show={isModalOpen}
				handleClose={handleCloseModal}
				listOfCategory={categoryDropdownOption}
				type={currentModalType}
				fetchChallenges={fetchChallenges}
				setIsModalOpen={setIsModalOpen}
				currentChallengeId={currentChallengeId}
				challengeData={challengeData}
				setChallengeData={setChallengeData}
				setCurrentModalType={setCurrentModalType}
			/>
			{currentModalType == 'view' && (
				<ViewChallengeModal
					show={isModalOpen}
					handleClose={handleCloseModal}
					currentChallengeId={currentChallengeId}
				/>
			)}
		</div>
	);
};

export default ChallengeSlider;
