import { createRef, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Slider, { CustomArrowProps } from 'react-slick';
import ReactHlsPlayer from 'react-hls-player';

import { ModalSliderIconRight, PlayIcon, PauseIcon } from 'shared/icons/icons';
import { BookmarkIcon, ShareIcon, LikesIcon } from 'shared/icons/icons';
import { numberFormat } from 'shared/helpers';
import userImage from 'assets/images/user-detail-pic.png';

import Button from '../form/button';

import { IAllVideoReportInfo, IVideoReportDetail } from 'features/userManagement/interface/userManagement';
import ChallengeCard from 'features/challenges/components/challengeCard';
import CustomModal from '../modal/modal';
import { Loader } from '../spinner/spinner';

interface ISliderProps {
	data: IAllVideoReportInfo[];
	detailsData: IVideoReportDetail;
	currentClickedVideoIndex: number;
	handleClose: () => void;
	handleVideoStatus: (id: string, status: string, reportStatus: () => void, index: number) => void;
	reportActionFlag: boolean;
	loading?: boolean;
	title?: string;
	className?: string;
	videoModalType?: string | 'about' | 'from' | 'all';
	handleDetail?: (id: string, index: number) => void;
}

const reportActionStatus = {
	blocked: 'Block',
	resolved: 'Resolve'
};

const SlickModalSlider = (props: ISliderProps) => {
	const {
		data,
		title,
		handleVideoStatus,
		detailsData,
		className,
		currentClickedVideoIndex,
		videoModalType,
		handleDetail,
		reportActionFlag,
		loading
	} = props;
	const playerRef = useRef<any>([]);
	const [index, setIndex] = useState(currentClickedVideoIndex);
	const [isPlaying, setIsPlaying] = useState(false);
	const [reportAction, setReportAction] = useState('');

	const location = useLocation();

	const modalSliderSettings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		arrows: true,
		draggable: true,
		onSwipe: (event) => ondrag(event),
		lazy: true
	};

	useEffect(() => {
		if (data && data[index]?.id != undefined) {
			handleDetail(data[index]?.id, index);
		}
	}, [index]);

	useEffect(() => {
		reportActionFlag && setIndex(index < data.length ? index + 1 : 0);
	}, [reportActionFlag]);

	const togglePlayback = () => {
		setIsPlaying((prevState) => !prevState);
	};

	const handleVideoEnded = () => {
		setIsPlaying(false);
	};

	function toggleControls() {
		playerRef.current.controls = !playerRef.current.controls;
	}

	const ondrag = (event) => {
		const leftScroll = event === 'left' && (index > 0 || index < data.length);
		setIndex(leftScroll ? index + 1 : index - 1);
	};

	const PrevArrow = ({ ...rest }: CustomArrowProps) => (
		<div
			{...rest}
			className={`video-report-modal-arrow left ${!modalSliderSettings.infinite && index < 1 ? 'disabled' : ''}`}
			onClick={(e) => {
				if (isPlaying) {
					setIsPlaying(false);
				}
				if (modalSliderSettings.infinite) {
					const tempindex = index === 0 ? data.length - 1 : index - 1;
					setIndex(tempindex);
				} else if (index > 0) {
					const tempIndex = index - 1;
					setIndex(tempIndex);
				}
			}}
		>
			<ModalSliderIconRight />
		</div>
	);

	const NextArrow = ({ ...rest }: CustomArrowProps) => {
		return (
			<div
				{...rest}
				className={`video-report-modal-arrow right ${
					!modalSliderSettings.infinite && index >= data.length - 1 ? 'disabled' : ''
				}`}
				onClick={() => {
					if (isPlaying) {
						setIsPlaying(false);
					}
					if (modalSliderSettings.infinite) {
						const tempIndex = index < data.length - 1 ? index + 1 : 0;
						setIndex(tempIndex);
					} else if (index < data.length - 1) {
						const tempIndex = index + 1;
						setIndex(tempIndex);
					}
				}}
			>
				<ModalSliderIconRight />
			</div>
		);
	};

	let settings = {
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />
	};

	const totalVideo = detailsData?.user?.challengeInfo.totalVideo;
	const followers = detailsData?.user?.friendship.followers;
	const friends = detailsData?.user?.friendship.friends;
	const totalSaveVideo = detailsData?.video?.totalSaveVideo;
	const totalShares = detailsData?.video?.totalShares;
	const totalLikes = detailsData?.video?.totalLikes;
	const streamingUrl = detailsData?.video?.streamingUrl;
	const userProfileImage = detailsData?.user?.image;
	const hashtags = detailsData?.challenge?.hashtags;

	settings = { ...settings, ...modalSliderSettings };

	playerRef.current = data.map((_, i) => {
		return playerRef.current[i] ?? createRef();
	});

	return (
		<div className={`${className || ''}`}>
			{!!title && <p>{title}</p>}
			<Slider {...settings}>
				{data.map((_, i) => {
					return (
						<div className='user-modal-wrapper' key={i}>
							{!loading ? (
								<div className='user-modal-image'>
									<ReactHlsPlayer
										className='user-modal-video-player'
										playerRef={playerRef.current[i]}
										src={
											streamingUrl ??
											'https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8'
										}
										autoPlay={isPlaying}
										controls={false}
										onEnded={handleVideoEnded}
										hlsConfig={{
											startPosition: -1,
											debug: false
										}}
										onClick={() => toggleControls()}
									/>
									<div
										className={`modal-video-btn ${
											!isPlaying ? 'show-modal-video-btn' : 'play-pause-video-btn'
										}`}
										onClick={togglePlayback}
									>
										{isPlaying ? <PauseIcon color='white' /> : <PlayIcon color='white' />}
									</div>
								</div>
							) : (
								<Loader />
							)}
							<div className='user-id-details'>
								{videoModalType != 'all' && (
									<div className='user-profile-wrapper'>
										<div className='user-img'>
											<img src={userProfileImage ?? userImage} alt='userImage' />
										</div>
										<div className='user-id-wrapper'>
											<h5 className='user-id'>
												{location.pathname.includes('reports') ? (
													<Link
														to={`/user-management/${detailsData?.user?.id}`}
													>{`@${detailsData?.user?.userName}`}</Link>
												) : (
													`@${detailsData?.user?.userName}`
												)}
											</h5>
											<h6 className='user-followers'>
												{`${numberFormat(totalVideo || 0, 1)} videos | ${numberFormat(
													followers || 0,
													1
												)} followers | ${numberFormat(friends || 0, 1)} friends`}
											</h6>
											<h6 className='user-bio'>{detailsData?.user?.description}</h6>
										</div>
									</div>
								)}
								<div className='user-posts-wrapper'>
									<ul className='user-modal-ul'>
										<li className='user-modal-li'>
											<div className='user-details'>
												<h2 className='user-post-details'>
													{`${numberFormat(totalLikes || 0, 1)}`}
												</h2>
												<div className='post-action-wrapper'>
													<LikesIcon />
													<h5 className='post-text'>Likes</h5>
												</div>
											</div>
										</li>
										<li className='user-modal-li'>
											<div className='user-details'>
												<h2 className='user-post-details'>
													{`${numberFormat(totalShares || 0, 1)}`}
												</h2>
												<div className='post-action-wrapper'>
													<ShareIcon />
													<h5 className='post-text'>Shared</h5>
												</div>
											</div>
										</li>
										<li className='user-modal-li'>
											<div className='user-details'>
												<h2 className='user-post-details'>{`${numberFormat(
													totalSaveVideo || 0,
													1
												)}`}</h2>
												<div className='post-action-wrapper'>
													<BookmarkIcon />
													<h5 className='post-text'>Saved</h5>
												</div>
											</div>
										</li>
									</ul>
								</div>
								<ChallengeCard
									date={detailsData?.challenge?.challengeRegStartAt}
									title={detailsData?.challenge?.title}
									description={detailsData?.challenge?.description}
									hashtags={hashtags}
								/>
								{videoModalType != 'all' && (
									<>
										<div className='user-report-wrapper'>
											<div className='user-report-details'>
												<h2 className='report-text'>Report</h2>
												<h4 className='report-info'>{detailsData?.category?.title}</h4>
												<p className='user-report-description'>{detailsData?.description}</p>
											</div>
										</div>
										<div className='user-modal-btn-wrapper'>
											<Button
												className='user-modal-cancel-btn'
												onClick={() => setReportAction('resolved')}
											>
												Resolve
											</Button>
											<Button
												btnType='danger'
												className='user-modal-block-btn'
												onClick={() => setReportAction('blocked')}
											>
												Block
											</Button>
										</div>
									</>
								)}
								{reportAction.length > 0 && (
									<CustomModal
										show={true}
										handleClose={() => {
											setReportAction('');
										}}
										closeOnOutSideClick
										className='report-action-modal'
									>
										<div>
											<p className='text-align-center font--18px'>
												Are you sure you want to{' '}
												<span className={`report-action report-action--${reportAction}`}>
													{reportActionStatus[reportAction]}
												</span>{' '}
												this video ?
											</p>
											<div className='report-action-btn-wrapper'>
												<Button
													btnType='default'
													type='submit'
													className='line-height--20px height--40px width-100px'
													onClick={() => {
														handleVideoStatus(
															detailsData?.video?.id,
															reportAction,
															() => setReportAction(''),
															index
														);
													}}
												>
													Yes
												</Button>
												<Button
													btnType='primary'
													type='submit'
													className='line-height--20px height--40px width-100px ml-15'
													onClick={() => setReportAction('')}
												>
													No
												</Button>
											</div>
										</div>
									</CustomModal>
								)}
							</div>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default SlickModalSlider;
