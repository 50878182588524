import { useState } from 'react';

import SingleCampaignsModal from '../modal/singleCampaignsModal';
import BattleCampaignsModal from '../modal/battleCampaignsModal';

import CampaignList from '../components/campaignList';

import SearchComponent from 'shared/components/search/search';
import Button from 'shared/components/form/button';
import ButtonTabs from 'shared/components/tabs/tabs';

const campaignTabs = [
	{ name: 'Single', value: 'single' },
	{ name: 'Battle', value: 'battle' }
];

const Campaigns: React.FC = () => {
	const [currentModalType, setCurrentModalType] = useState<'single' | 'battle' | ''>('');
	const [activeCampaignTab, setActiveCampaignTab] = useState('single');
	const [campaignSearch, setCampaignSearch] = useState('');
	const [isUpdateList, setIsUpdateList] = useState(false);

	const handleCloseModal = () => {
		setCurrentModalType('');
	};

	const handleSwitchTab = (selectedTab: string) => {
		setCampaignSearch('');
		setActiveCampaignTab(selectedTab);
	};

	return (
		<>
			<div>
				<div className='gap--20 d-flex flex-end '>
					<Button
						btnType='primary'
						addBtnType='add'
						className='add-campaign-icon'
						onClick={() => {
							setCurrentModalType('single');
						}}
					>
						Add Campaign
					</Button>
					<Button
						btnType='primary'
						addBtnType='add'
						className='add-campaign-icon'
						onClick={() => {
							setCurrentModalType('battle');
						}}
					>
						Battle Campaign
					</Button>
				</div>
				<div className='video-tab-wrap d-flex justify-content-end gap--20'>
					<div className='campaign-search'>
						<SearchComponent
							value={campaignSearch}
							handleSearch={(value) => {
								setCampaignSearch(value);
							}}
						/>
					</div>
					<ButtonTabs activeTab={activeCampaignTab} setActiveTab={handleSwitchTab} tabs={campaignTabs} />
				</div>
				<CampaignList
					campaignSearch={campaignSearch}
					isUpdateList={isUpdateList}
					handleUpdateList={() => setIsUpdateList(false)}
					activeCampaignTab={activeCampaignTab}
				/>
			</div>
			{currentModalType === 'single' && (
				<SingleCampaignsModal
					handleClose={handleCloseModal}
					handleUpdateList={() => setIsUpdateList(true)}
					handleSwitchTab={handleSwitchTab}
				/>
			)}
			{currentModalType === 'battle' && (
				<BattleCampaignsModal
					handleClose={handleCloseModal}
					handleUpdateList={() => setIsUpdateList(true)}
					handleSwitchTab={handleSwitchTab}
				/>
			)}
		</>
	);
};

export default Campaigns;
