import { useCallback, useState } from 'react';

import { User } from 'shared/icons/icons';
import { numberFormat } from 'shared/helpers';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import CustomDropDown from 'shared/components/form/customDropDown';
import { userStatusDropdownArray } from 'shared/constants/constants';
import Confirm from 'shared/components/confirm/confirm';

import userDetailImage from 'assets/images/user-detail-pic.png';

import { IUserDetail, UserStatus } from '../interface/userManagement';

export interface IUserProfileInfo {
	userDetails: IUserDetail;
	userId: string;
	handleUserStatusChange: (status: UserStatus) => void;
}
const signUpMethodMapper: { [key: string]: string } = {
	apple: 'Apple',
	gmail: 'Google',
	phoneNumber: 'Phone'
};
export const UserProfileInfo: React.FC<IUserProfileInfo> = (props) => {
	const { userDetails, userId, handleUserStatusChange } = props;

	const { challengeInfo, friendship, status, image, userName, description, signupType } = userDetails;

	const [currentSelectedUserStatus, setCurrentSelectedUserStatus] = useState<UserStatus>(status);
	const [actionLoading, setActionLoading] = useState(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const totalVideo = challengeInfo.totalVideo;
	const followers = friendship.followers;
	const friends = friendship.friends;

	const updateUserStatus = useCallback(() => {
		setActionLoading(true);
		httpService
			.put(`${API_CONFIG.path.user}/${userId}/status`, { status: currentSelectedUserStatus })
			.then(() => {
				setActionLoading(false);
				handleConfirmClose();
				handleUserStatusChange(currentSelectedUserStatus);
			})
			.catch((error) => {
				console.error(error);
				setActionLoading(false);
				handleConfirmClose();
			});
	}, [currentSelectedUserStatus]);

	const handleUserStatus = (status: UserStatus) => {
		setCurrentSelectedUserStatus(status);
		setIsConfirmOpen(true);
	};

	const handleConfirmClose = () => {
		setIsConfirmOpen(false);
	};

	return (
		<section className='user-profile-data'>
			<div className='user-id-data'>
				<div className='user-img'>
					{image ?? userDetailImage ? <img src={image ?? userDetailImage} alt='userDetailImage' /> : <User />}
				</div>
				<div className='user-details-container'>
					<div className='user-id-detail-wrapper'>
						<div className='d-flex align-items-center mb-10'>
							<div className='user-id-details'>
								<h5 className='user-id'>{`@${userName ? userName : ''}`}</h5>
							</div>
							<div className={`${status}-btn-wrapper action-btn-wrapper`}>
								<h6 className={`${status}-btn action-btn`}>{status.replace('_', ' ')}</h6>
							</div>
						</div>
						<div>
							<h6 className='user-followers'>
								{`${numberFormat(
									totalVideo ? (totalVideo == 0 ? 0 : totalVideo) : 0,
									1
								)} videos | ${numberFormat(
									followers ? (followers == 0 ? 0 : followers) : 0,
									1
								)} followers | ${numberFormat(friends ? (friends == 0 ? 0 : friends) : 0, 1)} friends`}
							</h6>
							<h6 className='user-bio'>{description}</h6>
						</div>
					</div>
					<p className='semi-bold text-gray mb-10'>
						Sign-up :{' '}
						<span className='signup-type'>
							{signupType.length > 0 ? signUpMethodMapper[signupType] : 'Demo'}
						</span>
					</p>
					{userDetails.email && (
						<p className='semi-bold text-gray mb-10'>
							E-mail : <span className='signup-type'>{userDetails.email}</span>
						</p>
					)}
					{userDetails.mobile && (
						<p className='semi-bold text-gray'>
							Contacts No. : <span className='signup-type'>{userDetails.mobile}</span>
						</p>
					)}
				</div>
			</div>
			<CustomDropDown
				handleDropdown={handleUserStatus}
				dropDownOptions={userStatusDropdownArray}
				filterType='userStatus'
				className='user-status-dropdown'
				selected={status}
				placeholder='User Status'
			/>
			<Confirm
				loading={actionLoading}
				show={isConfirmOpen}
				message={`Are you sure want to ${currentSelectedUserStatus} user ?`}
				handleConfirm={updateUserStatus}
				handleClose={handleConfirmClose}
				cancelBtnText='No'
				submitBtnText='Yes'
				className='viral-confirm-modal'
			/>
		</section>
	);
};
