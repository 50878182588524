import { IAllReportCategory } from '../interface/reports';

import { calculateReportTotal } from 'shared/util/utility';

export interface IReportsProgressBar {
	categoryData: IAllReportCategory[];
}

const ReportsProgressBar: React.FC<IReportsProgressBar> = (props) => {
	const { categoryData } = props;

	return (
		<div className='progress-wrapper'>
			{categoryData.map((report: IAllReportCategory, index) => (
				<div
					className='progress-node'
					style={{
						width: `${(100 * report.reportCount) / calculateReportTotal(categoryData)}%`,
						backgroundColor: report.color,
						display: report.reportCount === 0 ? 'none' : 'block'
					}}
					key={index}
				/>
			))}
		</div>
	);
};

export default ReportsProgressBar;
