import { useCallback, useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, FreeMode, Navigation } from 'swiper/modules';
import moment from 'moment';

import ChallengeCard from 'features/challenges/components/challengeCard';
import { IChallengeInfo, ModalTypes } from 'features/challenges/interface/challenges';
import { SliderIcon } from 'shared/icons/icons';

import { IPagination } from '../pagination/pagination';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';

interface ISliderProps {
	data: IChallengeInfo[];
	challengePagination: IPagination;
	handleDelete: (id: string) => void;
	setPagination: (pagination: IPagination) => void;
	title?: string;
	handleChallengeModal?: (id: string, type: ModalTypes) => void;
}

const ChallengesSlider = (props: ISliderProps) => {
	const { data, title, handleChallengeModal, handleDelete, setPagination, challengePagination } = props;

	const [challengesData, setChallengesData] = useState(data);

	const handlePagination = useCallback(
		(swiper) => {
			// const classEnable = '';
			(swiper.isEnd || swiper.activeIndex === 5) &&
				challengePagination.nextPage > 0 &&
				challengesData.length !== challengePagination.total &&
				setPagination({
					...challengePagination,
					currentPage: challengePagination.nextPage
				});
		},

		[challengePagination]
	);

	useEffect(() => {
		setChallengesData(data);
	}, [data]);

	return (
		<div className='challenges-slider-wrap'>
			{!!title && <p>{title}</p>}

			<Swiper
				direction={'horizontal'}
				mousewheel={true}
				navigation={{ nextEl: '.arrow-right', prevEl: '.arrow-left' }}
				onSlideChange={(swiper) => handlePagination(swiper)}
				freeMode={true}
				speed={500}
				modules={[FreeMode, Navigation, Mousewheel]}
				breakpoints={{
					// when window width is >= 768px
					768: {
						width: 768,
						slidesPerView: 2
					},
					// when window width is >= 992px
					992: {
						width: 800,
						slidesPerView: 2,
						spaceBetween: 30
					},
					// when window width is >= 1024px
					1024: {
						width: 700,
						slidesPerView: 2,
						spaceBetween: 30
					},
					// when window width is >= 1281px
					1281: {
						width: 900,
						slidesPerView: 2,
						spaceBetween: 10
					},
					1440: {
						width: 900,
						slidesPerView: 2.5,
						spaceBetween: 10
					},
					1500: {
						width: 1000,
						slidesPerView: 3,
						spaceBetween: 10
					},
					// when window width is >= 1600px
					1600: {
						width: 1200,
						slidesPerView: 3.5,
						spaceBetween: 10
					},
					// when window width is >= 1720px
					1720: {
						width: 1400,
						slidesPerView: 4,
						spaceBetween: 10
					}
				}}
			>
				{challengesData.map((challenge: IChallengeInfo) => {
					const currentDate = moment(moment().format()).valueOf();

					const currentChallengeStatus =
						currentDate < challenge.challengeRegStartAt
							? 'upcoming'
							: currentDate < challenge.challengeVoteStartAt
							? 'submission_phase'
							: currentDate <= challenge.challengeVoteEndAt
							? 'voting_phase'
							: 'completed';

					return (
						<SwiperSlide className='challenge-slider-card' key={challenge.id}>
							<ChallengeCard
								date={
									currentChallengeStatus === 'upcoming'
										? challenge.challengeRegStartAt
										: currentChallengeStatus === 'submission_phase'
										? challenge.challengeVoteStartAt
										: challenge.challengeVoteEndAt
								}
								title={challenge.title}
								description={challenge.description}
								hashtags={challenge.hashtags}
								isEditable={true}
								handleChallengeModal={handleChallengeModal}
								id={challenge.id}
								votingEndDate={challenge.challengeVoteEndAt}
								currentChallengeStatus={currentChallengeStatus}
								handleDelete={handleDelete}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
			<button className='challenges-slider-arrow arrow-left'>
				<SliderIcon className='arrow-svg' />
			</button>
			<button className='challenges-slider-arrow arrow-right'>
				<SliderIcon className='arrow-svg' />
			</button>
		</div>
	);
};

export default ChallengesSlider;
