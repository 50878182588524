import { useCallback, useEffect, useState } from 'react';

import Pagination, { IPagination } from 'shared/components/pagination/pagination';
import { API_CONFIG } from 'shared/constants/api';
import httpService from 'shared/services/http.service';
import ButtonTabs from 'shared/components/tabs/tabs';

import { IAllVideoReports, IUserDetail } from '../interface/userManagement';

import { UsersReports } from './userReports';

export interface IUserProfileDetails {
	userId: string;
	userDetails: IUserDetail;
}

const videoTabs = [
	{ name: 'All Videos', value: 'all' },
	{ name: 'Reports', value: 'report' }
];

export const UserVideos: React.FC<IUserProfileDetails> = (props) => {
	const { userId, userDetails } = props;
	const [activeVideoTab, setActiveVideoTab] = useState('all');

	const [isGettingUserDetailsFromMe, setIsGettingUserDetailsFromMe] = useState(true);
	const [videoReportsFromMe, setVideoReportsFromMe] = useState<IAllVideoReports>({ data: [], total: 0 });

	const [isGettingUserDetailsAboutMe, setIsGettingUserDetailsAboutMe] = useState(true);
	const [videoReportsAboutMe, setVideoReportsAboutMe] = useState<IAllVideoReports>({ data: [], total: 0 });

	const [isGettingUserAllVideos, setIsGettingUserAllVideos] = useState(true);
	const [userAllVideos, setUserAllVideos] = useState<IAllVideoReports>({ data: [], total: 0 });
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		nextPage: null,
		recordPerPage: 10,
		remainingCount: 0,
		total: 0,
		totalPages: 1
	});

	// Get Users All Videos.
	const getUserAllVideos = useCallback((id: string, currentPage = 1, recordPerPage = 10) => {
		const params = { page: currentPage, recordPerPage };
		setIsGettingUserAllVideos(true);
		const detailUrl = `${API_CONFIG.path.user}/${id}/videos`;
		httpService
			.get(detailUrl, params)
			.then((response) => {
				setIsGettingUserAllVideos(false);
				const updatedData = response.data.map((data) => {
					return {
						userVideo: data,
						id: data.id,
						videoId: data.id
					};
				});
				setUserAllVideos({ data: updatedData, total: updatedData.length });
				const { currentPage, recordPerPage, nextPage, remainingCount, total, totalPages } = response;
				setPagination((page) => ({
					...page,
					currentPage,
					recordPerPage,
					nextPage,
					remainingCount,
					total,
					totalPages
				}));
			})
			.catch((error) => {
				console.log('error : ', error);
				setIsGettingUserAllVideos(false);
			});
	}, []);

	const handlePagination = (page: number, perPageVal: number) => {
		const newPagination = {
			...pagination,
			currentPage: page,
			recordPerPage: perPageVal
		};
		getUserAllVideos(userId, page, perPageVal);
		setPagination({ ...newPagination });
	};

	// Get Users Video Reports From Me.
	const getUserVideoReportsFromMe = useCallback((id: string, page = 1, recordPerPage = 100) => {
		const params = { page, recordPerPage };
		setIsGettingUserDetailsFromMe(true);
		const detailUrl = `${API_CONFIG.path.user}/${id}/video-report/from-me`;
		httpService
			.get(detailUrl, params)
			.then((response) => {
				setVideoReportsFromMe(response);
				setIsGettingUserDetailsFromMe(false);
			})
			.catch((error) => {
				console.log('error : ', error);
				setIsGettingUserDetailsFromMe(false);
			});
	}, []);

	// Get Users Video Reports From Me.
	const getUserVideoReportsAboutMe = useCallback((id: string, page = 1, recordPerPage = 100) => {
		const params = { page, recordPerPage };
		setIsGettingUserDetailsAboutMe(true);
		const detailUrl = `${API_CONFIG.path.user}/${id}/video-report/about-me`;
		httpService
			.get(detailUrl, params)
			.then((response) => {
				setVideoReportsAboutMe(response);
				setIsGettingUserDetailsAboutMe(false);
			})
			.catch((error) => {
				console.log('error : ', error);
				setIsGettingUserDetailsAboutMe(false);
			});
	}, []);

	useEffect(() => {
		getUserVideoReportsFromMe(userId);
		getUserVideoReportsAboutMe(userId);
		getUserAllVideos(userId);
	}, [getUserAllVideos, getUserVideoReportsAboutMe, getUserVideoReportsFromMe, userId]);

	return (
		<>
			<div className='video-tab-wrap d-flex justify-content-end'>
				<ButtonTabs activeTab={activeVideoTab} setActiveTab={setActiveVideoTab} tabs={videoTabs} />
			</div>
			{userId && userId !== '' && (
				<>
					{activeVideoTab === 'all' && (
						<>
							<UsersReports
								userDetails={userDetails}
								videoReportsData={userAllVideos}
								loading={isGettingUserAllVideos}
								type='all'
							/>
							{userAllVideos.data.length > 0 && (
								<Pagination
									handlePageClick={(page: number) => handlePagination(page, pagination.recordPerPage)}
									handlePageSelection={(perPage: number) =>
										handlePagination(pagination.currentPage, perPage)
									}
									loading={isGettingUserAllVideos}
									pagination={pagination}
								/>
							)}
						</>
					)}
					{activeVideoTab === 'report' && (
						<>
							<UsersReports
								userDetails={userDetails}
								videoReportsData={videoReportsAboutMe}
								loading={isGettingUserDetailsAboutMe}
								type='about'
							/>
							<UsersReports
								userDetails={userDetails}
								videoReportsData={videoReportsFromMe}
								loading={isGettingUserDetailsFromMe}
								type='from'
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
