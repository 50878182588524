import React, { PropsWithChildren } from 'react';

import { Logo } from 'shared/icons/icons';

const LoginWrapper: React.FC<{ className?: string; page?: string } & PropsWithChildren> = (props) => {
	return (
		<div
			className={`blue-bg login-wrapper d-flex d-flex-dir-column align-items-center justify-content-center ${
				props.className || ''
			}`}
		>
			<div className='logo-wrapper'>
				<div className='logo'>
					<Logo />
				</div>
			</div>
			<div className='middle-box loginscreen animated fadeInDown'>{props.children}</div>
		</div>
	);
};

export default LoginWrapper;
