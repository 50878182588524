import { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { Loader } from 'shared/components/spinner/spinner';
import { UserList } from 'shared/icons/icons';
import { API_CONFIG } from 'shared/constants/api';
import httpService from 'shared/services/http.service';

import { IUserDetail, UserStatus } from '../interface/userManagement';

import { UserProfileInfo } from './userProfileInfo';
import { UserStatsGraph } from './userStatsGraph';
import { UserVideos } from './userVideo';

export const UserProfileDetails: React.FC = () => {
	const { userId } = useParams();
	const [isGettingUserDetails, setIsGettingUserDetails] = useState(false);
	const [userDetails, setUserDetails] = useState<IUserDetail>();
	const [_, setUpdateStatus] = useOutletContext<any>();

	const getUserDetails = useCallback((id: string) => {
		setIsGettingUserDetails(true);
		httpService
			.get(`${API_CONFIG.path.user}/${id}`)
			.then((response) => {
				setUserDetails(response);
				setIsGettingUserDetails(false);
			})
			.catch((error) => {
				console.error(error);
				setIsGettingUserDetails(false);
			});
	}, []);

	const handleUserStatusChange = (status: UserStatus) => {
		setUserDetails({ ...userDetails, status });
		setUpdateStatus({
			status,
			isUpdate: true
		});
	};

	useEffect(() => {
		if (userId !== '') {
			getUserDetails(userId);
		}
	}, [getUserDetails, userId]);

	return (
		<article className='user-profile-detail'>
			{(userId === '' || isGettingUserDetails) && <Loader className='custom-loader' />}
			{userId !== '' && (
				<>
					{!isGettingUserDetails && isEmpty(userDetails) && (
						<div className='user-profile-icon'>
							<UserList />
							<p className='user-profile-text'>Nothing in user details.</p>
						</div>
					)}
					{!isGettingUserDetails && !isEmpty(userDetails) && (
						<UserProfileInfo
							userDetails={userDetails}
							userId={userId}
							handleUserStatusChange={handleUserStatusChange}
						/>
					)}

					<UserStatsGraph userId={userId} />
					<UserVideos userId={userId} userDetails={userDetails} />
				</>
			)}
		</article>
	);
};
