import localizationConstants from 'shared/util/translation/constants';

const firstLevelBreadcrumbs = [{ name: localizationConstants.home, link: '/' }];

const NUMBER_REGEX = /[0-9]*\.?[0-9]*$/;
const PASSWORD_VALIDATOR_REGEX = /^(?=.{8,})(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).*$/;
const EMAIL_VALIDATOR_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const DATE_AND_TIME_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])*$/;
const URL_REGEX =
	/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // eslint-disable-line

const FORMAT_DATE_WITH_TIME = 'MM-dd-yyyy hh:mm';
const FORMAT_DATE_WITH_TIME_UTC = 'MM-DD-yyyy HH:mm';
const FORMAT_DATE_WITH_TIME_CST = 'MM-DD-YYYY HH:mm:ss';
const FORMAT_DATE_SERVER = 'yyyy-MM-dd';

export {
	firstLevelBreadcrumbs,
	NUMBER_REGEX,
	PASSWORD_VALIDATOR_REGEX,
	EMAIL_VALIDATOR_REGEX,
	DATE_AND_TIME_REGEX,
	FORMAT_DATE_WITH_TIME,
	FORMAT_DATE_WITH_TIME_UTC,
	FORMAT_DATE_WITH_TIME_CST,
	FORMAT_DATE_SERVER,
	URL_REGEX
};

export const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];
export const videoFileTypes = ['MP4', 'Webm'];

export const maxHashTagLimit = 5;
export const maxSongListLimit = 3;

export const userStatusDropdownArray = [
	{ label: 'Active', value: 'active', name: 'Active' },
	{ label: 'Deactivated', value: 'deactivated', name: 'Deactivated' },
	{ label: 'Action Required', value: 'action_required', name: 'Action Required' },
	{ label: 'Blocked', value: 'blocked', name: 'Blocked' }
];
