import { useState } from 'react';
import { Outlet } from 'react-router';
import UserListComponent from '../components/usersList';

export const UserManagement: React.FC = () => {
	const [updateStatus, setUpdateStatus] = useState({
		status: '',
		isUpdate: false
	});

	return (
		<main>
			<div className='user-management-wrapper'>
				<UserListComponent updateStatus={updateStatus} setUpdateStatus={() => setUpdateStatus} />
				<Outlet context={[updateStatus, setUpdateStatus]} />
			</div>
		</main>
	);
};

export default UserManagement;
