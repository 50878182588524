import React, { MouseEventHandler, CSSProperties, PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import loading from 'assets/images/loading.gif';
import { AddHashTagIcon, Delete, IconFileDownload } from 'shared/icons/icons';

type ActionButtonType = 'add' | 'remove' | 'download';
interface IButtonProps extends PropsWithChildren {
	btnType?: 'default' | 'primary' | 'warning' | 'danger' | 'info';
	loading?: boolean;
	disabled?: boolean;
	type?: 'submit' | 'button' | 'reset';
	className?: string;
	onClick?: MouseEventHandler<any>;
	abbr?: string;
	title?: string;
	style?: CSSProperties;
	dataTestId?: string;
	prefix?: ReactElement;
	addBtnType?: ActionButtonType;
}

const actionButtonIconMapper: { [key: string]: JSX.Element } = {
	add: <AddHashTagIcon />,
	remove: <Delete />,
	download: <IconFileDownload />
};

const Button: React.FC<IButtonProps> = (props) => {
	const { t } = useTranslation();
	const onClick: MouseEventHandler<any> = (e) => !props.loading && props.onClick && props.onClick(e);

	const button = (
		<button
			data-testid={props.dataTestId || null}
			title={t(props.abbr || '')}
			type={props.type || 'button'}
			className={classnames(
				'btn',
				`btn-${props.btnType || 'default'}`,
				'ignore-text-capitalize',
				props.className
			)}
			disabled={props.loading || props.disabled}
			style={props.style || {}}
			onClick={!props.disabled && !props.loading ? onClick : () => undefined}
		>
			{!props.loading && actionButtonIconMapper[props.addBtnType]}
			{props.loading ? <img className='loading-image' src={loading} /> : ''} {props.children}
		</button>
	);
	return props.abbr ? (
		<abbr title={t(props.abbr || '')} className='custom-abbr'>
			{button}
		</abbr>
	) : (
		button
	);
};

export default Button;
