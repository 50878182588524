import { useCallback, useEffect, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Calender } from './calender';

import { IAllChallenges, ICategoryFilter } from '../interface/challenges';

import CustomDropDown from 'shared/components/form/customDropDown';
import SearchComponent from 'shared/components/search/search';
import { Loader } from 'shared/components/spinner/spinner';
import Pagination, { IPagination } from 'shared/components/pagination/pagination';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { IDropDownAndCheckboxOptions } from 'shared/components/form/inputTypes';
import { debounce } from 'shared/util/utility';

interface IProps {
	categoryDropdownOption: IDropDownAndCheckboxOptions[];
	handleChallengeModal: (id: string, type: string) => void;
}
const calendarTabs = [
	{ name: 'Days', value: 'Days' },
	{ name: 'Weeks', value: 'Weeks' },
	{ name: 'Months', value: 'Months' }
];

const ChallengeCalender = (props: IProps) => {
	const { handleChallengeModal, categoryDropdownOption } = props;

	const [activeCalendarTab, setActiveCalendarTab] = useState('Days');
	const [isGettingChallengeCalender, setIsGettingChallengeCalender] = useState<boolean>(false);
	const [challengeCalenderData, setChallengeCalenderData] = useState<IAllChallenges>();
	const [calendarSearch, setCalendarSearch] = useState('');
	const [currentSelectedCategory, setCurrentSelectedCategory] = useState<ICategoryFilter>({} as ICategoryFilter);

	const fetchChallengeCalender = useCallback(
		(categoryId?: string, currentPage = 1, recordPerPage = 10, search = '') => {
			const params = { categoryId, page: currentPage, recordPerPage, search };
			setIsGettingChallengeCalender(true);
			httpService
				.get(`${API_CONFIG.path.challengeCalender}`, params)
				.then((response) => {
					setChallengeCalenderData(response);
					setIsGettingChallengeCalender(false);
				})
				.catch((error) => {
					console.error('error', error);
					setIsGettingChallengeCalender(false);
				});
		},
		[]
	);

	// Search Calendar
	const getSearchCalendarChallenge = (title: string) => {
		setCalendarSearch(title);
	};

	const handleCalendarSearch = debounce((value: string) => getSearchCalendarChallenge(value));

	const handleCalenderCategory = (categoryType: string, filterType: string) => {
		setCurrentSelectedCategory({ categoryType: categoryType, filterType: filterType });
	};

	useEffect(() => {
		if (currentSelectedCategory.filterType === 'calenderChallenges') {
			fetchChallengeCalender(
				currentSelectedCategory.categoryType === 'all' ? undefined : currentSelectedCategory.categoryType,
				1,
				undefined,
				calendarSearch.trim()
			);
		}
	}, [currentSelectedCategory]);

	useEffect(() => {
		fetchChallengeCalender(
			currentSelectedCategory.categoryType === 'all' ? undefined : currentSelectedCategory.categoryType,
			1,
			undefined,
			calendarSearch.trim()
		);
	}, [calendarSearch]);

	return (
		<div className='calender-wrap'>
			<div className='calender-category-status-wrapper'>
				<ButtonGroup>
					{calendarTabs.map((radio, idx) => (
						<ToggleButton
							key={idx}
							id={`radio-${idx}`}
							type='radio'
							variant={'outline-primary'}
							name='radio'
							value={radio.value}
							checked={activeCalendarTab === radio.value}
							onChange={(e) => setActiveCalendarTab(e.currentTarget.value)}
							className='calender-toggle-btn'
						>
							{radio.name}
						</ToggleButton>
					))}
				</ButtonGroup>
				<div className='calendar-dropdown-search'>
					<div className='challenge-search mb-15'>
						<SearchComponent handleSearch={handleCalendarSearch} />
					</div>
					<CustomDropDown
						className='challenge-dropdown'
						handleDropdown={handleCalenderCategory}
						dropDownOptions={categoryDropdownOption}
						filterType='calenderChallenges'
						placeholder='All Category'
					/>
				</div>
			</div>
			{isGettingChallengeCalender ? (
				<Loader className='custom-loader' />
			) : (
				<>
					{!challengeCalenderData || challengeCalenderData.data.length === 0 ? (
						<div className='user-profile-icon'>
							<p className='user-profile-text'>No Challenge Found</p>
						</div>
					) : (
						<Calender
							activeTab={activeCalendarTab}
							calenderData={challengeCalenderData}
							handleCalendarEvent={handleChallengeModal}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default ChallengeCalender;
