import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import ButtonTabs from 'shared/components/tabs/tabs';
import { numberFormat } from 'shared/helpers';
import LineChart, { IChartData } from 'shared/components/chart/linechart';
import httpService from 'shared/services/http.service';
import { IconTripleDots } from 'shared/icons/icons';
import { Loader } from 'shared/components/spinner/spinner';
import { getMinSecDuration } from 'shared/util/utility';
import {
	AvgTimeGraph,
	DateLabelMapper,
	DetailsActions,
	GraphPreTitle,
	GraphTitle,
	IDashboardDetailsMapper,
	dashboardTabs
} from '../interface/dashboard';

const Dashboard: FC = () => {
	const [dashboardStats, setDashboardStats] = useState<IDashboardDetailsMapper>();
	const [checkChartEmpty, setCheckChartEmpty] = useState<any>({});
	const [activeDashboardTab, setActiveDashboardTab] = useState('all');
	const [dashboardDataLoader, setDashboardDataLoader] = useState(false);

	const dashboardGraphData = useMemo(() => {
		if (!isEmpty(dashboardStats)) {
			return Object.keys(dashboardStats);
		} else {
			return [];
		}
	}, [dashboardStats]);

	const getDashboardGraphData = useCallback(async (filterType: string) => {
		let graphDetails: any = {};
		const checkEmpty = {};
		setDashboardDataLoader(true);
		for (const key in DetailsActions) {
			const graph = DetailsActions[key];
			try {
				const GRAPH_URL = `dashboard/${graph}`;
				const response = await httpService.get(GRAPH_URL, { filterType });
				if (response) {
					if (
						!('performanceGraph' in response) ||
						('performanceGraph' in response && response.performanceGraph.some((ele) => ele.count === 0)) ||
						('performanceGraph' in response && response.performanceGraph.length === 0)
					) {
						checkEmpty[graph] = true;
					}
					graphDetails = {
						...graphDetails,
						[graph]: response
					};
				}
			} catch (error) {
				setDashboardDataLoader(false);
				console.error(error);
			}
		}
		setDashboardDataLoader(false);
		setCheckChartEmpty(checkEmpty);
		setDashboardStats({ ...graphDetails });
	}, []);

	useEffect(() => {
		getDashboardGraphData(activeDashboardTab);
	}, [getDashboardGraphData, activeDashboardTab]);

	const formatDate = (date: Date) => {
		const inputDate = date;

		// Parse the date
		const parsedDate = moment(inputDate);
		// Format the day with 'Do' for the ordinal suffix (e.g., 1st, 2nd, 3rd, 4th, etc.)
		const formattedDay = parsedDate.format(DateLabelMapper[activeDashboardTab][0]);

		// Format the month with 'MMM' for the abbreviated month name (e.g., Dec)
		const formattedMonth = parsedDate.format(DateLabelMapper[activeDashboardTab][1]);

		const formattedYear = parsedDate.format(DateLabelMapper[activeDashboardTab][2]);

		// Combine the formatted day and month
		const formattedDate =
			activeDashboardTab === 'day'
				? `${formattedDay}:${formattedMonth}:${formattedYear}`
				: `${formattedDay} ${formattedMonth} ${formattedYear}`;

		return formattedDate;
	};

	const createChartData = (key: string) => {
		return {
			labels: dashboardStats[key]?.performanceGraph.map((ele) => formatDate(ele.timestamp)),
			datasets: [
				{
					label: key,
					data: dashboardStats[key]?.performanceGraph.map((ele) =>
						key === AvgTimeGraph ? Math.round(ele.count / 60) : ele.count
					),
					borderColor: ['rgba(88, 201, 71, 1)'],
					backgroundColor: 'rgba(88, 201, 71, 1)',
					radius: 2,
					borderWidth: 2,
					tension: 0.5,
					fill: false // To remove the area under the line
				}
			]
		};
	};

	const options = {
		plugins: {
			legend: {
				display: false
			}
		},
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false
				},
				ticks: {
					callback: function (val, index, vals) {
						return index === 0 || index === vals.length - 1 ? this.getLabelForValue(val) : '';
					}
				}
			},
			x: {
				grid: {
					display: false
				},
				ticks: {
					callback: function (val, index, vals) {
						// Hide every 2nd tick label
						return index === 0 || index === vals.length - 1 ? this.getLabelForValue(val) : '';
					},
					maxRotation: 0,
					minRotation: 0
				}
			},
			r: {
				display: false
			}
		},
		maintainAspectRatio: false
	};

	const handleTabChange = (activeTab: string) => {
		setActiveDashboardTab(activeTab);
		setCheckChartEmpty({});
	};
	return (
		<>
			<div className='dashboard-statistics-tabs'>
				<ButtonTabs activeTab={activeDashboardTab} setActiveTab={handleTabChange} tabs={dashboardTabs} />
			</div>
			{dashboardDataLoader ? (
				<Loader className='custom-loader dashboard-loader' />
			) : (
				<section className='dashboard-graph-wrapper'>
					<div className='graph-detail-data'>
						<ul className='graph-ul'>
							{dashboardGraphData.map((graph, index) => {
								const total = dashboardStats?.[graph.toLocaleLowerCase()]?.total;
								const performancePer = dashboardStats?.[graph.toLocaleLowerCase()]?.performancePer;
								return (
									<li className='graph-li' key={index}>
										<div className='dashboard-graph-title'>
											<h4 className='graph-title'>
												{`${GraphPreTitle[activeDashboardTab]} ${GraphTitle[graph]}`}
											</h4>
											<IconTripleDots />
										</div>
										<div className='dashboard-statistics'>
											<div className='dashboard-progress-data'>
												<div className='dashboard-personal-detail'>
													<div className='followers-data'>
														<h2 className='user-progress'>
															{graph === AvgTimeGraph
																? `${getMinSecDuration(total, 'HH:MM')} h`
																: `${numberFormat(total || 0, 1)}`}
														</h2>

														{activeDashboardTab !== 'all' && (
															<h6 className='user-rate'>
																{`${numberFormat(performancePer || 0, 1)}
												% from last ${activeDashboardTab}`}
															</h6>
														)}
													</div>
												</div>
											</div>
											<div className='graph-icon d-flex justify-content-end'>
												{checkChartEmpty[graph] ? (
													<p className='empty-chart'>-----</p>
												) : (
													<LineChart
														data={createChartData(graph) as unknown as IChartData}
														option={{
															...options,
															scales: {
																...options.scales,
																y: {
																	...options.scales.y,
																	title: {
																		display: true,
																		text:
																			graph === AvgTimeGraph ? 'Minutes' : 'Count'
																	}
																}
															}
														}}
													/>
												)}
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</section>
			)}
		</>
	);
};

export default Dashboard;
