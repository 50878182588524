import { useState } from 'react';
import moment from 'moment';

import { ModalTypes } from '../interface/challenges';

import { CountDownTimer } from 'shared/components/CountDownTimer';
import { CustomizeEdit, DeleteChallenge, View } from 'shared/icons/icons';
import { statusIndicatorMapper, statusTitleMapper } from '../constants/challenges.constant';

export interface IChallengeCard {
	date: number;
	title: string;
	description: string;
	hashtags: string[];
	isEditable?: boolean;
	handleChallengeModal?: (id: string, type: ModalTypes) => void;
	id?: string;
	votingEndDate?: number;
	currentChallengeStatus?: string;
	handleDelete?: (id: string) => void;
}

export const ChallengeCard: React.FC<IChallengeCard> = (props) => {
	const {
		date,
		title,
		description,
		hashtags,
		isEditable,
		handleChallengeModal,
		id,
		votingEndDate,
		currentChallengeStatus,
		handleDelete
	} = props;

	const [isExpire, setExpire] = useState(false);
	const currentDateStamp = moment().valueOf();

	const showEdit = currentDateStamp < votingEndDate;
	const isDisabled = currentChallengeStatus !== 'upcoming';

	return (
		<div
			className='user-challenge-wrapper'
			onDoubleClick={(e) => {
				e.stopPropagation();
				isEditable && handleChallengeModal(id, 'view');
			}}
		>
			<div className='user-challenge-inner-wrapper'>
				<div className='challenge-timing'>
					<h5 className='user-text'>Challenge</h5>
					<h5 className='user-timing'>
						<span className='count-down'>
							<CountDownTimer date={date} afterContent={<></>} onComplete={() => setExpire(true)} />
						</span>
					</h5>
					<div className={`challenge-current-status-bar`}>
						{statusIndicatorMapper[currentChallengeStatus]}
					</div>
				</div>
				<div className='user-dance-wrapper'>
					<h3 className='dance-text'>{title?.toLowerCase()}</h3>
					<p className='dance-description'>{description}</p>
				</div>
				<div className='user-codes-wrapper-out'>
					<div className='user-codes-wrapper'>
						{hashtags &&
							hashtags.map((hash, index) => {
								return (
									<h5 className='user-code' key={index}>
										{hash.toUpperCase()}
									</h5>
								);
							})}
					</div>
				</div>
				{isEditable && (
					<div className='challenge-action-overlay'>
						<p className='challenge-action-status'>{statusTitleMapper[currentChallengeStatus]}</p>
						<div
							className='icon-wrapper'
							title='View'
							onClick={(e) => {
								e.stopPropagation();
								handleChallengeModal(id, 'view');
							}}
						>
							<View />
						</div>
						{showEdit && (
							<div
								className='icon-wrapper'
								title='Edit'
								onClick={(e) => {
									e.stopPropagation();
									handleChallengeModal(id, 'edit');
								}}
							>
								<CustomizeEdit />
							</div>
						)}
						<div
							className={`icon-wrapper-delete ${isDisabled ? 'disabled' : ''}`}
							title={`${isDisabled ? 'You can not delete this challenge' : 'Delete'}`}
							onClick={(e) => {
								e.stopPropagation();
								if (!isDisabled) {
									handleDelete(id);
								}
							}}
						>
							<DeleteChallenge />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ChallengeCard;
