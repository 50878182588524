import { useEffect, useState, useRef } from 'react';
import Timeline, {
	CursorMarker,
	CustomMarker,
	DateHeader,
	TimelineHeaders,
	TimelineMarkers,
	TodayMarker
} from 'react-calendar-timeline';
import { Spring, animated } from 'react-spring';
import moment from 'moment';
import 'react-calendar-timeline/lib/Timeline.css';

import { ZoomInIcon, ZoomOutIcon } from 'shared/icons/icons';
import { defaultTimeEnd, defaultTimeStart, keys } from '../utility/challengeConstants';
import { moveResizeValidator, verticalLineClassNamesForTime } from '../utility/calenderHandles';
import { IAllChallenges, IChallengeInfo } from '../interface/challenges';
import {
	defaultHeaderLabelFormats,
	defaultSubHeaderLabelFormats,
	defaultTimeFormat
} from '../constants/challenges.constant';
import CalenderCard from './calenderCard';

export interface IChallengesCalender {
	activeTab: string;
	calenderData: IAllChallenges;
	handleCalendarEvent: (id: string, type: 'view') => void;
}

export interface IChallengeTimelineData {
	id: number;
	group: number;
	title: string;
	start_time: string;
	end_time: string;
}
const calendarDay = moment().startOf('day');
const calendarWeek = moment().startOf('week');
const calendarMonth = moment().startOf('month');

const visibleTimeMapper = {
	Day: {
		visibleTimeStart: calendarDay.subtract(1, 'days').valueOf(),
		visibleTimeEnd: calendarDay.add(0, 'days').valueOf()
	},
	Days: {
		visibleTimeStart: calendarDay.subtract(15, 'days').valueOf(),
		visibleTimeEnd: calendarDay.add(15, 'days').valueOf()
	},
	Weeks: {
		visibleTimeStart: calendarWeek.subtract(5, 'weeks').valueOf(),
		visibleTimeEnd: calendarWeek.add(5, 'weeks').valueOf()
	},
	Months: {
		visibleTimeStart: calendarMonth.valueOf(),
		visibleTimeEnd: calendarMonth.add(3, 'months').valueOf()
	}
};

export const Calender: React.FC<IChallengesCalender> = (props) => {
	const { activeTab, calenderData, handleCalendarEvent } = props;

	const [groupsData, setGroupsData] = useState([]);
	const [itemsData, setItemsData] = useState([]);
	const [currentUnit, setCurrentUnit] = useState<any>('day');
	const [currentLabelFormat, setCurrentLabelFormat] = useState<any>('D');
	const [currentTopLabelFormat, setCurrentTopLabelFormat] = useState<any>('D');
	const [topCurrentUnit, setTopCurrentUnit] = useState<any>('day');
	const [visibleTimeStart, setVisibleTimeStart] = useState(calendarDay.subtract(15, 'days').valueOf());
	const [visibleTimeEnd, setVisibleTimeEnd] = useState(calendarDay.add(15, 'days').valueOf());
	const [zoomCount, setZoomCount] = useState(1);

	const timeLineRef: any = useRef();

	const currentDay = visibleTimeStart - visibleTimeEnd >= -86400000;

	useEffect(() => {
		setVisibleTimeStart(visibleTimeMapper[activeTab].visibleTimeStart);
		setZoomCount(1);
		setVisibleTimeEnd(visibleTimeMapper[activeTab].visibleTimeEnd);
		if (activeTab == 'Day') {
			setCurrentUnit('hour');
		} else if (activeTab == 'Days') {
			setCurrentUnit('day');
		} else if (activeTab == 'Weeks') {
			setCurrentUnit('week');
		} else {
			setCurrentUnit('month');
		}
	}, [activeTab]);

	useEffect(() => {
		if (currentUnit == 'hour') {
			setCurrentLabelFormat('HH:00');
			setTopCurrentUnit('day');
			setCurrentTopLabelFormat('dddd, LL');
		}

		if (currentUnit == 'day') {
			setCurrentLabelFormat('D');
			setTopCurrentUnit('day');
			setTopCurrentUnit('month');
		}

		if (currentUnit == 'year') {
			setCurrentLabelFormat('YY');
			setTopCurrentUnit('year');
		}
		if (currentUnit == 'month') {
			setCurrentLabelFormat('MMMM YYYY');
			setTopCurrentUnit('year');
		}
		if (currentUnit == 'week') {
			setCurrentLabelFormat('w');
			setTopCurrentUnit('month');
		}
	}, [currentUnit]);

	useEffect(() => {
		if (topCurrentUnit == 'hour') {
			setCurrentTopLabelFormat('dddd, LL');
		}
		if (topCurrentUnit == 'day' || currentDay) {
			setCurrentTopLabelFormat('dddd, LL');
		}
		if (topCurrentUnit == 'year') {
			setCurrentTopLabelFormat('YYYY');
		}
		if (topCurrentUnit == 'month') {
			setCurrentTopLabelFormat('MMMM YYYY');
		}
		if (topCurrentUnit == 'week') {
			setCurrentTopLabelFormat('w');
		}
	}, [topCurrentUnit]);

	useEffect(() => {
		const groupArray = [];
		const itemsArray = [];
		calenderData?.data?.map((data: IChallengeInfo) => {
			const { id, title, challengeRegStartAt, challengeVoteEndAt } = data;

			const groups = {
				id,
				group: id,
				title,
				start_time: challengeRegStartAt,
				end_time: challengeVoteEndAt,
				height: 100,
				stackItems: false
			};
			groupArray.push(groups);

			const items = {
				id,
				group: id,
				title,
				start_time: challengeRegStartAt,
				end_time: challengeVoteEndAt,
				canMove: false,
				canResize: true,
				canChangeGroup: false,
				useResizeHandle: true,
				itemProps: {
					// these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
					'data-custom-attribute': 'Random content',
					'aria-hidden': true,
					className: 'weekend',
					style: {
						background: 'fuchsia'
					},
					...data
				}
			};
			itemsArray.push(items);
		});

		setGroupsData(groupArray);
		setItemsData(itemsArray);
	}, [calenderData]);

	const handleItemDoubleClick = (itemId: string) => {
		handleCalendarEvent(itemId, 'view');
	};

	const handleZoomIn = () => {
		// Calculate the new zoom range, for example, zoom in by half
		const newVisibleTimeStart = visibleTimeStart + (visibleTimeEnd - visibleTimeStart) / 4;
		const newVisibleTimeEnd = visibleTimeEnd - (visibleTimeEnd - visibleTimeStart) / 4;

		if (zoomCount < 3) {
			setZoomCount(zoomCount + 1);
			setVisibleTimeStart(newVisibleTimeStart);
			setVisibleTimeEnd(newVisibleTimeEnd);
		}
	};

	const handleZoomOut = () => {
		// Calculate the new zoom range, for example, zoom out by double
		const newVisibleTimeStart = visibleTimeStart - (visibleTimeEnd - visibleTimeStart) / 2;
		const newVisibleTimeEnd = visibleTimeEnd + (visibleTimeEnd - visibleTimeStart) / 2;

		if (zoomCount > 0) {
			setZoomCount(zoomCount - 1);
			setVisibleTimeStart(newVisibleTimeStart);
			setVisibleTimeEnd(newVisibleTimeEnd);
		}
	};

	const AnimatedTimeline = animated(({ animatedVisibleTimeStart, animatedVisibleTimeEnd, ...props }) => (
		<Timeline
			visibleTimeStart={animatedVisibleTimeStart}
			visibleTimeEnd={animatedVisibleTimeEnd}
			ref={timeLineRef}
			defaultTimeFormat={defaultTimeFormat}
			defaultHeaderLabelFormats={defaultHeaderLabelFormats}
			defaultSubHeaderLabelFormats={defaultSubHeaderLabelFormats}
			{...props}
		/>
	));

	const minZoom = activeTab == 'Months' ? 60 * 60 * 1000 * 672 * 8 : 60 * 60 * 1000 * 200;
	const maxZoom = activeTab == 'Months' ? 1 * 365.24 * 86400 * 1000 : 0.2 * 365.24 * 86400 * 1000;

	return (
		<div className='position--relative'>
			<div className='challenge-calender-wrap'>
				<button className='zoom-in-btn btn' disabled={zoomCount === 2} onClick={() => handleZoomIn()}>
					<ZoomInIcon />
				</button>
				<button className='zoom-out-btn btn' disabled={zoomCount === 0} onClick={() => handleZoomOut()}>
					<ZoomOutIcon />
				</button>
				<Spring
					to={{
						animatedVisibleTimeStart: visibleTimeStart,
						animatedVisibleTimeEnd: visibleTimeEnd
					}}
				>
					{(value) => {
						return (
							<AnimatedTimeline
								groups={groupsData}
								items={itemsData}
								keys={keys}
								sidebarWidth={0}
								useResizeHandle
								sidebarContent={''}
								canMove
								canResize='both'
								itemTouchSendsClick={false}
								stackItems={false}
								itemHeightRatio={0.65}
								onItemDoubleClick={handleItemDoubleClick}
								moveResizeValidator={moveResizeValidator}
								verticalLineClassNamesForTime={verticalLineClassNamesForTime}
								defaultTimeStart={defaultTimeStart}
								defaultTimeEnd={defaultTimeEnd}
								itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
									const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
									const backgroundColor = itemContext.selected ? itemContext.dragging : '#ECF0FF';
									const borderColor = '#ECF0FF';
									return (
										<div
											{...getItemProps({
												style: {
													backgroundColor,
													color: 'green',
													borderColor,
													borderStyle: 'solid',
													borderRadius: 4,
													borderLeftWidth: itemContext.selected ? 3 : 1,
													borderRightWidth: itemContext.selected ? 3 : 1,
													border: 0
												}
											})}
										>
											{itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
											<div
												style={{
													maxHeight: `${itemContext.dimensions.height}`,
													marginBottom: 10
												}}
											>
												<div>
													<CalenderCard
														key={item.id}
														calenderItemData={item.itemProps}
														handleCalendarEvent={handleCalendarEvent}
													/>
												</div>
											</div>

											{itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
										</div>
									);
								}}
								groupRenderer={({ group }) => {
									return (
										<div className='custom-group'>
											<span className='title'>{group.title}</span>
											<p className='tip'>{group.id}</p>
										</div>
									);
								}}
								{...value}
								minZoom={minZoom}
								maxZoom={maxZoom}
							>
								<TimelineMarkers>
									<TodayMarker date={defaultTimeStart} />
									<CustomMarker date={calendarDay.valueOf() + 1000 * 60 * 60 * 2} />
									<CustomMarker date={moment().add(3, 'day').valueOf()}>
										{({ styles }) => {
											const newStyles = { ...styles, backgroundColor: 'blue' };
											return <div style={newStyles} />;
										}}
									</CustomMarker>
									<CursorMarker />
								</TimelineMarkers>
								<TimelineHeaders>
									<DateHeader unit={topCurrentUnit} labelFormat={currentTopLabelFormat} />
									<DateHeader
										unit={currentUnit}
										intervalRenderer={({ getIntervalProps, intervalContext }) => {
											return (
												<div {...getIntervalProps()} onClick={() => null}>
													<span>{`${currentUnit == 'week' ? 'Week' : ''} ${
														intervalContext.intervalText
													}`}</span>
												</div>
											);
										}}
										labelFormat={currentLabelFormat}
									/>
								</TimelineHeaders>
							</AnimatedTimeline>
						);
					}}
				</Spring>
			</div>
		</div>
	);
};
