import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { API_CONFIG } from 'shared/constants/api';
import httpService from 'shared/services/http.service';
import { Loader } from 'shared/components/spinner/spinner';

import ReportsHeader from '../components/reportsHeader';
import ReportsProgressBar from '../components/reportsProgressBar';
import ReportsProgressDataWrapper from '../components/reportsProgressDataWrapper';
import ReportsSlider from '../components/reportsSlider';
import { IAlStatisticsReportData, IAllReportCategory, IAllTopVideoReport } from '../interface/reports';

interface IColorMapperInterface {
	[key: string]: string;
}

const colorMapper: IColorMapperInterface = {
	'Harassment or bullying': '#EDA073',
	'Copyright infringement': '#A47EF1',
	'Child abuse': '#EECC77',
	'Sexual content': '#6EAEF7',
	'Spam or false information': '#8BE9EE',
	'Harmful or dangerous actions': '#87DBF7',
	'Propaganda of terrorism': '#EB8484',
	Insults: '#777CF8',
	'Violent scenes': '#CA77EE',
	Other: '#E4E5EE'
};

const Reports: React.FC = () => {
	const [topUserVideoReport, setTopUserVideoReport] = useState<IAllTopVideoReport[]>([]);
	const [isGettingReports, setIsGettingReports] = useState<boolean>(false);
	const [reportByCategory, setReportByCategory] = useState<IAllReportCategory[]>([]);
	const [statisticsReport, setStatisticsReport] = useState<IAlStatisticsReportData>();

	const fetchReportStatistics = () => {
		setIsGettingReports(true);
		const detailUrl = `${API_CONFIG.path.reportStatistics}`;
		httpService
			.get(detailUrl)
			.then(async (response) => {
				setStatisticsReport(response);
				setTopUserVideoReport(response.topUserVideoReport);
				setIsGettingReports(false);
				const categoryReportData = response.reportByCategory.map((item: IAllReportCategory) => {
					return {
						...item,
						color: colorMapper[item.title]
					};
				});
				setReportByCategory(categoryReportData);
			})
			.catch((error) => {
				console.error('error', error);
				setIsGettingReports(false);
			});
	};

	useEffect(() => {
		fetchReportStatistics();
	}, []);

	return (
		<main>
			{isGettingReports && <Loader className='reports-loader' />}
			{!isGettingReports && isEmpty(reportByCategory) && <p className='no-report-text'>No Reports Found</p>}
			{!isGettingReports && !isEmpty(reportByCategory) && (
				<div className='reports-container'>
					<ReportsHeader
						topUserVideoReport={topUserVideoReport}
						reportByCategory={reportByCategory}
						statisticsReport={statisticsReport}
					/>
					<div className='reports-progress-wrapper'>
						<ReportsProgressBar categoryData={reportByCategory} />
						<ReportsProgressDataWrapper reportsByCategoryData={reportByCategory} />
						{reportByCategory.map(({ id, title }) => (
							<ReportsSlider key={id} categoryId={id} title={title} />
						))}
					</div>
				</div>
			)}
		</main>
	);
};

export default Reports;
