import { Line } from 'react-chartjs-2';

export interface IChartData {
	labels: string[];
	datasets: IDataSets[];
}
export interface IDataSets {
	label: string;
	data: string[];
	borderColor: string[];
	backgroundColor: string[];
	radius: number;
	borderWidth: number;
}
interface IProps {
	data: IChartData;
	option?: object;
}

const LineChart: React.FC<IProps> = ({ data, option }) => {
	return <Line data={data} options={option} />;
};

export default LineChart;
