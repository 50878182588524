import { useCallback, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Alert } from 'react-bootstrap';

import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import { API_CONFIG } from 'shared/constants/api';
import httpService from 'shared/services/http.service';
import { IPresignedData } from 'shared/interface';
import { Loader } from 'shared/components/spinner/spinner';

import { IAddMusic } from '../interface/music';

import MusicImage from 'assets/images/music.png';
import LoadingImage from 'assets/images/loading.gif';

interface IProps {
	show: boolean;
	handleClose: () => void;
	setIsMusicList: (isMusicList: boolean) => void;
}

const AddMusicModal = (props: IProps) => {
	const { show, handleClose, setIsMusicList } = props;

	const [uploadedFileList, setUploadedFileList] = useState([]);
	const [spotifyList, setSpotifyList] = useState([]);
	const [filesWithNoData, setFilesWithNoData] = useState([]);
	const [isUpload, setIsUpload] = useState(false);
	const [uploadingToS3, setUploadingToS3] = useState(false);
	const [isBulkUploading, setIsBulkUploading] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);

	const uploadingState = isUpload || isBulkUploading || uploadingToS3;

	const getMusicDuration = async (file) => {
		return new Promise((resolve, reject) => {
			const audio = new Audio(URL.createObjectURL(file));

			audio.addEventListener('loadedmetadata', () => {
				resolve(audio.duration);
			});

			audio.addEventListener('error', (e) => {
				reject(e);
			});
		});
	};

	const handleFileUpload = async (files) => {
		setIsUpload(true);
		setSelectedFile(Object.keys(files));
		const fileData = [];
		const missingDataFile = [];
		for (const file of files) {
			const answerData = await fetchMusicList(file);
			if (!answerData || (!!answerData && !answerData.result)) {
				missingDataFile.push({ ...answerData, file });
			} else if (answerData?.result) {
				const duration = await getMusicDuration(file);
				if (duration) {
					fileData.push({ ...answerData, file, duration });
				}
			} else {
				missingDataFile.push({ file });
			}
		}

		const filteredData = handleDuplicateSpotifyMusic([...fileData, ...spotifyList]);
		const missingFileFilteredData = handleDuplicateLocalMusic([...missingDataFile, ...filesWithNoData]);
		setSpotifyList([...filteredData]);
		setFilesWithNoData([...missingFileFilteredData]);
		setIsUpload(false);
	};

	const handleDuplicateLocalMusic = (musicFiles: File[]) => {
		return musicFiles.filter((file: File, index: number) => {
			const uniqueIdentifier = file.name;
			return index === musicFiles.findIndex((music) => music.name === uniqueIdentifier);
		});
	};

	const handleDuplicateSpotifyMusic = (musicFiles) => {
		// Create an empty array to store unique objects based on the "album" field
		const filteredData = [];

		// Create an empty object to keep track of unique album values
		const uniqueAlbums = {};

		musicFiles.forEach((obj) => {
			const album = obj.result.song_link;

			if (!uniqueAlbums[album]) {
				// Add the object to the filteredData array
				filteredData.push(obj);
				// Mark this album as seen
				uniqueAlbums[album] = true;
			}
		});
		return filteredData;
	};

	const handleCloseModal = () => {
		if (!uploadingState) {
			handleClose();
			setSpotifyList([]);
			setFilesWithNoData([]);
			setIsUpload(false);
			setSelectedFile([]);
			setUploadedFileList([]);
		}
	};

	const fetchMusicList = useCallback(async (fileDetails) => {
		setIsUpload(true);
		const formData = new FormData();
		formData.append('file', fileDetails);
		try {
			const response = await fetch(
				`https://api.audd.io/?api_token=${process.env.REACT_APP_AUDD_API_TOKEN}&return=spotify`,
				{
					method: 'POST',
					body: formData
				}
			);
			const data = await response.json();
			setIsUpload(false);
			return data;
		} catch (error) {
			setIsUpload(false);
			console.error('error', error);
			throw error;
		}
	}, []);

	const handleMusicStore = async (spotifyList) => {
		const uploadedFiles = [...uploadedFileList];
		setUploadingToS3(true);
		for (const music of spotifyList) {
			const { file, result, duration } = music;
			const details = await getCredentialsAndUploadFile(file, result);
			details['duration'] = +duration;
			uploadedFiles.push(details);
		}
		setUploadingToS3(false);
		addBulkMusic(uploadedFiles);
		setUploadedFileList(uploadedFiles);
	};

	// Handle S3 Bucket Upload
	const getCredentialsAndUploadFile = (file: File, fileDetails) => {
		return httpService
			.post(`${API_CONFIG.path.uploadMusicCred}`, { fileName: file.name }, { contentType: 'multipart/form-data' })
			.then((res) => {
				return uploadFileToS3(res, file, fileDetails); // Return the promise here
			})
			.catch((err) => {
				console.error(err);
				setUploadingToS3(false);
			});
	};

	const uploadFileToS3 = (presignedData: IPresignedData, file: File, fileDetails) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			Object.keys(presignedData.fields).forEach((key) => {
				formData.append(key, presignedData.fields[key]);
			});
			formData.append('file', file);
			const xhr = new XMLHttpRequest();
			xhr.onload = function () {
				if (this.status === 204) {
					const { title, spotify, artist } = fileDetails;
					const details = {
						title,
						artist,
						musicUrl: presignedData.previewUrl,
						imageUrl: spotify?.album.images[0].url,
						externalLink: spotify?.external_urls
					};
					resolve(details);
				} else {
					reject(this.responseText);
				}
			};
			xhr.open('POST', presignedData.uploadUrl, true);
			xhr.send(formData);
		});
	};

	const addBulkMusic = useCallback((data: IAddMusic[]) => {
		setIsBulkUploading(true);
		httpService
			.post(`${API_CONFIG.path.music}`, { musics: data })
			.then(() => {
				setIsBulkUploading(false);
				setIsMusicList(true);
				handleCloseModal();
			})
			.catch((error) => {
				setIsBulkUploading(false);
				console.error('error', error);
			});
	}, []);

	return (
		<CustomModal
			show={show}
			handleClose={handleCloseModal}
			closeOnOutSideClick
			className={`add-song-modal${uploadingState ? '-uploading-state' : ''}`}
			dialogClassName='modal-52w'
		>
			<div className='prize-file-upload-wrapper'>
				<FileUploader
					name='music'
					multiple
					handleChange={(file: File) => {
						handleFileUpload(file);
					}}
					types={['mp3', 'wav']}
				>
					<div className='file-upload-wrapper'>
						<h4 className='upload-photo-text'>Drag & Drop music here</h4>
						<p className='photo-size-text'>Supported format:mp3</p>
						<span className='file-upload'>or</span>
						<div className='browse-button-wrapper'>
							<Button btnType='default' className='browse-button'>
								Browse music
							</Button>
						</div>
					</div>
				</FileUploader>
				{(selectedFile.length > 0 || spotifyList.length > 0) && (
					<DataTable
						className={`music-table-wrapper music-modal-table${uploadingState ? '-uploading-state' : ''}`}
						columns={columns as TableColumn<any>[]}
						data={spotifyList}
						progressPending={isUpload}
						progressComponent={<Loader className='custom-loader' />}
						highlightOnHover
						theme='solarized'
						noDataComponent={
							<div className='user-profile-icon'>
								<p className='user-profile-text'>No Music Found</p>
							</div>
						}
					/>
				)}
				{!isUpload && filesWithNoData.length > 0 && (
					<>
						<Alert variant='warning' className='mt-20'>
							Note : We are unable to find the details for the list of below songs, so they won't be
							uploaded!
						</Alert>
						<DataTable
							className={`music-table-wrapper music-modal-table${
								uploadingState ? '-uploading-state' : ''
							}`}
							progressPending={isUpload}
							progressComponent={<Loader className='custom-loader' />}
							columns={missingDataColumns as TableColumn<any>[]}
							data={filesWithNoData}
							highlightOnHover
							theme='solarized'
							noDataComponent={
								<div className='user-profile-icon'>
									<p className='user-profile-text'>No Music Found</p>
								</div>
							}
						/>
					</>
				)}
			</div>
			<div className='d-flex justify-content-end mt-12'>
				<Button
					type='button'
					disabled={uploadingState}
					className='line-height--20px mt-12 mr-13 height--40px width-100px'
					onClick={handleCloseModal}
				>
					Cancel
				</Button>
				<Button
					btnType='primary'
					disabled={uploadingState || !selectedFile.length}
					type='submit'
					className='line-height--20px mt-12 height--40px width-100px'
					onClick={() => spotifyList.length > 0 && handleMusicStore(spotifyList)}
				>
					{uploadingState ? <img className='loading-image' src={LoadingImage} /> : 'Upload'}
				</Button>
			</div>
		</CustomModal>
	);
};

export default AddMusicModal;

const columns = [
	{
		name: 'Song',
		center: true,
		cell: ({ result, file }) => {
			const title = result ? (result?.spotify ? result?.spotify.name : result.title) : file.name;
			const image =
				result?.spotify && result?.spotify?.album?.images.length > 0
					? result?.spotify?.album.images[0]?.url
					: MusicImage;
			return (
				<div className='music-user-img'>
					<img src={image} alt='userImage' />
					<span className='text-truncate' title={title || '-'}>
						{title || '-'}
					</span>
				</div>
			);
		},
		width: '300px'
	},
	{
		name: 'Artist',
		center: true,
		cell: ({ result }) => {
			const { spotify } = result;
			const artists = spotify && spotify.artists ? spotify.artists : [];
			return (
				<div className='text-truncate'>
					{artists.length > 0 ? artists.map((artist) => artist.name).join(', ') : result.album}
				</div>
			);
		},
		flex: 1
	}
];

const missingDataColumns = [
	{
		name: 'Song',
		center: true,
		cell: ({ file }) => {
			return (
				<div className='music-user-img'>
					<img src={MusicImage} alt='userImage' />
					<span className='text-truncate' title={file.name || '-'}>
						{file.name || '-'}
					</span>
				</div>
			);
		},
		width: '300px'
	},
	{
		name: 'Artist',
		center: true,
		cell: () => <div className='text-truncate'>{''}</div>,
		flex: 1
	}
];
