import React from 'react';

import Button from '../form/button';

interface IPaginationProps {
	pagination: IPagination;
	handlePageClick: (page: number) => void;
	handlePageSelection: (perPage: number) => void;
	hidePerPage?: boolean;
	loading?: boolean;
}
export interface IPagination {
	currentPage: number;
	nextPage: number | null;
	recordPerPage: number;
	remainingCount: number;
	total: number;
	totalPages: number;
}

// maximum number of pages per pagination
const maxPages = 3;
const pageOptions: number[] = [10, 15, 30, 50, 100];

const Pagination: React.FC<IPaginationProps> = (props) => {
	const { loading, hidePerPage, pagination, handlePageClick, handlePageSelection } = props;
	const { currentPage, recordPerPage, total, totalPages, nextPage } = pagination;
	// total number of paginations available
	// so, let's say props.totalPages = 17
	// that means pagination must be divided into 2 parts => 1 to 10, and 11 to 17
	// so `totalPaginations` = Math.ceil(17 / 10) = 2
	const totalPaginations = Math.ceil(totalPages / maxPages);
	// currentPagination is the pagination that user is currently on
	// pagination is divided in 2 parts => 1to 10, and 11 to 17
	// let's say user's current page is 11 out of total 17 pages,
	// so user must be on 2nd(1st, as array index starts from 0) pagination (11 to 17)
	// so, formula for getting user's currentPagination = Math.ceil(11 / 10) - 1 = 2 - 1 => 1
	const currentPagination = Math.ceil(pagination.currentPage / maxPages) - 1;
	// determine last page to show in pagination
	let lastPage = maxPages * (currentPagination + 1);
	if (totalPages < lastPage) {
		lastPage = totalPages;
	}

	// jsx to render page numbers
	const pages: JSX.Element[] = [];
	// show pages in 10-10 batches
	// when user's currentpage = 1, show 1 to 10 pages
	// when user's currentpage = 11, show 11 to 20 pages
	// when user's currentpage = 23, show 21 to 30 pages, and so on...
	for (let page = currentPagination * maxPages + 1; page <= lastPage; page += 1) {
		pages.push(
			<Button
				onClick={() => page !== currentPage && handlePageClick(page)}
				key={page}
				btnType='primary'
				disabled={loading}
				className={`pagination-btn ${currentPage === page ? 'pagination-active' : ''}`}
			>
				{page}
			</Button>
		);
	}

	return (
		<div className='pagination-wrapper' data-testid='pagination'>
			<div className='page-count'>
				<span className='font-bold'>{total}</span>
			</div>
			{hidePerPage ? null : (
				<div className='page-selection'>
					<select
						className='form-control'
						disabled={loading}
						value={recordPerPage}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
							handlePageSelection(parseInt(e.target.value));
						}}
					>
						{pageOptions.map((page) => {
							return (
								<option key={page} value={page}>
									{page}
								</option>
							);
						})}
					</select>
				</div>
			)}
			<div className='btn-group-pagination'>
				<Button
					disabled={currentPage === 1}
					onClick={() => props.handlePageClick(1)}
					className='btn btn-pagination'
				>
					{'<<<'}
				</Button>
				<Button
					onClick={() => props.handlePageClick(currentPage - 1)}
					disabled={currentPage === 1}
					className='btn btn-pagination'
				>
					{'<<'}
				</Button>
				{pages}
				<Button
					onClick={() => props.handlePageClick(nextPage)}
					disabled={currentPage === totalPages}
					className='btn btn-pagination'
				>
					{'>>'}
				</Button>
				<Button
					onClick={() => props.handlePageClick(totalPages)}
					disabled={currentPage === totalPages}
					className='btn btn-pagination'
				>
					{'>>>'}
				</Button>
			</div>
		</div>
	);
};

export default Pagination;
