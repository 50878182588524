import {
	Campaigns,
	Challenges,
	Company,
	Dashboard,
	Csv,
	Insight,
	Music,
	Partnership,
	Products,
	Reports,
	UserManagement,
	IconOnBoarding
} from '../../icons/icons';

export const _adminNav = [
	{
		name: 'Dashboard',
		permissionName: 'dashboard',
		reactRoute: '/dashboard',
		icon: <Dashboard />
	},
	{
		name: 'Company',
		permissionName: 'company',
		reactRoute: '/company',
		icon: <Company />
	},
	{
		name: 'Products',
		permissionName: 'products',
		reactRoute: '/products',
		icon: <Products />
	},
	{
		name: 'Campaigns',
		permissionName: 'campaigns',
		reactRoute: '/campaigns',
		icon: <Campaigns />
	},
	{
		name: 'Insight',
		permissionName: 'insight',
		reactRoute: '/insight',
		icon: <Insight />
	},
	{
		name: 'Partnership',
		permissionName: 'partnership',
		reactRoute: '/partnership',
		icon: <Partnership />
	},
	{
		name: 'User Management',
		permissionName: 'user-management',
		reactRoute: '/user-management',
		icon: <UserManagement />
	},
	{
		name: 'Challenges',
		permissionName: 'challenges',
		reactRoute: '/challenges',
		icon: <Challenges />
	},
	{
		name: 'Reports',
		permissionName: 'reports',
		reactRoute: '/reports',
		icon: <Reports />
	},
	{
		name: 'Music',
		permissionName: 'music-management',
		reactRoute: '/music-management',
		icon: <Music />
	},
	{
		name: 'Csv',
		permissionName: 'csv-management',
		reactRoute: '/csv-management',
		icon: <Csv />
	},
	{
		name: 'Onboarding',
		permissionName: 'onboarding',
		reactRoute: '/onboarding',
		icon: <IconOnBoarding />
	}
];
