import React, { ReactElement } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';

interface ICountDownTimerProps {
	date: number | Date;
	afterContent?: ReactElement;
	onComplete: () => void;
	timerOver?: (item: boolean) => void;
}

interface ICountdownRenderProps extends CountdownRenderProps {
	afterContent?: ReactElement;
}

export const CountDownTimer = (props: ICountDownTimerProps) => {
	const { date, onComplete, timerOver } = props;

	return (
		<Countdown
			date={date}
			renderer={renderer}
			onComplete={onComplete}
			onMount={(item) => {
				!!item && !!timerOver && timerOver(item?.completed);
			}}
		/>
	);
};

const renderer = ({ hours, minutes, seconds, completed, afterContent, days }: ICountdownRenderProps) => {
	const renderTimeInSingleDigit = (timeValue: number) => {
		const currentTimeValue = timeValue.toString().length === 1 ? '0' + timeValue : timeValue;
		return (
			<span
				className='single-digit-wrap'
				dangerouslySetInnerHTML={{
					__html: `<span class="single-digit">${(currentTimeValue + '')
						.split('')
						.join('</span><span>')}</span>`
				}}
			/>
		);
	};
	if (completed) {
		return afterContent || null;
	}

	if (days > 0) {
		return (
			<span className={`count-down ${'tournament-count-down'}`}>
				<span className='count-down__item count-down__days'>
					{renderTimeInSingleDigit(days)} {`${days > 1 ? 'days' : 'day'}`} to go
				</span>
			</span>
		);
	} else {
		return (
			<span className={`count-down ${'tournament-count-down'}`}>
				<span className='count-down__item count-down__hour'>{renderTimeInSingleDigit(hours)}</span>
				<span className='count-down__separator'>:</span>
				<span className='count-down__item count-down__min'>{renderTimeInSingleDigit(minutes)}</span>
				<span className='count-down__separator'>:</span>
				<span className='count-down__item count-down__sec'>{renderTimeInSingleDigit(seconds)}</span>
			</span>
		);
	}
};
