import { useLocation } from 'react-router';
import { useCallback, useMemo, useRef, useState } from 'react';

import { FeatureLogo, User } from 'shared/icons/icons';
import authService from 'shared/services/auth.service';
import { useDispatch } from 'react-redux';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/action-types';
import { useOutsideClick } from 'shared/hoc/handleClickOutside/handleClickOutside';

import Confirm from '../confirm/confirm';
import PasswordChangeModal from 'features/auth/container/passwordChangeModal';

const TopHeader = () => {
	const { pathname } = useLocation();
	const topHeaderTitle = useMemo(() => pathname.split('/')[1].replace(/-/g, ' '), [pathname]);

	const [showProfileDropdown, setShowProfileDropdown] = useState<boolean>(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
	const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState<boolean>(false);

	const dispatch = useDispatch();
	const logout = useCallback(() => {
		authService.removeAuthData();
		dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
	}, [dispatch]);

	const profileRef = useRef(null);

	useOutsideClick(profileRef, () => setShowProfileDropdown(false));

	return (
		<>
			<div className='topheader-wrapper d-flex justify-content-space-between'>
				<div className=' d-flex flex-row justify-content-space-between align-items-center'>
					{!!topHeaderTitle && (
						<div className=' d-flex gap--20'>
							<div className='mt-10'>
								<FeatureLogo />
							</div>
							<div className='topheader-title'>
								<h1>{topHeaderTitle}</h1>
							</div>
						</div>
					)}
				</div>

				<div className='icons d-flex'>
					<div
						className='action-wrapper profile-menu-wrap'
						onClick={() => setShowProfileDropdown(!showProfileDropdown)}
						ref={profileRef}
					>
						<div className='dropdown'>
							<div id='profileDropdownMenu' className='profile-icon'>
								<User />
							</div>
							<ul
								className={`dropdown-menu ${showProfileDropdown ? 'show' : 'hide'}`}
								aria-labelledby='profileDropdownMenu'
							>
								<li onClick={() => setIsPasswordChangeOpen(true)}>
									<a href='#'>Password Change</a>
								</li>
								<li onClick={() => setIsConfirmOpen(true)}>
									<a href='#'>Logout</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<Confirm
				loading={false}
				show={isConfirmOpen}
				message='Are you sure want to logout?'
				handleConfirm={logout}
				handleClose={() => setIsConfirmOpen(false)}
				cancelBtnText='No'
				submitBtnText='Yes'
				className='viral-confirm-modal'
			/>

			<PasswordChangeModal show={isPasswordChangeOpen} handleClose={() => setIsPasswordChangeOpen(false)} />
		</>
	);
};

export default TopHeader;
