import { useEffect, useState } from 'react';
import fileSaver from 'file-saver';
import moment from 'moment';

import Button from 'shared/components/form/button';
import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/api';
import { IconCsvFileUpload } from 'shared/icons/icons';
import authService from 'shared/services/auth.service';
import httpService from 'shared/services/http.service';
import { ICsvLastUpload } from './interface/csvInterface';

const CsvComponent = () => {
	const [isImportCsvFile, setIsImportCsvFile] = useState(false);
	const [csvLastUpload, setCsvLastUpload] = useState<ICsvLastUpload>({} as ICsvLastUpload);

	const handleUploadExcel = (file: File | null) => {
		try {
			setIsImportCsvFile(true);
			const formData = new FormData();
			formData.append('file', file);
			httpService
				.post(API_CONFIG.path.csvUpload, formData, {}, { contentType: 'multipart/form-data' })
				.then(() => {
					handleLastUpload();
					setIsImportCsvFile(false);
				})
				.catch((error) => {
					console.error('error', error);
					setIsImportCsvFile(false);
				});
			setIsImportCsvFile(false);
		} catch (error) {
			setIsImportCsvFile(false);
			throw Error(error);
		}
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		handleUploadExcel(file);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDownloadExcel = async () => {
		try {
			setIsImportCsvFile(true);
			const headers: any = { 'Content-Type': 'text/csv' };
			const token = authService.getAccessToken();
			if (token) {
				headers['Authorization'] = `Bearer ${token}`;
			} else {
				headers['x-request-language'] = localStorage.getItem('lang') || 'en';
			}
			const response = await fetch(`${process.env.REACT_APP_API_URL}/csv/download`, {
				method: 'GET',
				headers
			});
			const data = await response.arrayBuffer();
			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			fileSaver.saveAs(blob, 'user_activation_code.csv');
			setIsImportCsvFile(false);
		} catch (error) {
			setIsImportCsvFile(false);
			throw Error(error);
		}
	};

	const handleLastUpload = () => {
		try {
			setIsImportCsvFile(true);
			httpService
				.get(API_CONFIG.path.csvLastUpload)
				.then((response) => {
					setCsvLastUpload(response);
					setIsImportCsvFile(false);
				})
				.catch((error) => {
					console.error('error', error);
					setIsImportCsvFile(false);
				});
			setIsImportCsvFile(false);
		} catch (error) {
			setIsImportCsvFile(false);
			throw Error(error);
		}
	};

	useEffect(() => {
		handleLastUpload();
	}, []);

	return (
		<>
			<div>
				<Button className='download-btn' addBtnType='download' onClick={handleDownloadExcel}>
					Download
				</Button>
			</div>
			<label className='csv-file-upload' htmlFor='csv-upload' onDrop={handleDrop} onDragOver={handleDragOver}>
				<div className='upload-details'>
					<IconCsvFileUpload />
					<span>Upload your CSV file here.</span>
				</div>
				<input
					id='csv-upload'
					name='csv-upload'
					type='file'
					accept='.csv'
					onChange={(e) => handleUploadExcel(e.target.files[0])}
					hidden
				/>
				{isImportCsvFile && <Loader className='csv-upload-loader' />}
			</label>
			{csvLastUpload && (
				<div className='csv-last-upload'>
					<span className='last-upload-title'>Last Upload: </span>
					<span>{moment(csvLastUpload.lastSyncAt).format('YYYY-MM-DD HH:mm')}</span>
				</div>
			)}
		</>
	);
};

export default CsvComponent;
