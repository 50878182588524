import { FC, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { ErrorMessage, Form, FormikErrors, FormikState } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { IDropDownAndCheckboxOptions, Input } from 'shared/components/form/inputTypes';
import { IMusic } from 'features/music/interface/music';
import { convertToUniqueArray, handleKeyDown } from 'shared/util/utility';
import { AddHashTagIcon, IconCircle, RemoveHashTagIcon } from 'shared/icons/icons';
import { maxHashTagLimit, maxSongListLimit } from 'shared/constants/constants';
import Button from 'shared/components/form/button';
import { Loader } from 'shared/components/spinner/spinner';

import { fileTypes, types, videoFileTypes } from '../utility/challengeConstants';
import { ModalTypes } from '../interface/challenges';
import AddMusicModal from './addSongModal';
import { challengeInitValues } from './addChallengeModal';

interface IChallengeFormProps {
	type: ModalTypes;
	isDisableActive: boolean;
	listOfCategory: IDropDownAndCheckboxOptions[];
	values: Record<string, any>;
	isDisable: boolean;
	active: string;
	videoPreviewUrl: string;
	currentFileType: string;
	isBrowseFileLoader: boolean;
	previewUrl: string;
	isSubmitLoader: boolean;
	addHashTag: string[];
	uploading: boolean;
	prizeUploading: boolean;
	errors: FormikErrors<any>;
	setAddHashTag: (hashtagList: string[]) => void;
	setChallengeInitVal: (values) => void;
	handleMusicSelection: (
		music: IMusic,
		field: string,
		musicList: IMusic[],
		setFieldValue: (name: string, value: any) => void
	) => void;
	handleClose: () => void;
	handleSubmit: (formData) => void;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	setActive: (type: string) => void;
	setVideoPreviewUrl: (videoPreviewUrl: string) => void;
	setVideoUrl: (videoUrl: string) => void;
	handleChange: (file: File, setFieldValue: (name: string, value: string) => void, fileUploaderType: string) => void;
	resetForm: (nextState?: Partial<FormikState<any>>) => void;
	setCurrentModalType: (value: ModalTypes) => void;
	setPreviewUrl: (url: string) => void;
	setChallengeDetailsData: () => void;
}

const ChallengeForm: FC<IChallengeFormProps> = ({
	type,
	isDisableActive,
	listOfCategory,
	values,
	isDisable,
	active,
	videoPreviewUrl,
	currentFileType,
	isBrowseFileLoader,
	previewUrl,
	isSubmitLoader,
	addHashTag,
	uploading,
	prizeUploading,
	errors,
	setAddHashTag,
	setFieldValue,
	setActive,
	handleSubmit,
	setVideoPreviewUrl,
	setVideoUrl,
	handleChange,
	handleMusicSelection,
	setChallengeInitVal,
	handleClose,
	resetForm,
	setCurrentModalType,
	setPreviewUrl,
	setChallengeDetailsData
}) => {
	const [showMoreHashtags, setShowMoreHashtags] = useState(false);
	const [isOpenMusicModal, setIsOpenMusicModal] = useState(false);
	const [showMoreMusicList, setShowMoreMusicList] = useState(false);
	const [wordCount, setWordCount] = useState({
		title: 0,
		description: 0
	});

	useEffect(() => {
		return () => {
			setChallengeInitVal(type === 'edit' ? challengeInitValues : values);
		};
	}, [values]);

	useEffect(() => {
		return () => {
			if (type === 'edit') {
				setAddHashTag([]);
				setVideoUrl('');
				setVideoPreviewUrl('');
				setCurrentModalType('');
				setPreviewUrl('');
				setChallengeDetailsData();
			}
		};
	}, []);

	const handleHashTags = (hashTagString: string) => {
		if (hashTagString) {
			if (hashTagString.includes('#')) {
				const splitedHashtag = hashTagString.trim().split('#');
				if (splitedHashtag.length > 2) {
					const hashtags = [];
					splitedHashtag.forEach((hash) => {
						if (hash.trim()) {
							hashtags.push(`#${hash.trim()}`);
						}
					});
					setAddHashTag(convertToUniqueArray([...hashtags, ...addHashTag]));
				} else if (splitedHashtag.length <= 2) {
					setAddHashTag(
						convertToUniqueArray([`#${splitedHashtag[1].trim()}`.replaceAll(' ', ''), ...addHashTag])
					);
				}
			} else {
				setAddHashTag(convertToUniqueArray([`#${hashTagString.trim()}`.replaceAll(' ', ''), ...addHashTag]));
			}
		}
	};
	useEffect(() => {
		setWordCount({ ...wordCount, title: values.title.length, description: values.description.length });
	}, [values.description, values.title.length]);

	return (
		<Form onSubmit={handleSubmit}>
			<h1>{`${type == 'add' ? 'New' : type} Challenge`}</h1>
			<div className='add-challenge-modal-wrapper'>
				<div className='form-group '>
					<Input
						type='text'
						name='title'
						placeholder='Start typing...'
						setFieldValue={setFieldValue}
						showLabels
						className='full-width input-field'
						config={{
							name: 'title',
							type: 'text',
							label: 'Challenge Name'
						}}
						disabled={isDisableActive}
						onKeyDown={(e) => handleKeyDown(e, 40)}
					/>
					<p className='position--absolute challenge-name-count'>{wordCount.title}/40</p>
				</div>
				<div className='form-group'>
					<Input
						placeholder='Choose Category'
						type='dropdown'
						name='category'
						setFieldValue={(name, value) => {
							setFieldValue(name, value.value ?? '');
						}}
						value={listOfCategory.find((opt) => opt.value === values.category)}
						showLabels
						isClearable
						className=''
						config={{
							name: 'category',
							type: 'dropdown',
							label: 'Category',
							otherOptions: {
								dropDownOptions: listOfCategory
							}
						}}
						disabled={isDisableActive}
					/>
				</div>
				<div className='datepicker-wrapper form-group'>
					<div className=' width-50'>
						<Input
							placeholder='MM/DD/YYYY'
							type='datepicker'
							name='challengeRegStartAt'
							setFieldValue={(name, value) => {
								if (Date.parse(value) > 0) {
									setFieldValue(name, value);
								}
							}}
							showLabels
							isClearable
							className='width-50 pl-32 input-field'
							config={{
								name: 'challengeRegStartAt',
								type: 'datepicker',
								label: 'Start Date'
							}}
							disabled={isDisable}
						/>
					</div>
					<div className='width-50'>
						<Input
							placeholder='MM/DD/YYYY'
							type='datepicker'
							name='challengeRegEndAt'
							setFieldValue={(name, value) => {
								if (Date.parse(value) > 0) {
									setFieldValue(name, value);
								}
							}}
							showLabels
							isClearable
							className='full-width input-field '
							config={{
								name: 'challengeRegEndAt',
								type: 'datepicker',
								label: 'End Date'
							}}
							disabled={isDisable}
						/>
					</div>
				</div>
				<div className='datepicker-wrapper form-group'>
					<div className=' width-50'>
						<Input
							placeholder='MM/DD/YYYY'
							type='datepicker'
							name='challengeVoteStartAt'
							setFieldValue={(name, value) => {
								if (Date.parse(value) > 0) {
									setFieldValue(name, value);
								}
							}}
							showLabels
							isClearable
							className='width-50 pl-32 input-field'
							config={{
								name: 'challengeVoteStartAt',
								type: 'datepicker',
								label: 'Voting Start Date'
							}}
							disabled={isDisable}
						/>
					</div>
					<div className='width-50'>
						<Input
							placeholder='MM/DD/YYYY'
							type='datepicker'
							name='challengeVoteEndAt'
							setFieldValue={(name, value) => {
								if (Date.parse(value) > 0) {
									setFieldValue(name, value);
								}
							}}
							showLabels
							isClearable
							className='full-width input-field'
							config={{
								name: 'challengeVoteEndAt',
								type: 'datepicker',
								label: 'Voting End Date'
							}}
							disabled={isDisable}
						/>
					</div>
				</div>
				<div className='form-group'>
					<div className='description-wrapper'>
						<Input
							type='textarea'
							name='description'
							placeholder='Start typing...'
							setFieldValue={setFieldValue}
							showLabels
							className='br--10px height-155px width--412px resize--none'
							config={{
								name: 'description',
								type: 'textarea',
								label: 'Description'
							}}
							onKeyDown={(e) => handleKeyDown(e, 100)}
						/>
						<p className='position--absolute word-count'>{wordCount.description}/100</p>
					</div>
				</div>
				<div className='tags-wrapper form-group'>
					<Input
						type='text'
						name='hashtags'
						placeholder='#tags'
						setFieldValue={setFieldValue}
						showLabels
						className='width-85pr input-field'
						config={{
							name: 'hashtags',
							type: 'text',
							label: 'Hashtags'
						}}
						disabled={isDisableActive}
					/>
					<div
						className='add-hash-tag-icon'
						onClick={() => {
							handleHashTags(values.hashtags);
							setFieldValue('hashtags', '');
						}}
					>
						<span title='Add Hashtag'>
							<AddHashTagIcon />
						</span>
					</div>
				</div>
				<div className='add-hash-tag-wrapper'>
					{[
						...(addHashTag.length > maxHashTagLimit && !showMoreHashtags
							? addHashTag.slice(0, maxHashTagLimit)
							: addHashTag)
					].map((hash, index) => (
						<span className='add-hash-tag hash-tag-text' key={index}>
							{hash}
							<span
								title='Remove Hashtag'
								className='hash-tag-remove'
								onClick={() => {
									!isDisableActive &&
										setAddHashTag([...addHashTag.slice(0, index), ...addHashTag.slice(index + 1)]);
								}}
							>
								<RemoveHashTagIcon />
							</span>
						</span>
					))}
					{addHashTag.length > maxHashTagLimit && (
						<span
							className={`add-hash-tag hash-tag-text cursor-pointer
                        ${showMoreHashtags ? 'show-btn showmore' : 'show-btn'}`}
							onClick={() => setShowMoreHashtags(!showMoreHashtags)}
						>
							{showMoreHashtags ? 'Show Less' : 'Show More'}
						</span>
					)}
				</div>
				<div className='d-flex'>
					<h5 className='pt-15'>Songs</h5>
					<AddMusicModal
						show={isOpenMusicModal}
						handleClose={() => setIsOpenMusicModal(false)}
						handleMusicSelection={(music: any) =>
							handleMusicSelection(music, 'musicIds', values.musicIds, setFieldValue)
						}
						selectedRows={values.musicIds}
					/>
					{!isEmpty(values.musicIds) && (
						<Button
							disabled={isDisableActive}
							className='add-song-icon add-song-icon--small ml-10'
							onClick={() => {
								setIsOpenMusicModal(true);
							}}
						>
							<span title='Add Song'>
								<AddHashTagIcon height='12px' width='12px' />
							</span>
						</Button>
					)}
				</div>
				{isEmpty(values.musicIds) ? (
					<Button
						disabled={isDisableActive}
						className='add-song-icon'
						onClick={() => {
							setIsOpenMusicModal(true);
						}}
					>
						<span title='Add Song'>
							<AddHashTagIcon />
						</span>
					</Button>
				) : (
					<div className='d-flex d-flex-dir-column'>
						<div>
							{[
								...(values.musicIds.length > maxSongListLimit && !showMoreMusicList
									? values.musicIds.slice(0, maxSongListLimit)
									: values.musicIds)
							].map((music: IMusic) => {
								return (
									<div key={music.id} className='flex-space-between  pb-10'>
										<div className='d-flex'>
											<IconCircle />
											<label className='ml-10' htmlFor='html'>
												{music.title}
											</label>
										</div>
										<div
											title='Remove song'
											className={`${isDisableActive ? 'cursor-not-allowed ' : 'cursor-pointer'}`}
											onClick={() => {
												!isDisableActive &&
													handleMusicSelection(
														music,
														'musicIds',
														values.musicIds,
														setFieldValue
													);
											}}
										>
											<RemoveHashTagIcon width='15px' height='15px' />
										</div>
									</div>
								);
							})}
						</div>
						{values.musicIds.length > maxSongListLimit && (
							<span
								className='cursor-pointer text-underline align-self-end'
								onClick={() => setShowMoreMusicList(!showMoreMusicList)}
							>
								{showMoreMusicList ? 'Show Less' : 'Show More'}
							</span>
						)}
					</div>
				)}
				<h2 className='pt-15'>Participants restrictions</h2>
				<div className='buttons-wrapper mt-20'>
					<div className='age-button form-group'>
						<Input
							type='text'
							name='minAge'
							placeholder=''
							setFieldValue={setFieldValue}
							showLabels
							className='minimum-age-button'
							config={{
								name: 'minAge',
								type: 'text',
								label: 'Minimum age'
							}}
							disabled={isDisableActive}
						/>
					</div>
					<div className='toggle-button-wrapper'>
						{types.map((type, index) => (
							<Button
								btnType='default'
								className={`${active === type ? 'active' : ''}`}
								onClick={() => !isDisableActive && setActive(type)}
								key={index}
								disabled={isDisableActive}
							>
								{type}
							</Button>
						))}
					</div>
				</div>
				<div className='prize-details form-group'>
					<Input
						type='textarea'
						name='prize_description'
						placeholder='Start typing...'
						setFieldValue={(name, value) => {
							setFieldValue(name, value.indexOf(' ') === 0 ? '' : value);
						}}
						showLabels
						className='br--10px height-155px width--412px resize--none'
						config={{
							name: 'prize_description',
							type: 'textarea',
							label: 'Advanced Description'
						}}
					/>
				</div>
				<div className='prize-wrapper'>
					<h5 className='prize-text'>Background Picture / Video</h5>
					<Button
						btnType='danger'
						addBtnType='remove'
						disabled={isDisableActive}
						onClick={() => {
							if (!isDisableActive) {
								setVideoPreviewUrl('');
								setFieldValue('videoPreviewUrl', '');
								setVideoUrl('');
							}
						}}
					/>
				</div>
				<div className='common-file-upload-wrapper'>
					<FileUploader
						handleChange={(file: File) => {
							setFieldValue('videoPreviewUrl', file);
							handleChange(file, setFieldValue, 'withVideos');
							setVideoPreviewUrl('');
						}}
						name='videoPreviewUrl'
						types={videoFileTypes}
						disabled={isDisableActive}
					>
						{videoPreviewUrl && !uploading ? (
							<>
								{currentFileType === 'video/mp4' ? (
									<video width='100%' height='240' controls>
										<source src={videoPreviewUrl} type='video/mp4' />
									</video>
								) : (
									<img src={videoPreviewUrl} alt='file upload image' className='file-image' />
								)}
							</>
						) : (
							<div className='file-upload-wrapper'>
								{!uploading ? (
									<>
										<h4 className='upload-photo-text'>
											Drag & Drop background photo / video here.
										</h4>
										<p className='photo-size-text'>
											Supported format: JPG, JPEG, GIF, PNG, MP4 or Webm.
										</p>
										<span className='file-upload'>or</span>
										<div className='browse-button-wrapper'>
											<Button
												btnType='default'
												disabled={isBrowseFileLoader}
												className='browse-button'
											>
												Browse files
											</Button>
										</div>
									</>
								) : (
									<Loader />
								)}
							</div>
						)}
					</FileUploader>
				</div>
				<div className={`prize-detail-wrapper`}>
					<div className='prize-wrapper'>
						<h5 className='prize-text'>Prize</h5>
					</div>
					<div className='common-file-upload-wrapper'>
						<FileUploader
							handleChange={(file: File) => {
								// setPreviewUrl('');
								handleChange(file, setFieldValue, 'withImageOnly');
							}}
							name='previewUrl'
							types={fileTypes}
							disabled={isDisableActive}
						>
							{previewUrl && !prizeUploading ? (
								<img src={previewUrl} alt='file upload image' className='file-image' />
							) : (
								<div className='file-upload-wrapper'>
									{!prizeUploading ? (
										<>
											<h4 className='upload-photo-text'>Drag & Drop prize photo here</h4>
											<p className='photo-size-text'>Supported format: JPG, JPEG, GIF or PNG.</p>
											<span className='file-upload'>or</span>
											<div className='browse-button-wrapper'>
												<Button
													btnType='default'
													disabled={isBrowseFileLoader}
													className='browse-button'
												>
													Browse files
												</Button>
											</div>
										</>
									) : (
										<Loader />
									)}
								</div>
							)}
						</FileUploader>
						{!previewUrl && <ErrorMessage name='previewUrl' className='error pt-4' component={'p'} />}
					</div>
					<div className='prize-details form-group'>
						<Input
							type='text'
							name='prize_name'
							placeholder='Start typing...'
							setFieldValue={setFieldValue}
							showLabels
							className='full-width input-field'
							config={{
								name: 'prize_name',
								type: 'text',
								label: 'Prize Name'
							}}
							disabled={isDisableActive}
						/>
					</div>
				</div>
				<div className='d-flex mt-12'>
					<Button
						className='line-height--20px mt-12 mr-13 height--40px width-214px'
						type='reset'
						onClick={() => {
							resetForm({ values: { ...challengeInitValues } });
							setVideoUrl('');
							setVideoPreviewUrl('');
							setPreviewUrl('');
							setAddHashTag([]);
							handleClose();
							setCurrentModalType('');
						}}
					>
						Cancel
					</Button>
					<Button
						btnType='primary'
						type='submit'
						disabled={isSubmitLoader}
						className='line-height--20px mt-12 height--40px width-214px'
						onClick={handleSubmit}
					>
						{type == 'edit' ? 'Update' : 'Save'}
					</Button>
				</div>
			</div>
		</Form>
	);
};

export default ChallengeForm;
