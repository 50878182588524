const statusIndicatorMapper: { [key: string]: JSX.Element } = {
	upcoming: (
		<div title='Upcoming' className='upcoming'>
			<span className='bar' />
		</div>
	),
	submission_phase: (
		<div title='Submission Phase' className='submission-phase'>
			<span className='bar' />
		</div>
	),
	// waiting_phase: (
	// 	<div title='Waiting Phase' className='waiting-phase'>
	// 		<span className='bar' />
	// 	</div>
	// ),
	voting_phase: (
		<div title='Voting Phase' className='voting-phase'>
			<span className='bar' />
		</div>
	),
	completed: (
		<div title='Completed' className='completed'>
			<span className='bar' />
		</div>
	)
};

const statusTitleMapper = {
	upcoming: 'Upcoming',
	submission_phase: 'Submission Phase',
	// waiting_phase: 'Waiting Phase',
	voting_phase: 'Voting Phase',
	completed: 'Completed'
};

const defaultTimeFormat = {
	hour: 'short',
	minute: 'short',
	weekday: 'short',
	month: 'short',
	day: 'short'
};

const defaultHeaderLabelFormats = {
	yearShort: 'YY',
	yearLong: 'YYYY',
	monthShort: 'MM/YY',
	monthMedium: 'MM/YYYY',
	monthMediumLong: 'MMM YYYY',
	monthLong: 'MMMM YYYY',
	week: 'W',
	dayShort: 'L',
	dayLong: 'dddd, LL',
	hourShort: 'HH',
	hourMedium: 'HH:00',
	hourMediumLong: 'L, HH:00',
	hourLong: 'dddd, LL, HH:00',
	time: 'LLL'
};

const defaultSubHeaderLabelFormats = {
	yearShort: 'YY',
	yearLong: 'YYYY',
	monthShort: 'MM',
	monthMedium: 'MMM',
	monthLong: 'MMMM',
	week: 'W',
	dayShort: 'D',
	dayMedium: 'dd D',
	dayMediumLong: 'ddd, Do',
	dayLong: 'dddd, Do',
	hourShort: 'HH',
	hourLong: 'HH:00',
	minuteShort: 'mm',
	minuteLong: 'HH:mm'
};

export {
	statusIndicatorMapper,
	statusTitleMapper,
	defaultTimeFormat,
	defaultHeaderLabelFormats,
	defaultSubHeaderLabelFormats
};
