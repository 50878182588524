import { IAllReportCategory } from '../interface/reports';

export interface IReportsProgressDataWrapper {
	reportsByCategoryData: IAllReportCategory[];
}
const ReportsProgressDataWrapper: React.FC<IReportsProgressDataWrapper> = (props) => {
	const { reportsByCategoryData } = props;

	return (
		<div className='progress-data-wrapper'>
			{reportsByCategoryData.map((report, index) => (
				<div className='progress-node-data' key={index}>
					<div className='info-color-wrap'>
						<span
							className='progress-node-color'
							style={{
								backgroundColor: report.color
							}}
						/>
						<div className='progress-node-info'>
							<h5 className='progress-node-label'>{report.title}</h5>
							<p className='progress-node-count'>{report.reportCount}</p>
						</div>
					</div>
					<div className='progress-node-percentage'>
						<span>{`${report.reportPercentage}%`}</span>
					</div>
				</div>
			))}
		</div>
	);
};

export default ReportsProgressDataWrapper;
